import { getAuthData } from "utils/AuthUtil";
import {
  IMAGE_OPTIMIZATION_CDN_URL,
  IMAGE_OPTIMIZATION_S3_BUCKET,
  NON_OPTIMIZATION_CDN_URL,
  NON_OPTIMIZATION_S3_BUCKET,
} from "../constants/ExlyImage.constants";
import { logError } from "utils/error";
import { generateS3ImageKey } from "@my-scoot/exly-react-component-lib/build/cjs/features/Common/modules/ExlyImage/utils/generateS3ImageKey";
import { isImageTypeValidForOptimization as _isImageTypeValidForOptimization } from "@my-scoot/exly-react-component-lib/build/cjs/features/Common/modules/ExlyImage/utils/isImageTypeValidForOptimization";
import { uploadToS3 } from "../../S3/utils/S3.utils";

export const isImageTypeValidForOptimization = _isImageTypeValidForOptimization;

/**
 * removes data:image/jpg;base64 from data:image/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAA...
 * and process as buffer to successfully upload to s3.
 * This function to be used in case we are cropping images and we need to upload b64 image string.
 */
export const preProcessB64ImageForS3Upload = (b64ImageString) =>
  Buffer.from(b64ImageString.split(",")[1], "base64");

export const appendKeyToCdnUrl = (cdnBaseUrl, key) => `${cdnBaseUrl}/${key}`;

/**
 * Use this function to upload all images which serves images through public cdn's and
 * return exly's public cdn urls which can futher used to fetch optimized images based on buckets uploaded.
 *
 * @param fileType should be the file mime type
 */
export const uploadImageToExlyBucket = async ({
  fileName,
  fileType,
  file,
  sectionName,
}) => {
  if (!fileName || !fileType || !file || !sectionName) {
    throw new Error(
      "some arguments out of (fileName, fileType, file, sectionName) are falsy."
    );
  }

  try {
    const username = getAuthData().username;
    const key = generateS3ImageKey({ fileName, sectionName, username });

    const isImageValidForOptimization =
      isImageTypeValidForOptimization(fileType);

    const bucketName = isImageValidForOptimization
      ? IMAGE_OPTIMIZATION_S3_BUCKET
      : NON_OPTIMIZATION_S3_BUCKET;

    await uploadToS3({
      file: file,
      contentType: fileType,
      key: key,
      bucket: bucketName,
    });

    const cdnUrl = isImageValidForOptimization
      ? IMAGE_OPTIMIZATION_CDN_URL
      : NON_OPTIMIZATION_CDN_URL;

    return appendKeyToCdnUrl(cdnUrl, key);
  } catch (error) {
    logError({
      error: error?.body?.message,
      occuredAt:
        "uploadImageToExlyBucket in src/features/Common/modules/Image/Images.utils.js",
      when: "Error while uploading image to exly buckets",
    });
  }
};
