import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  {
    root: {
      padding: 0,
      borderRadius: 4,
      color: '#272522',
      '& svg': {
        fontSize: 20,
      },
    },
  },
  {
    name: 'LinkTool',
  }
);
