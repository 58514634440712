import React, { useCallback, useRef } from "react";
import { Field } from "react-final-form";
import debounce from "lodash/debounce";

import ExlyInput from "../../../form/ExlyInput";

import useStyles from "../ExlyTable.styles";

const ExlyTableFilterInput = ({
  placeholder,
  source,
  filterValues,
  setFilters,
  disableContains,
  filterTriggerValidator,
  debounceTimeout,
}) => {
  const classes = useStyles();
  const inputRef = useRef();

  const onChange = useCallback(
    debounce((value, name) => {
      if (!filterTriggerValidator || filterTriggerValidator(value)) {
        setFilters({
          ...filterValues,
          [name]: value,
        });
        if (inputRef.current) inputRef.current.blur();
      }
    }, debounceTimeout ?? 2000),
    [filterValues]
  );

  return (
    <Field
      component={ExlyInput}
      onChange={onChange}
      size="thin"
      name={disableContains ? source : `${source}__icontains`}
      placeholder={placeholder}
      classes={{ notchedOutline: classes.noBorder }}
      inputClassName={classes.inputFilter}
      inputRef={inputRef}
    />
  );
};

export default ExlyTableFilterInput;
