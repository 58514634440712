import React, { useState } from "react";
import AddQuestions from "./AddQuestions";
import {
  Button,
  Checkbox,
  RadioGroup,
} from "@my-scoot/component-library-legacy";
import { is_empty } from "../../../../utils/validations";
import { format, isValid } from "date-fns";
import { getS3SignedUrl, is_desktop } from "../../../../utils/Utils";
import useStyles from "../../../schedule-v2.module";
import { ReactComponent as TipsAndUpdatesIcon } from "assets/vectors/tips_and_updates.svg";
import ExlyCheckbox from "../../../../common/form/ExlyCheckbox";
import { Field, useFormState } from "react-final-form";
import { file_types } from "./questionFormConstants";
import ExlyComplexDropdown from "../../../../common/form/ExlyComplexDropdown";
import { ExlyInput } from "../../../../common/form";
import cssStyles from "./QuestionTypeComponentsStyle.module.css";
import { DATE_MONTH_YEAR_SLASH_FORMAT } from "features/Common/modules/DateTime/DateTime.constants";

export const FileQuestionTypeValidation = () => {
  const classes = useStyles({ is_desktop });
  const formState = useFormState();
  const [allowLimit, setAllowLimit] = useState(
    !is_empty(formState?.values?.questionObj?.validations)
  );

  return (
    <div>
      <div className={classes.questionsTip}>
        <TipsAndUpdatesIcon />
        <div className={is_desktop && classes.textWrapper}>
          Images, Videos & PDF files are only allowed (upto 5mb)
        </div>
      </div>

      <div>
        <Field
          name="set_file_validations"
          component={ExlyCheckbox}
          size="large"
          fullWidth
          label={"Set limits"}
          checked={allowLimit}
          onChange={(value) => setAllowLimit(value)}
        />
        {allowLimit && (
          <Field
            name="questionObj.validations.accept"
            component={ExlyComplexDropdown}
            fullWidth
            size="small"
            options={file_types}
            placeholder="Allowed File Types"
            checked
            multiple
            helperTextClassName={classes.errorTextWrap}
            dropdownLabel={{
              label: "Allowed File Types",
              size: "small",
              weight: "semi-bold",
            }}
          />
        )}
      </div>
    </div>
  );
};

export const TextQuestionTypeValidation = () => {
  const formState = useFormState();
  const [allowLimit, setAllowLimit] = useState(
    !is_empty(formState?.values?.questionObj?.validations)
  );

  const classes = useStyles({ is_desktop });
  return (
    <div>
      <Field
        name="set_text_validations"
        component={ExlyCheckbox}
        size="large"
        fullWidth
        label={"Set limits"}
        checked={allowLimit}
        onChange={(value) => setAllowLimit(value)}
      />
      {allowLimit && (
        <div className={classes.fieldWrapper}>
          <div className={classes.questionsTitle}>Character Limit</div>
          <Field
            name="questionObj.validations.maxlength"
            placeholder="Maximum characters allowed"
            type="number"
            size="small"
            style={{ width: "100%" }}
            component={ExlyInput}
          />
        </div>
      )}
    </div>
  );
};

export const SelectOptionTypeValidation = ({ question }) => {
  return <AddQuestions data={question?.choices} type={question?.type} />;
};

export const MultiSelectQuestionTypeShowAnswer = ({ answer }) => {
  return (
    <div>
      {answer.question_choices?.map((choice) => (
        <span key={choice} className={cssStyles.optionsWrapper}>
          {" "}
          <Checkbox
            size="large"
            disableRipple="false"
            wrapperClassName="mt-1"
            checked={answer.choices.indexOf(choice) > -1}
            disabled={true}
          />
          <label className={cssStyles.labelStyle}>{choice} </label>
        </span>
      ))}
    </div>
  );
};

export const SingleSelectQuestionTypeShowAnswer = ({ answer }) => {
  const selected = !is_empty(answer.text_answer)
    ? {
        value: answer.text_answer,
        label: answer.text_answer,
      }
    : null;
  const parseRadioData = answer.question_choices?.map((choice) => {
    return {
      value: choice,
      label: choice,
      disabled: true,
    };
  });

  return (
    <div>
      <RadioGroup
        options={parseRadioData}
        selected={selected}
        radioProps={{ disabled: true }}
      />
    </div>
  );
};

export const CalendarQuestionTypeShowAnswer = ({ answer }) => {
  const date = new Date(answer.text_answer || "");
  return (
    <p className={cssStyles.responsePara}>
      {isValid(date) ? format(date, DATE_MONTH_YEAR_SLASH_FORMAT) : " "}
    </p>
  );
};

export const FileQuestionTypeShowAnswer = ({ answer }) => {
  const onDownloadFile = async (item) => {
    if (is_empty(item.text_answer)) return;
    const url = await getS3SignedUrl(item.text_answer);
    if (!is_empty(url)) window.open(url);
  };

  return (
    <div>
      <Button size="small" onClick={() => onDownloadFile(answer)}>
        View file
      </Button>
    </div>
  );
};

export const DefaultQuestionTypeShowAnswer = ({ answer }) => {
  return <p className={cssStyles.responsePara}>{answer.text_answer || " "}</p>;
};
