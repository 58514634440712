// if: lines of code > 300
// then: breakdown this file and move to src/features/Listings/utils folder

import areIntervalsOverlapping from "date-fns/areIntervalsOverlapping";
import addMinutes from "date-fns/addMinutes";
import parse from "date-fns/parse";
import constants from "constants/constants";
import { webinarMaxSpot } from "constants/schedule";
import { isTimeExpired } from "features/Crm/modules/AddCustomerModal/utils/AddCustomer.utils";
import { is_empty } from "utils/validations";
// if: lines of code > 300

import {
  PAYMENT_PLAN_TYPE,
  PRICING_MODE_KEYS,
  schedule_types_ids,
} from "constants/schedule";
import { capitalizeFirstLetter } from "features/Common/modules/DataTypes/utils/strings";
import { parsePhoneNumber } from "utils/Utils";
import { dataProvider } from "data";
import { FETCH_OFFERINGS_LIST } from "../constants/Listings.apis";
import { apiMethods } from "data/api.constants";
import {
  DEFAULT_TIME_FORMAT_12,
  DEFAULT_YEAR_MONTH_DATE_FORMAT,
} from "constants/date-fns/dateTime.constant";
import moment from "moment";

// then: breakdown this file and move to src/features/Listings/utils folder
/**
 * navigates to whatsapp page using a phone number from a record.
 *
 * @function onConnectWhatsapp
 * @param {Object} params - The parameters for connecting to WhatsApp.
 * @param {Object} params.record - The record containing the phone number information.
 * @param {string} params.countryCodeKey - The key used to extract the country code from the record.
 * @param {string} params.source - The key used to extract the phone number from the record.
 */
export const onConnectWhatsapp = ({ record, countryCodeKey, source }) => {
  const phone_number = parsePhoneNumber(record, {
    countryCodeKey,
    phoneNumberKey: source,
  });
  if (phone_number) {
    window.open(
      `https://api.whatsapp.com/send?phone=${phone_number.substring(1)}`,
      "_blank"
    );
  }
};

/**
 * Converts UTM information from a record into a single string for CSV.
 *
 * @param {Object} params - The parameters for extracting UTM information.
 * @param {Object} params.record - The record containing UTM information.
 * @returns {string} - The UTM information as a single string, or '-' if no information is available.
 */
export const getUtmInfoString = ({ record = {} }) => {
  if (!record || !record.utm_info) return "-";

  const filteredEntries = Object.entries(record.utm_info).filter(
    ([, value]) => !is_empty(value)
  );

  if (is_empty(filteredEntries)) {
    return "-";
  }

  const utmString = filteredEntries
    .map(([key, value]) => {
      const label = `${capitalizeFirstLetter(key)}: `;
      return `${label}${value}`;
    })
    .join(", ");

  return utmString;
};

export const isScheduleTypeListing = (type) => {
  const listing_type_including_schedules = [
    schedule_types_ids.workshop,
    schedule_types_ids.webinar,
    schedule_types_ids.one_on_one_appointment,
    schedule_types_ids.one_on_one_class,
    schedule_types_ids.group_class,
    schedule_types_ids.rolling_class,
  ];
  return listing_type_including_schedules.includes(type);
};

/**
 *
 * @param {*} offering offering object containing type and  pricing_mode key
 */
export const isGroupClassOneTime = (offering) => {
  return (
    offering?.type === schedule_types_ids.group_class &&
    offering?.pricing_mode === PRICING_MODE_KEYS.oneTime &&
    offering?.payment_plan_type !== PAYMENT_PLAN_TYPE.SUBSCRIPTIONS
  );
};

export const isListingPlanTypeSubscription = (offering) => {
  return offering?.payment_plan_type === PAYMENT_PLAN_TYPE.SUBSCRIPTIONS;
};

export const isListingWhatsappCommunity = ({ type }) => {
  return type === schedule_types_ids.whatsapp;
};

export const isListingMerchandise = ({ type }) => {
  return type === schedule_types_ids.merchandise;
};

/**
 * Checks if the given slot is available.
 * @param {object} slot - The slot to check.
 * @param {boolean} isWebinar - Indicates whether the slot is for a webinar.
 * @returns {boolean} Returns true if the slot is available, otherwise false.
 */
const isSlotAvailable = ({ slot, isWebinar }) => {
  const { start_time } = slot;

  const isDateExpired = isTimeExpired(start_time, "days");

  if (isDateExpired) return false;

  const isSlotTimeExpired = isTimeExpired(start_time, "hours");

  if (isSlotTimeExpired) return false;

  const isAllSpotsFilled = isWebinar
    ? slot.spots_filled > webinarMaxSpot
    : slot.spots_filled > 0;

  return !isAllSpotsFilled;
};

/**
 * Retrieves the available slots from the given listing data.
 * @param {object} listingData - The listing data containing slots.
 * @returns {array} Returns an array of available slots.
 */
export const getAvailableSlots = (listingData) => {
  if (is_empty(listingData)) return [];

  const { type: listingType, slots: listingSlots = [] } = listingData;
  const isWebinar = listingType === constants.scheduleTypesId.webinar;

  return listingSlots.filter((slot) => isSlotAvailable({ slot, isWebinar }));
};

/**
 *
 * @param {array} request_groups Array of Duration & start time
 * @param {number} duration each time slot duration
 * @returns {boolean} true if overlapping date time slots exist, else false
 *
 * NOTE: used for webinar as well as workshop
 */
export const checkWorkshopTimeOverlap = (request_groups = [[]], duration) => {
  const [firstRequestGroup] = request_groups;

  // @dev - For "Repeat on selected days" webinars, slots are generated for the entire week,
  // which can result in some slots having start_time: null
  // filtering for slots with valid start_time
  const validSlots = firstRequestGroup.filter((slot) => slot?.start_time);

  // converting data into interval object format having start and end datetime.
  const timeSlotIntervals = validSlots.map((slot) => {
    const slotDateObj = parse(
      `${slot.interval} ${slot.start_time}`,
      `${DEFAULT_YEAR_MONTH_DATE_FORMAT} ${DEFAULT_TIME_FORMAT_12}`,
      new Date()
    );

    return { start: slotDateObj, end: addMinutes(slotDateObj, duration) };
  });

  // sorting intervals in ascending order of start datetime
  timeSlotIntervals.sort((a, b) => a.start - b.start);

  for (let i = 0; i < timeSlotIntervals.length - 1; i++) {
    const overlapping = areIntervalsOverlapping(
      timeSlotIntervals[i],
      timeSlotIntervals[i + 1]
    );
    if (overlapping) return true;
  }

  return false;
};

/**
 *
 * @param {array} request_groups Array of interval & time range
 * @returns {boolean} true if overlapping date time slots exist, else false
 *
 * NOTE: used for webinar as well as appointment
 */
export const checkAppointmentTimeOverlap = (request_groups = [[]]) => {
  // check if request_groups has same time overlap with interval
  const checkedIntervals = [];
  let overlapped = false;

  const firstRequestGroup = request_groups[0];

  firstRequestGroup.forEach((request_group) => {
    const interval = request_group.interval;
    if (checkedIntervals.includes(interval)) {
      return;
    }

    checkedIntervals.push(interval);

    const sameDaySlots = firstRequestGroup.filter(
      (request_group) => request_group.interval === interval
    );

    if (sameDaySlots.length > 1) {
      const start_time = sameDaySlots[0].time_range[0]?.toLowerCase();
      const end_time = sameDaySlots[0].time_range[1]?.toLowerCase();

      sameDaySlots.forEach((request_group2, index) => {
        if (index !== 0) {
          const start_time2 = request_group2.time_range[0]?.toLowerCase();
          const end_time2 = request_group2.time_range[1]?.toLowerCase();

          if (
            moment(start_time, DEFAULT_TIME_FORMAT_12).isBetween(
              moment(start_time2, DEFAULT_TIME_FORMAT_12),
              moment(end_time2, DEFAULT_TIME_FORMAT_12),
              DEFAULT_TIME_FORMAT_12,
              "[]"
            ) ||
            moment(end_time, DEFAULT_TIME_FORMAT_12).isBetween(
              moment(start_time2, DEFAULT_TIME_FORMAT_12),
              moment(end_time2, DEFAULT_TIME_FORMAT_12),
              DEFAULT_TIME_FORMAT_12,
              "[]"
            ) ||
            (start_time == start_time2 && end_time == end_time2)
          ) {
            overlapped = true;
            return true;
          }
        }
      });
    }
  });

  return overlapped;
};

/**
 * Checks if a given payment plan type is equal to 'INSTALLMENTS'.
 *
 * @param {string} planType - The payment plan type to be checked.
 * @returns {boolean} True if the planType is 'INSTALLMENTS', otherwise false.
 */
export const isInstallmentsPlanType = (planType) =>
  planType === PAYMENT_PLAN_TYPE.INSTALLMENTS;

export const fetchOfferingsList = (payload = {}) => {
  return dataProvider.custom_request(
    FETCH_OFFERINGS_LIST,
    apiMethods.GET,
    payload
  );
};
