import React, { useEffect, useState } from "react";
import { useListController } from "react-admin";
import dataProvider from "../../../../data/dataProvider";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import {
  filterConfig,
  getTableConfig,
  tableChipsFormatter,
} from "./bookedSessionTableConfig";
import { is_empty } from "utils/validations";
import constants from "constants/constants";
import ExlyTable from "../../../../common/Components/ExlyTable";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import SessionStorageConstants from "constants/SessionStorage.constants";
import { useNotifications, useToggleState } from "utils/hooks";
import DesktopInfoModal from "./components/DesktopInfoModal/DesktopInfoModal";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { ListingTypeFieldFormatted } from "./components/TableFields/TableFields";
import LearnMoreModal from "../../../widgets/LearnMoreModal/LearnMoreModal";
import FilteredEmptyState from "./components/miscellaneous/FilteredEmptyState";
import useStyles from "./BookedSession.styles";
import EmptyListIcon from "assets/vectors/infoModal/Session.svg";
import { getAuthData } from "utils/AuthUtil";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { isSubscriptionPaymentType } from "utils/listing/listingUtils";
import formConstants, {
  bookings_section_name,
  MY_BOOKINGS_LEARN_MORE_HREFS,
} from "./bookedSession.constants";
import useOrgMemberList from "utils/hooks/useOrgMemberList";
import { useCustomColumnsConfig } from "features/Crm/modules/ManageColumns/modules/CustomColumn/utils/useCustomColumnsConfig";
import useBulkActions from "features/BulkActions/utils/hooks/useBulkActions";
import { BULK_ACTION_TABLES } from "features/BulkActions/BulkActions.constants";
import BulkActionButton from "features/BulkActions/modules/BulkActionButton/BulkActionButton";
import SelectionCard from "features/BulkActions/modules/SelectionCard/SelectionCard";
import { BroadcastModal } from "common/Components/BroadcastModal/BroadcastModal";
import ExlyImage from "common/Components/ExlyImage";
import useOfferingAutoDebitEnabled from "features/Listings/utils/useOfferingAutoDebitEnabled";
import { BOOKING_LABEL } from "features/Crm/modules/Bookings/Bookings.constant";
import { WhatsAppButton } from "features/Crm/modules/Bookings/modules/MyBookings/modules/WhatsAppButton/WhatsappButton";
import AddCustomerModal from "features/Crm/modules/AddCustomerModal/components/AddCustomerModal";
import { exportLargeListingData } from "utils/Utils";
import { REPORT_TYPES } from "ui/pages/Reports/constants";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { useCustomerDetailsNavigations } from "features/Crm/utils/hooks/useCustomerDetailsNavigations";
import { getCrmAsset } from "features/Crm/utils/Crm.utils";

const handleClick = (event, record) => {
  event.preventDefault();
  const is_renewal_payment = isSubscriptionPaymentType({
    ...record,
    type: record.session_type,
  });
  sessionStorage.setItem(
    SessionStorageConstants.IS_SUBSCRIPTION_PAYMENT_TYPE,
    is_renewal_payment
  );
  sessionStorage.setItem(
    SessionStorageConstants.LISTING_CUSTOMER_ID,
    record.listing_uuid
  );
  sessionStorage.setItem(
    SessionStorageConstants.LISTING_CUSTOMER_TYPE,
    record.session_type
  );
  const isNoSchedSub =
    record.session_type === constants.scheduleTypesId.no_schedule &&
    record.course_end_magnitude > 0;
  sessionStorage.setItem(
    SessionStorageConstants.LISTING_CUSTOMERS_IS_NO_SCHEDULE_SUBSCRIPTION,
    isNoSchedSub
  );

  window.location.href = `${window.location.origin}/#/${
    is_renewal_payment
      ? app_route_ids[app_route_keys.subscription_customer]
      : app_route_ids[app_route_keys.listing_customer]
  }`;
};
const BookedSession = ({ from, tableProps, ...props }) => {
  const classes = useStyles();
  const is_desktop = useDesktopMediaQuery();
  const { notify } = useNotifications();
  const { orgMembers: staffMembers } = useOrgMemberList();
  const [learnMore, setLearnmore] = useState(false);
  const [is_add_modal_active, show_add_modal, hide_add_modal] =
    useToggleState(false);
  const [selectedRecord, setSelectedRecord] = React.useState(null);
  const [isInfoModalActive, showInfoModal, hideInfoModal] =
    useToggleState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const { handleBookingNavigationToCustomer, scrollToLastViewedRow } =
    useCustomerDetailsNavigations();
  const { isOfferingAutoDebitEnabled } = useOfferingAutoDebitEnabled();

  const { appendCustomTableConfig } = useCustomColumnsConfig({
    sectionName: bookings_section_name,
    recordSlug: formConstants.id,
    setScrollPosition,
  });

  const {
    getTableActions,
    showBroadcastModal,
    setShowBroadcastModal,
    handleRowsSelected,
    selectedRecords,
    initiateBroadcast,
    currentPageFullSelection,
    selectAll,
    getBroadcastActions,
    handleSelectAll,
    generateFooterProps,
  } = useBulkActions(props);

  const broadcastActions = getBroadcastActions({
    action: ({ tableName, broadcastType }) =>
      initiateBroadcast({ tableName, broadcastType }),
    tableName: BULK_ACTION_TABLES.MY_BOOKINGS,
  });

  const bulkActions = getTableActions(
    RESOURCE_KEYS.SUBSECTIONS.MY_BOOKINGS.BOOKINGS
  );

  React.useEffect(() => {
    dataProvider.postAnalyticalEvent("creatorPage", "bookedSession", {
      flow: "creatorPage",
      funnel: "creatorPage",
      ui_component: "Text Button",
      identifier: "Booked Session",
    });
  }, []);

  const canAddBooking = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SUBSECTIONS.MY_BOOKINGS.BOOKINGS
  );

  const isCustomerDetailsHidden = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SECTIONS.ALL_CUSTOMERS
  );

  const isTransactionHidden = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS,
    RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS
  );

  const {
    customColumnsToRender,
    customFiltersToAppend,
    customChipsToFormat,
    customFieldToRenderInDetails,
  } = appendCustomTableConfig({
    orgMembers: staffMembers,
    disabled: !canAddBooking,
  });

  const from_bonus_offering =
    from === app_route_ids[app_route_keys.bonus_offering_customers];

  const showSecondaryComponent = is_desktop && !from_bonus_offering;

  let primaryFooterProps = {
    text: "Add Customers",
    onClick: () => show_add_modal(true),
    hide: !canAddBooking,
    icon: (
      <ExlyImage
        src={getCrmAsset("person_add.svg")}
        height={20}
        width={20}
        id="tag"
      />
    ),
  };
  const footerProps = generateFooterProps({ bulkActions, primaryFooterProps });

  const secondaryComponent = (
    <div className="d-flex">
      <LearnMoreCta
        href={MY_BOOKINGS_LEARN_MORE_HREFS.MY_BOOKINGS}
        className="mr-3"
      />
      {canAddBooking && (
        <BulkActionButton actionItem={primaryFooterProps} isSmall={false} />
      )}
    </div>
  );

  const showStaffFilter =
    orgPermissions.isOwner() ||
    orgPermissions.isMainUser() ||
    orgPermissions.isManager();

  const emptyStateProps = {
    page_title: "Bookings",
    content_title: "No sessions yet",
    children:
      "Please promote your offering to get some bookings, so that we can show them hear",
    empty_list_icon: EmptyListIcon,
    alt: "No Bookings",
    primary_cta: {
      children: "Add a session",
      onClick: () => {
        const authData = getAuthData();
        let subdomain = null;
        if (authData) {
          subdomain = authData.sub_domain;
        }
        window.sessionStorage.setItem(
          SessionStorageConstants.FROM_ROUTE,
          "Booked Session"
        );
        window.location.href = !is_empty(subdomain)
          ? `${window.location.origin}/#/ScheduleList`
          : `${window.location.origin}/#/TemplatePreview`;
      },
    },
    secondary_cta: { children: "View sample data" },
    preview_image: constants.preview_booked_session,
  };

  const columnToRender = getTableConfig({
    handleCustomersClick: handleClick,
    showInfoModal,
    setSelectedRecord,
    isTransactionHidden,
    isCustomerDetailsHidden,
    from_bonus_offering,
    customColumnsToRender,
    orgMembers: staffMembers,
    handleBookingNavigationToCustomer,
    isOfferingAutoDebitEnabled,
  });
  const { data, loaded, total, selectedIds, onSelect } =
    useListController(props);
  useEffect(() => {
    /** if : scrollPosition
     * then : run window.scrollTo
     * why: when any record is updated from table ,  we need to scroll back to that record after refresh.
     */
    if (loaded && !is_empty(data) && scrollPosition)
      window.scrollTo(0, scrollPosition);
  }, [loaded, data]);

  useEffect(() => {
    return () => onSelect([]); //@Dev need to clear the selectedIds when moved to another route
  }, []);

  useEffect(() => {
    scrollToLastViewedRow();
  }, [columnToRender]);

  return (
    <div className="component-wrapper">
      <ExlyTable
        checkboxSelection={true}
        showSelectAllcheckBox={true}
        onRowSelected={handleRowsSelected}
        selected={selectedIds}
        ra_props={{ ...props }}
        entityName={BOOKING_LABEL}
        allValuesSelected={selectAll}
        bulkActionComponent={
          <SelectionCard
            actions={bulkActions}
            selectedRecords={Object.keys(selectedRecords)}
            totalRecords={total}
            currentPageFullSelection={currentPageFullSelection}
            entityName={BOOKING_LABEL}
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
          />
        }
        noExportButton={!orgPermissions.canExport()}
        customHandleExport={({ filterValues, isEmpty }) =>
          exportLargeListingData({
            isEmpty,
            filterValues,
            reportType: REPORT_TYPES.BOOKINGS,
            notify,
          })
        }
        tableTitle={!from_bonus_offering ? "My Bookings" : ""}
        footerProps={footerProps}
        hasMobileFooter={true}
        selectedTab="booked-sessions"
        columnConfig={columnToRender}
        tableChipsFormatter={tableChipsFormatter(
          staffMembers,
          customChipsToFormat
        )}
        tableFilters={filterConfig({
          staffMembers,
          showStaffFilter,
          from_bonus_offering,
          customFiltersToAppend,
          isOfferingAutoDebitEnabled,
        })}
        seperateTabFilter={is_desktop}
        filteredEmptyState={<FilteredEmptyState />}
        layoutProps={{
          secondaryComponent: showSecondaryComponent
            ? secondaryComponent
            : null,
          paddingDesktop: "0",
          paddingMobile: "0",
          paddingBottom: "120px",
          noMobileBoxShadow: true,
          learnMoreHref: MY_BOOKINGS_LEARN_MORE_HREFS.MY_BOOKINGS,
        }}
        renderPrimaryColumnRightNode={(record) => (
          <>
            <WhatsAppButton
              record={record}
              source="customer_phone"
              countryCodeKey="customer_country_code"
              showIconBtn
            />
          </>
        )}
        fieldsLeftPadded
        borderedFields
        drawerFieldsBordered
        fieldsAlignment="space-between"
        drawerFieldsAlignment="space-between"
        recordFooterVariant="secondary"
        primaryKey="id"
        recordFooterClassName={classes.footer}
        renderSecondaryAction={(record) => (
          <ListingTypeFieldFormatted record={record} source="session_type" />
        )}
        customPrimaryAction={{
          label: "See Details",
          onClick: (record) => {
            setSelectedRecord(record);
            showInfoModal();
          },
        }}
        {...emptyStateProps}
        {...tableProps}
      />
      {is_add_modal_active && (
        <AddCustomerModal open={is_add_modal_active} onClose={hide_add_modal} />
      )}
      {isInfoModalActive && (
        <DesktopInfoModal
          isOpen={isInfoModalActive}
          customFieldToRenderInDetails={customFieldToRenderInDetails}
          hideModal={() => {
            hideInfoModal();
          }}
          record={selectedRecord}
        />
      )}
      {learnMore && (
        <LearnMoreModal
          title="My Bookings"
          list={[
            "The Bookings tool on Exly is a one-stop tool that helps you keep a track of all the confirmed purchases for your offerings.",
            "Each time an end-user (customer) pays for any of your offerings, a new booking is added to the Bookings tab. It’s easy to filter these bookings as per your requirement on the basis of type, mode, status, and staff.",
          ]}
          open={learnMore}
          onModalClose={() => setLearnmore(false)}
          onClose={() => {
            setLearnmore(false);
          }}
        />
      )}
      {showBroadcastModal && (
        <BroadcastModal
          open={showBroadcastModal}
          onClose={() => setShowBroadcastModal(false)}
          modal_props={{ title: "Send Message" }}
          mobile_modal_props={{ header: "Select Broadcast medium" }}
          broadcastActions={broadcastActions}
        />
      )}
    </div>
  );
};

export default withComponentLibraryTheme(BookedSession);
