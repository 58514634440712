import Icon from '../../../../../../RichTextEditorV2/common/Icon';
import { RTE_NODE_TYPES } from '../../../../RTEEditable/constants/RTEEditable.constants';

export const ALIGNMENT_TOOL_OPTIONS = [
  {
    id: '1',
    label: <Icon icon={RTE_NODE_TYPES.ALIGN_LEFT} />,
    value: { type: 'block', format: RTE_NODE_TYPES.ALIGN_LEFT },
  },
  {
    id: '2',
    label: <Icon icon={RTE_NODE_TYPES.ALIGN_CENTER} />,
    value: { type: 'block', format: RTE_NODE_TYPES.ALIGN_CENTER },
  },
  {
    id: '3',
    label: <Icon icon={RTE_NODE_TYPES.ALIGN_RIGHT} />,
    value: { type: 'block', format: RTE_NODE_TYPES.ALIGN_RIGHT },
  },
];
