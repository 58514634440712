import { MobileModal, Modal } from "@my-scoot/component-library-legacy";
import { UTMInfoField } from "common/form/UTMInfoField";
import constants from "constants/constants";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import React, { useEffect, useState } from "react";
import {
  ORDER_PAYMENT_TYPE,
  ORDER_PAYMENT_TYPE_LABELS,
} from "schedule-v2/constants";
import {
  ScheduleSession,
  ViewSlotsField,
} from "ui/pages/customers/ManageBookings/ListingCustomers/components/TableFields/TableFields";
import { upsellTypeMap } from "ui/pages/customers/ManageBookings/constants";
import { RESOURCE_KEYS, orgPermissions } from "utils/OrgPermissions";
import { is_empty } from "utils/validations";
import DispatchConfirmationModal from "../miscellaneous/DispatchConfirmationModal";
import { DateTimeField } from "../../../../../../common/Components/TableFields/TableFields.js";
import formConstants from "../../bookedSession.constants";
import { BookingQuestions } from "../BookingQuestions/BookingQuestions";
import { LinkField } from "../TableFields/CustomColumns";
import {
  ListingTypeFieldFormatted,
  MeetingAddress,
} from "../TableFields/TableFields";
import CustomColumnDetailsFieldValueMapper from "common/Components/CustomColumnDetailsFieldValueMapper/CustomColumnDetailsFieldValueMapper";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@my-scoot/component-library-legacy";
import classNames from "../../BookedSessionStyles.module.css";
import { PAYMENT_DEBIT_MODES_LABELS } from "features/Listings/modules/PricingSection/PricingSection.constants";
import classes from "./DesktopModalStyles.module.css";
import useMerchandiseShippingStatus from "features/Listings/utils/useMerchandiseShippingStatus";
import { WhatsAppButton } from "features/Crm/modules/Bookings/modules/MyBookings/modules/WhatsAppButton/WhatsappButton";
import DetailsHeader from "features/Crm/modules/Bookings/modules/MyBookings/modules/DetailsHeader/DetailsHeader";
import {
  auto_debit_allowed_offering_types,
  listing_customer_country_code,
} from "features/Listings/Listings.constants";
import useOfferingAutoDebitEnabled from "features/Listings/utils/useOfferingAutoDebitEnabled";
import { isScheduleTypeListing } from "features/Listings/utils/Listings.utils";
import { isClassType } from "utils/listing/listingUtils";
import { schedule_types_ids } from "constants/schedule";
import { notification_color_keys, useNotifications } from "utils/hooks";
import MerchandiseVariantsChips from "features/Crm/modules/Bookings/modules/MerchandiseVariantChips/MerchandiseVariantsChips";
import MerchandiseShippingStatusDropdown from "features/Crm/modules/Bookings/modules/MerchandiseShippingStatusDropdown/MerchandiseShippingStatusDropdown";
import { showAppointmentSlots } from "features/Listings/modules/Appointments/Appointments.utils";
import { logError } from "utils/error";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import ExlyModal from "common/Components/ExlyModal";
import { renderSlots } from "ui/pages/customers/ManageBookings/components/ListingDetailModal";
import { masterFormData } from "ui/pages/customers/ManageBookings/components/MasterTableConfig";
import { see_responses_link_text } from "features/Crm/Crm.constants";
import { useOfferings } from "hooks/useOfferings";
import { BatchField } from "features/Crm/modules/Bookings/components/BatchField/BatchField";
import CustomerStatus from "features/Crm/modules/Bookings/modules/CustomerStatus/CustomerStatus";
import MerchandiseDeliveryAddressField from "features/Crm/modules/Bookings/modules/MerchandiseDeliveryAddressField/MerchandiseDeliveryAddressField";
import useBookingDetailsData from "features/Crm/modules/Bookings/modules/MyBookings/modules/BookingDetails/useBookingDetailsData";
import ExlyLoader from "ui/modules/ExlyLoader";
import { SubscriptionStatus } from "features/Crm/modules/Bookings/modules/MyBookings/modules/BookingDetails/modules/SubscriptionStatus/SubscriptionStatus";

//@DEV(muskan) todo not moving this to feature folder as these logics will be moved to BE in CRM R4
export const DesktopInfoModal = ({
  hideModal,
  isOpen,
  customFieldToRenderInDetails,
  record,
}) => {
  const { data: bookingDetailsData } = useBookingDetailsData({
    record,
    hideModal,
  });
  const data = { ...record, ...bookingDetailsData };
  const isDesktop = useDesktopMediaQuery();
  const {
    processing,
    isDispatchModalActive,
    hideDispatchModal,
    handleUpdateShippingStatus,
    handleShowShippingStatusUpdateModal,
  } = useMerchandiseShippingStatus();

  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const [answerModalData, setAnswerModalData] = useState(false);
  const [showSlotsModal, setShowSlotsModal] = useState(false);
  const [slotsData, setSlotsData] = useState([]);
  const [listingData, setListingData] = useState({});
  const { fetchOfferingDetails } = useOfferings();

  //@DEV(muskan) todo
  //Following checks to be moved to BE in CRM R4
  //BLOCK START
  const isMerch = data?.listing_type === constants.scheduleTypesId.merchandise;
  const isTransactionHidden = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS,
    RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS
  );
  const isCustomerDetailsHidden = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SECTIONS.ALL_CUSTOMERS
  );
  const paymentType = data?.[formConstants.form_data.payment_type.field];
  const isInstallmentPaymentType =
    paymentType === ORDER_PAYMENT_TYPE.INITIAL_PART_PAYMENT ||
    paymentType === ORDER_PAYMENT_TYPE.PART_PAYMENT_INSTALLMENTS;

  const isListingScheduleType = isScheduleTypeListing(data?.listing_type);
  const isClass = isClassType(data?.listing_type);
  const isAppointment =
    data?.listing_type === schedule_types_ids.one_on_one_appointment;
  const isWebinar = data?.listing_type === schedule_types_ids.webinar;
  const isFlexibleOneTimeListing =
    data?.listing_type === schedule_types_ids.rolling_class &&
    paymentType === ORDER_PAYMENT_TYPE.ONE_TIME_PAYMENT;
  const isSubscriptionListing = paymentType === ORDER_PAYMENT_TYPE.SUBSCRIPTION;
  const isAvailabilityNotDecided =
    listingData?.availability_choice === constants.avaibility_type.not_decided;
  const { isOfferingAutoDebitEnabled } = useOfferingAutoDebitEnabled();
  const showAutoDebitField =
    isOfferingAutoDebitEnabled &&
    auto_debit_allowed_offering_types?.includes(data?.listing_type) &&
    isSubscriptionListing;
  // BLOCK END

  const { notify } = useNotifications();

  const fetchDetails = async () => {
    if (isClass || isAppointment) {
      const offeringUuid = data?.listing_uuid;
      if (!offeringUuid) return;

      const details = await fetchOfferingDetails({ offeringUuid });
      setListingData(details);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [isClass, isAppointment]);

  const showSlots = async (record) => {
    try {
      const queryParams = {
        transaction_uuid: record.transaction_uuid,
        customer_uuid: record.username,
      };
      const { slots } = await showAppointmentSlots({ queryParams });
      setSlotsData(slots);
      setShowSlotsModal(true);
    } catch (error) {
      logError({
        error,
        occuredAt: "showSlots in BookedSession/DesktopInfoModal",
        when: "calling showAppointmentSlots",
      });
      notify(
        error?.body?.message || SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
    }
  };

  const content = (
    <div className={classes.container}>
      {!isCustomerDetailsHidden && (
        <>
          <div className={classes.row}>
            <div className={classes.rowTitle}>
              {`${formConstants.form_data.email.placeholder}`} :
            </div>
            <div className={classes.rowValue}>
              {data?.customer_email ?? "-"}
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.rowTitle}>
              {formConstants.form_data.phone.placeholder} :
            </div>
            <div className={classes.rowValue}>
              {data?.customer_country_code ?? ""} {data?.customer_phone ?? "-"}
            </div>
          </div>
        </>
      )}
      <div className={classes.row}>
        <div className={classes.rowTitle}>
          {formConstants.form_data.offering.placeholder} :
        </div>
        <div className={classes.rowValue}>{data?.listing_name ?? "-"}</div>
      </div>
      {data[formConstants.form_data.purchased_plan.field] && (
        <>
          <div className={classes.row}>
            <div className={classes.rowTitle}>
              {formConstants.form_data.purchased_plan.placeholder} :
            </div>
            <div className={classes.rowValue}>
              {data[formConstants.form_data.purchased_plan.field] ?? "-"}
            </div>
          </div>
        </>
      )}
      {data?.offering_variant_uuid && (
        <div className={classes.row}>
          <div className={classes.rowTitle}>
            {formConstants.form_data.variants_purchased} :
          </div>
          <div className={classes.rowValue}>
            {data.offering_variant_title ?? "-"}
          </div>
        </div>
      )}
      {data?.variants && isMerch && (
        <div className={classes.row}>
          <div className={classes.rowTitle}>
            {formConstants.form_data.variants_purchased} :
          </div>
          <div className={classes.rowValue}>
            <MerchandiseVariantsChips variants={data?.variants} />
          </div>
        </div>
      )}
      {isMerch && (
        <>
          <div className={classes.row}>
            <div className={classes.rowTitle}>
              {formConstants.form_data.quantity} :
            </div>
            <div className={classes.rowValue}>{data?.quantity}</div>
          </div>
          <div className={classes.row}>
            <div className={classes.rowTitle}>
              {formConstants.form_data.shipping_status} :
            </div>
            <div className={classes.rowValue}>
              <MerchandiseShippingStatusDropdown
                record={data}
                onChange={() => handleShowShippingStatusUpdateModal(data)}
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.rowTitle}>
              {formConstants.form_data.delivery_address} :
            </div>
            <div className={classes.rowValue}>
              <MerchandiseDeliveryAddressField
                address={data?.customer_address}
              />
            </div>
          </div>
        </>
      )}
      {isClass && (
        <div className={classes.row}>
          <div className={classes.rowTitle}>
            {formConstants.form_data.batch.placeholder} :
          </div>
          <div className={classes.rowValue}>
            <BatchField
              listingData={listingData}
              record={data}
              source={formConstants.form_data.batch.field}
            />
          </div>
        </div>
      )}
      {isAppointment && (
        <div className={classes.row}>
          <div className={classes.rowTitle}>
            {formConstants.form_data.slots_booked.placeholder} :
          </div>
          <div className={classes.rowValue}>
            <ViewSlotsField
              showSlots={showSlots}
              record={data}
              source={formConstants.form_data.slots_booked.field}
              availibility_type={data?.availability_choice}
            />
          </div>
        </div>
      )}
      {isAppointment && isAvailabilityNotDecided && (
        <>
          <div className={classes.row}>
            <div className={classes.rowTitle}>Schedule Session :</div>
            <div className={classes.rowValue}>
              <ScheduleSession record={data} listing_data={listingData} />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.rowTitle}>Customer Status :</div>
            <div className={classes.rowValue}>
              <CustomerStatus record={data} />
            </div>
          </div>
        </>
      )}
      {isWebinar && (
        <div className={classes.row}>
          <div className={classes.rowTitle}>
            {formConstants.form_data.session_booked.placeholder} :
          </div>
          <div className={classes.rowValue}>
            <DateTimeField
              record={data}
              source={formConstants.form_data.session_booked.field}
            />
          </div>
        </div>
      )}
      {isTransactionHidden ? null : (
        <div className={classes.row}>
          <div className={classes.rowTitle}>
            {formConstants.form_data.price.placeholder} :
          </div>
          <div className={classes.rowValue}>
            {data?.total_amount >= 0
              ? `${constants.CURRENCY_SYMBOL_MAP[data?.currency]}${
                  data?.total_amount
                }`
              : "-"}
          </div>
        </div>
      )}
      <div className={classes.row}>
        <div className={classes.rowTitle}>
          {formConstants.form_data.date.placeholder} :
        </div>
        <div className={classes.rowValue}>
          {data?.payment_date ? (
            <DateTimeField record={data} source="payment_date" />
          ) : (
            "-"
          )}
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.rowTitle}>
          {formConstants.form_data.payment_type.placeholder} :
        </div>
        <div className={classes.rowValue}>
          {ORDER_PAYMENT_TYPE_LABELS[
            data?.[formConstants.form_data.payment_type.field]
          ] || "-"}
        </div>
      </div>
      {data[formConstants.form_data.subscription_status.field] && (
        <div className={classes.row}>
          <div className={classes.rowTitle}>
            {formConstants.form_data.subscription_status.placeholder} :
          </div>
          <div className={classes.rowValue}>
            <SubscriptionStatus
              status={data[formConstants.form_data.subscription_status.source]}
            />
          </div>
        </div>
      )}
      {showAutoDebitField && (
        <div className={classes.row}>
          <div className={classes.rowTitle}>
            {formConstants.form_data.payment_debit_mode.placeholder} :
          </div>
          <div className={classes.rowValue}>
            {data?.payment_type === ORDER_PAYMENT_TYPE.SUBSCRIPTION
              ? PAYMENT_DEBIT_MODES_LABELS[
                  data?.[formConstants.form_data.payment_debit_mode.field]
                ]
              : "-"}
          </div>
        </div>
      )}
      {isFlexibleOneTimeListing && (
        <>
          <div className={classes.row}>
            <div className={classes.rowTitle}>
              {formConstants.form_data.class_start_date.placeholder} :
            </div>
            <div className={classes.rowValue}>
              <DateTimeField
                record={data}
                source={formConstants.form_data.class_start_date.field}
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.rowTitle}>
              {formConstants.form_data.class_end_date.placeholder} :
            </div>
            <div className={classes.rowValue}>
              <DateTimeField
                record={data}
                source={formConstants.form_data.class_end_date.field}
              />
            </div>
          </div>
        </>
      )}
      {isSubscriptionListing && (
        <>
          <div className={classes.row}>
            <div className={classes.rowTitle}>
              {formConstants.form_data.subscription_start_date.placeholder} :
            </div>
            <div className={classes.rowValue}>
              <DateTimeField
                record={data}
                source={formConstants.form_data.subscription_start_date.field}
              />
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.rowTitle}>
              {formConstants.form_data.subscription_end_date.placeholder} :
            </div>
            <div className={classes.rowValue}>
              <DateTimeField
                record={data}
                source={formConstants.form_data.subscription_end_date.field}
              />
            </div>
          </div>
        </>
      )}
      {isInstallmentPaymentType ? (
        <div className={classes.row}>
          <div className={classes.rowTitle}>No. of Instalments paid :</div>
          <div className={classes.rowValue}>
            {`${data?.[formConstants.form_data.paid_installments.field] || 0} of
             ${
               data?.[formConstants.form_data.total_installments.field]
             } instalments`}
          </div>
        </div>
      ) : null}
      <div className={classes.row}>
        <div className={classes.rowTitle}>
          {formConstants.form_data.offering_type.placeholder} :
        </div>
        <div className={classes.rowValue}>
          <ListingTypeFieldFormatted record={data} source="listing_type" />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.rowTitle}>
          Offering Owner
          <Tooltip
            title="This has been assigned from member selected in the offering "
            color="primary"
            arrow
          >
            <InfoOutlinedIcon className={classNames.infoIconStyle} />
          </Tooltip>
        </div>
        <div className={classes.rowValue}>
          {is_empty(data?.staff_name) ? "-" : data?.staff_name}
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.rowTitle}>
          {formConstants.form_data.listing_address.placeholder} :
        </div>
        <div className={classes.rowValue}>
          <MeetingAddress record={data} isInfoModal={true} />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.rowTitle}>
          {formConstants.form_data.upsell_type.placeholder} :
        </div>
        <div className={classes.rowValue}>
          {upsellTypeMap[data?.[formConstants.form_data.upsell_type.field]] ??
            "-"}
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.rowTitle}>
          {formConstants.form_data.source_upsell_offering_name.placeholder} :
        </div>
        <div className={classes.rowValue}>
          {data?.[formConstants.form_data.source_upsell_offering_name.field] ??
            "-"}
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.rowTitle}>
          {formConstants.form_data.utm_info.placeholder} :
        </div>
        <div className={classes.rowValue}>
          <UTMInfoField record={data} />
        </div>
      </div>
      {isListingScheduleType && (
        <div className={classes.row}>
          <div className={classes.rowTitle}>
            {formConstants.form_data.customer_timezone.placeholder} :
          </div>
          <div className={classes.rowValue}>
            {data?.customer_timezone ?? "-"}
          </div>
        </div>
      )}
      <div className={classes.row}>
        <div className={classes.rowTitle}>
          {formConstants.form_data.responses.placeholder} :
        </div>
        <div className={classes.rowValue}>
          {data?.answer_count ? (
            <LinkField
              onClick={(record) => {
                setShowAnswerModal(true);
                setAnswerModalData(record);
              }}
              record={data ?? {}}
              source="answer_list"
              linkText={see_responses_link_text}
              className={classes.action}
            />
          ) : (
            "-"
          )}
        </div>
      </div>
      <CustomColumnDetailsFieldValueMapper
        columns={customFieldToRenderInDetails}
        recordSlug={formConstants.transaction_uuid}
        data={data}
      />
    </div>
  );

  const modalContent = (
    <>
      {is_empty(bookingDetailsData) ? (
        <div className={classes.loaderWrapper}>
          <ExlyLoader showText={false} />
        </div>
      ) : (
        content
      )}
      <DispatchConfirmationModal
        title="Confirm"
        open={isDispatchModalActive}
        onClose={hideDispatchModal}
        primaryBtnText="Confirm"
        primaryBtnProps={{
          loading: processing,
          disabled: processing,
        }}
        onPrimaryBtnClick={() =>
          handleUpdateShippingStatus({ hideDetailsModal: hideModal })
        }
      />
      {showAnswerModal && (
        <BookingQuestions
          isOpen={showAnswerModal}
          data={answerModalData}
          hideModal={() => {
            setShowAnswerModal(false);
          }}
          title={formConstants.booking_questions_header}
        />
      )}
      {showSlotsModal && (
        <ExlyModal
          open={showSlotsModal}
          className={classes.moreContentModal}
          title={data?.listing_name}
          onClose={() => {
            setShowSlotsModal(false);
            setSlotsData(null);
          }}
          modal_props={{
            customFooter: <></>,
            modalPaperClassName: classes.desktopDetailModal,
            dialogTitleClassName: classes.detailModalHeader,
          }}
          mobile_modal_props={{
            customFooter: <></>,
            dialogHeaderClassName: classes.mobileDetailHeader,
          }}
        >
          {renderSlots(masterFormData, slotsData)}
        </ExlyModal>
      )}
    </>
  );
  const commonProps = {
    open: isOpen,
    customHeader: <DetailsHeader data={data} hideModal={hideModal} />,
    onClose: hideModal,
  };

  return isDesktop ? (
    <Modal {...commonProps} customFooter={<></>}>
      {modalContent}
    </Modal>
  ) : (
    <MobileModal
      {...commonProps}
      customFooter={
        isCustomerDetailsHidden ? (
          <></>
        ) : (
          <WhatsAppButton
            record={data}
            source={formConstants.form_data.phone.field}
            countryCodeKey={listing_customer_country_code}
          />
        )
      }
    >
      {modalContent}
    </MobileModal>
  );
};

export default DesktopInfoModal;
