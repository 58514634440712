import { booking_record_slug } from "constants/schedule";
import { bookings_section_name } from "ui/pages/schedule/BookedSession/bookedSession.constants";

export const CUSTOM_COLUMN_SECTIONS = {
  BOOKINGS: {
    section_name: bookings_section_name,
    record_slug: booking_record_slug,
  },
  LEADS: {
    section_name: "webpage_leads",
    record_slug: "lead_id",
    details_record_slug: "id",
  },
  CUSTOMERS: {
    section_name: "all_customers",
    record_slug: "uuid",
  },
};

export const CUSTOM_COLUMN_DATA_TYPE = {
  SHORT_TEXT: 5,
  LONG_TEXT: 7,
  SINGLE_SELECT: 8,
  MULTIPLE_SELECT: 9,
  DATE: 10,
  TIME: 11,
  URL: 12,
  GENDER: 13,
  INDIAN_STATE: 14,
  INDIAN_CITY: 15,
};

export const SINGLE_SELECT_EMPTY_OPTION = {
  id: "none__empty__option",
  value: null,
  label: "-",
};

export const ORG_STAFF_EMPTY_OPTION = {
  id: "none__empty__option",
  value: null,
  label: "Assign to member",
};
