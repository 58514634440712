export const indian_states_and_uts = [
  { name: "Andaman and Nicobar Islands", isUT: true },
  { name: "Andhra Pradesh", isUT: false },
  { name: "Arunachal Pradesh", isUT: false },
  { name: "Assam", isUT: false },
  { name: "Bihar", isUT: false },
  { name: "Chandigarh", isUT: true },
  { name: "Chhattisgarh", isUT: false },
  { name: "Dadra and Nagar Haveli and Daman and Diu", isUT: true },
  { name: "Delhi", isUT: true },
  { name: "Goa", isUT: false },
  { name: "Gujarat", isUT: false },
  { name: "Haryana", isUT: false },
  { name: "Himachal Pradesh", isUT: false },
  { name: "Jharkhand", isUT: false },
  { name: "Jammu and Kashmir", isUT: true },
  { name: "Karnataka", isUT: false },
  { name: "Kerala", isUT: false },
  { name: "Ladakh", isUT: true },
  { name: "Lakshadweep", isUT: true },
  { name: "Madhya Pradesh", isUT: false },
  { name: "Maharashtra", isUT: false },
  { name: "Manipur", isUT: false },
  { name: "Meghalaya", isUT: false },
  { name: "Mizoram", isUT: false },
  { name: "Nagaland", isUT: false },
  { name: "Odisha", isUT: false },
  { name: "Puducherry", isUT: true },
  { name: "Punjab", isUT: false },
  { name: "Rajasthan", isUT: false },
  { name: "Sikkim", isUT: false },
  { name: "Tamil Nadu", isUT: false },
  { name: "Telangana", isUT: false },
  { name: "Tripura", isUT: false },
  { name: "Uttar Pradesh", isUT: false },
  { name: "Uttarakhand", isUT: false },
  { name: "West Bengal", isUT: false },
];

export const indian_states_and_uts_dropdown_options = indian_states_and_uts.map(
  (item, idx) => ({
    id: `${item.name}-${idx}`,
    label: item.name,
    value: item.name,
  })
);
