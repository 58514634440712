// @ts-ignore
import { isDateToday as _isDateToday } from "@my-scoot/exly-react-component-lib/build/cjs/features/Common/modules/DateTime/utils/DateTime.utils";
import { isDateToday as isDateTodayType } from "@my-scoot/exly-react-component-lib/build/types/features/Common/modules/DateTime/utils/DateTime.utils";

import { DEFAULT_TIME_FORMAT_12 } from "constants/date-fns/dateTime.constant";
import { DEFAULT_TIME_FORMAT_24_WITH_SECS } from "constants/dateTime";
import { format, parse, isMatch } from "date-fns";

export const convertTo12HourFormat = (time: string) => {
  try {
    // Check if the time is already in the 12-hour format
    if (isMatch(time, DEFAULT_TIME_FORMAT_12)) {
      return time;
    }

    // Parse and convert to 12-hour format if not already
    return format(
      parse(time, DEFAULT_TIME_FORMAT_24_WITH_SECS, new Date()),
      DEFAULT_TIME_FORMAT_12
    ).toUpperCase();
  } catch (error) {
    // Return the original value if any error occurs
    return time;
  }
};

export const isDateToday: typeof isDateTodayType = _isDateToday;
