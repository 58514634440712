import { FC } from 'react';
import { getRTEMark } from '../../utils/RTEEditable.utils';

export const RTELeafRenderer = ({ attributes, children, leaf }: any) => {
  children = getRTEMark(leaf, children);
  return (
    <span
      style={leaf.text === '' ? { paddingLeft: '0.1px' } : null}
      {...attributes}
    >
      {children}
    </span>
  );
};
