export const DESERIALIZER_KEYS = {
  strong: 'strong',
  b: 'b',
  em: 'em',
  i: 'i',
  code: 'code',
  u: 'u',
  del: 'del',
  bold: 'bold',
  italic: 'italic',
  underline: 'underline',
  lineThrough: 'line-through',
  text: 'text',
  emptyString: '',
  A: 'A',
  element: 'element',
  href: 'href',
  BODY: 'BODY',
  BR: 'BR',
  BLOCKQUOTE: 'BLOCKQUOTE',
  fragment: 'fragment',
  DIV: 'DIV',
  LI: 'LI',
  P: 'P',
  H1: 'H1',
  H2: 'H2',
  H3: 'H3',
  H4: 'H4',
  H5: 'H5',
  H6: 'H6',
  IMG: 'IMG',
  UL: 'UL',
  OL: 'OL',
  TABLE: 'TABLE',
  TBODY: 'TBODY',
  TR: 'TR',
  TH: 'TH',
  TD: 'TD',
  CITE: 'CITE',
  sevenHundred: '700',
};
