import React from "react";
import ExlySpinner from "ui/modules/ExlySpinner/ExlySpinner";
import { Input } from "@my-scoot/component-library-legacy";
import { useCustomColumnUpdate } from "../../CustomColumn/utils/useCustomColumnUpdate";
import styles from "./ShortTextField.module.css";
import classnames from "classnames";

export const ShortTextField = ({
  targetObject,
  handleUpdate,
  defaultValue = "",
  disabled,
  inputClass,
}) => {
  const { loading, selectedValue, handleUpdateColumnData, setSelectedValue } =
    useCustomColumnUpdate({
      handleUpdate,
      targetObject,
      defaultValue,
    });

  if (loading) return <ExlySpinner loaderWidth="24px" wrapperPadding="0 0" />;

  return (
    <div className="w-100">
      <Input
        placeholder="Add text..."
        value={selectedValue}
        disabled={disabled}
        onChange={({ target: { value } }) => setSelectedValue(value)}
        onBlur={({ target: { value } }) => handleUpdateColumnData(value)}
        multiline
        className={classnames(styles.inputClass, inputClass)}
        inputElementClassName={styles.inputElement}
        inputProps={{ maxLength: 100 }}
        fullWidth
      />
    </div>
  );
};
