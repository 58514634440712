import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  {
    root: {
      backgroundColor: 'transparent',
    },
  },
  { name: 'ComponentLibrary' }
);
