import { RTE_TOOLBAR_MARKS } from '../../constants/RTEToolbar.marks';
import { MarkToolButton } from '../MarkToolButton/MarkToolButton';
import FormatBoldRoundedIcon from '@material-ui/icons/FormatBoldRounded';

export const BoldMarkTool = () => {
  return (
    <MarkToolButton markFormat={RTE_TOOLBAR_MARKS.bold}>
      <FormatBoldRoundedIcon />
    </MarkToolButton>
  );
};
