import FormatIndentDecrease from '@material-ui/icons/FormatIndentDecrease';
import { ListsEditor } from '@prezly/slate-lists';
import { useSlate } from 'slate-react';
import { isBlockActive } from '../../../../../RichTextEditorV2/utils/SlateUtilityFunctions';
import { ToolbarButton } from '../ToolbarButton/ToolbarButton';
import { IToolbarButton } from '../ToolbarButton/ToolbarButton.interfaces';

export const IndentLeftTool = () => {
  const editor = useSlate();

  const handleToggleUnorderedList: IToolbarButton['onMouseDown'] = (e) => {
    e.preventDefault();
    ListsEditor.decreaseDepth(editor as ListsEditor);
  };

  return (
    <ToolbarButton
      active={isBlockActive(editor, 'indent-left')}
      onMouseDown={handleToggleUnorderedList}
    >
      <FormatIndentDecrease />
    </ToolbarButton>
  );
};
