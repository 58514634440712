import React from 'react';
import { getJsxStyles } from '../../../utils/RTEEditable.utils';

interface ImageSerializeProps {
  attributes: any;
  element: any;
}

const ImageSerialize: React.FC<ImageSerializeProps> = ({
  attributes,
  element,
  children,
}) => {
  const styles: any = getJsxStyles(element?.style?.value);
  const { width } = styles;

  const Image = (
    <img
      alt={element.alt}
      src={element.url}
      style={{
        ...styles,
        justifyContent: element.justifyContent,
        width: element.width || width,
        maxWidth: '100%',
        whiteSpace: 'nowrap',
      }}
    />
  );

  return (
    <div
      {...attributes}
      {...element.attr}
      style={{
        width: '100%',
        textAlign: element.justifyContent || 'left',
      }}
    >
      {element.redirectTo ? (
        <a href={element.redirectTo} target="_blank" rel="noreferrer">
          {Image}
        </a>
      ) : (
        Image
      )}
      {children}
    </div>
  );
};
export default ImageSerialize;
