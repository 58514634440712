import React from "react";
import ExlySpinner from "ui/modules/ExlySpinner/ExlySpinner";
import { Dropdown } from "@my-scoot/component-library-legacy";
import { useCustomColumnUpdate } from "../../CustomColumn/utils/useCustomColumnUpdate";
import styles from "./SingleSelectDropdownField.module.css";
import classnames from "classnames";

export const SingleSelectDropdownField = ({
  options,
  targetObject,
  handleUpdate,
  defaultValue,
  disabled,
  columnWidth,
}) => {
  const { loading, selectedValue, handleUpdateColumnData } =
    useCustomColumnUpdate({
      handleUpdate,
      targetObject,
      defaultValue,
    });

  if (loading) return <ExlySpinner loaderWidth="24px" wrapperPadding="0 0" />;

  return (
    <Dropdown
      options={options}
      placeholder="Select"
      value={selectedValue}
      disabled={disabled}
      handleChange={handleUpdateColumnData}
      formControlClassName={classnames(
        styles.dropdownFormControl,
        columnWidth && styles.dropdownFormControlWidth
      )}
      restMenuProps={{ menu_paper_classname: styles.dropdownPoppver }}
      fullWidth
    />
  );
};
