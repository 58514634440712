import { FC } from 'react';
import { AlignmentTool } from './modules/AlignmentTool/AlignmentTool';
import { BoldMarkTool } from './modules/BoldMarkTool/BoldMarkTool';
import { FontColorTool } from './modules/FontColorTool/FontColorTool';
import { FontFamilyTool } from './modules/FontFamilyTool/FontFamilyTool';
import { FontSizeTool } from './modules/FontSizeTool/FontSizeTool';
import { HeadingTool } from './modules/HeadingTool/HeadingTool';
import { HighlightColorTool } from './modules/HighlightColorTool/HighlightColorTool';
import { ImageTool } from './modules/ImageTool/ImageTool';
import { IndentLeftTool } from './modules/IndentLeftTool/IndentLeftTool';
import { IndentRightTool } from './modules/IndentRightTool/IndentRightTool';
import { InlineCodeTool } from './modules/InlineCode/InlineCodeTool';
import { ItalicMarkTool } from './modules/ItalicMarkTool/ItalicMarkTool';
import { LinkTool } from './modules/LinkTool/LinkTool';
import { MonospaceTool } from './modules/MonospaceTool/MonospaceTool';
import { OrderedListTool } from './modules/OrderedListTool/OrderedListTool';
import { QuoteTool } from './modules/QuoteTool/QuoteTool';
import { StrikeThroughMarkTool } from './modules/StrikeThroughMarkTool/StrikeThroughMarkTool';
import { TableTool } from './modules/TableTool/TableTool';
import { ToolsGroupDivider } from './modules/ToolsGroupDivider/ToolsGroupDivider';
import { UnderlineTool } from './modules/UnderlineTool/UnderlineTool';
import { UnorderedListTool } from './modules/UnorderedListTool/UnorderedListTool';
import { IRTEToolbar } from './RTEToolbar.interfaces';
import { useStyles } from './RTEToolbar.styles';

export const RTEToolbar: FC<IRTEToolbar> = ({
  renderToolbarOptions,
  imageUploadCallback,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {renderToolbarOptions?.({
        options: {
          HeadingTool,
          FontFamilyTool,
          FontSizeTool,
          BoldMarkTool,
          ItalicMarkTool,
          UnderlineTool,
          StrikeThroughMarkTool,
          MonospaceTool,
          AlignmentTool,
          FontColorTool,
          HighlightColorTool,
          UnorderedListTool,
          OrderedListTool,
          IndentRightTool,
          IndentLeftTool,
          QuoteTool,
          InlineCodeTool,
          LinkTool,
          ImageTool,
          TableTool,
        },
      }) ?? (
        <>
          <HeadingTool />
          <FontFamilyTool />
          <FontSizeTool />

          <ToolsGroupDivider />

          <BoldMarkTool />
          <ItalicMarkTool />
          <UnderlineTool />
          <StrikeThroughMarkTool />
          <MonospaceTool />

          <ToolsGroupDivider />

          <AlignmentTool />

          <ToolsGroupDivider />

          <FontColorTool />
          <HighlightColorTool />

          <ToolsGroupDivider />

          <UnorderedListTool />
          <OrderedListTool />
          <IndentRightTool />
          <IndentLeftTool />

          <ToolsGroupDivider />

          <QuoteTool />
          <InlineCodeTool />

          <ToolsGroupDivider />

          <LinkTool />
          <ImageTool imageUploadCallback={imageUploadCallback} />
          <TableTool />
        </>
      )}
    </div>
  );
};
