// Third party imports
import React from "react";

// Util functions and providers
import { getUserCurrencySymbol } from "../../../../utils/AuthUtil";
import { DateConvert, roundOff } from "../../../../utils/Utils";
import WhastappLogo from "assets/vectors/whatsapp_green.svg";
import EmailLogo from "assets/vectors/email_arrow.svg";

// Components
import {
  NameField,
  EmailField,
  TagsField,
  CustomerActionsField,
} from "./components/TableColumns/TableColumns";
import { WhatsAppPhoneField } from "../../../../common/Components/TableFields/TableFields";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import { LinkField } from "webpage-leads/components/CustomColumns";
import { analyticsConstants } from "constants/analyticsConstants";
import { hasPermissionToEditLogs } from "webpage-leads/webpageHelpers";
import { is_empty } from "utils/validations";
import { UTMInfoField } from "common/form/UTMInfoField";
import { customer_table_config } from "features/Crm/modules/AllCustomers/AllCustomers.constants";
import {
  formatCustomChip,
  transformCustomColumns,
} from "features/Crm/modules/ManageColumns/modules/CustomColumn/utils/CustomColumn.utils";

export const getTableConfig = ({
  onClickNameField,
  hideTotalSpent,
  setShowNotesModal,
  onShowTagsClicked,
  setShowTagsModal,
  onResponsesClick,
  customColumnsToRender = [],
}) => {
  const transformedObject = transformCustomColumns({ customColumnsToRender });

  return {
    [customer_table_config.customer_name.field]: {
      field: customer_table_config.customer_name.field,
      headerName: customer_table_config.customer_name.placeholder,
      valueFormatter: (record) => (
        <NameField
          record={record}
          source={customer_table_config.customer_name.field}
          onClick={onClickNameField}
        />
      ),
      isPrimary: true,
      avatar: true,
      sortable: false,
      fixed: "left",
      width: "220px",
      columnClassName: "word_break",
      ellipsis: true,
      maxCharCount: 25,
    },
    [customer_table_config.customer_email.field]: {
      field: customer_table_config.customer_email.field,
      headerName: customer_table_config.customer_email.placeholder,
      valueFormatter: (record) => (
        <EmailField
          record={record}
          source={customer_table_config.customer_email.field}
        />
      ),
      sortable: false,
      width: "180px",
      columnClassName: "word_break",
    },
    [customer_table_config.email.field]: {
      field: customer_table_config.email.field,
      headerName: customer_table_config.email.placeholder,
      valueFormatter: (record) => (
        <EmailField
          record={record}
          source={customer_table_config.customer_email.field}
        />
      ),
      sortable: false,
      width: "180px",
      columnClassName: "word_break",
    },
    [customer_table_config.phone_number.field]: {
      field: customer_table_config.phone_number.field,
      headerName: customer_table_config.phone_number.placeholder,
      valueFormatter: (record) => (
        <WhatsAppPhoneField
          record={record}
          source={customer_table_config.phone_number.field}
        />
      ),
      sortable: false,
    },
    [customer_table_config.total_spent.field]: {
      field: customer_table_config.total_spent.field,
      headerName: customer_table_config.total_spent.placeholder,
      valueFormatter: (record) =>
        record.total_spent || record.total_spent === 0
          ? `${getUserCurrencySymbol()} ${roundOff(record.total_spent, 2)}`
          : "N/A",
      noWrap: true,
      sortable: false,
      hidden: hideTotalSpent,
    },
    [customer_table_config.transactions_count.field]: {
      field: customer_table_config.transactions_count.field,
      headerName: customer_table_config.transactions_count.placeholder,
      sortable: false,
    },
    [customer_table_config.referer_group_id.field]: {
      field: customer_table_config.referer_group_id.field,
      headerName: customer_table_config.referer_group_id.placeholder,
      emptyField: "N/A",
      valueFormatter: (row) => (
        <div>
          {row.referer_group_id !== null
            ? analyticsConstants.lead_source[row.referer_group_id - 1]?.label //Getting ids from indexes doing -1 as mapping is id - 1
            : "N/A"}{" "}
        </div>
      ),
      //desktop
      width: "100px",
      columnClassName: "word_break",
      ellipsis: true,
      maxCharCount: 25,
    },
    [customer_table_config.last_transaction_date.field]: {
      field: customer_table_config.last_transaction_date.field,
      headerName: customer_table_config.last_transaction_date.placeholder,
      valueFormatter: (record) =>
        DateConvert(record, customer_table_config.last_transaction_date.field),
      noWrap: true,
      sortable: false,
    },
    [customer_table_config.tags.field]: {
      field: customer_table_config.tags.field,
      headerName: customer_table_config.tags.placeholder,
      valueFormatter: (record) => (
        <TagsField record={record} onClick={() => setShowTagsModal(record)} />
      ),
      noWrap: true,
      sortable: false,
    },
    [customer_table_config.source_link.field]: {
      field: `${customer_table_config.source_link.field}`,
      headerName: customer_table_config.source_link.placeholder,
      emptyField: "N/A",
      valueFormatter: (record) =>
        record.source_link ? (
          <LinkField
            onClick={(record) => {
              window.location.href = record.source_link;
            }}
            linkText={record.source_link}
            record={record}
            source={`${customer_table_config.source_link.field}`}
          />
        ) : (
          "N/A"
        ),
      noWrap: true,
    },
    [customer_table_config.answer_count.field]: {
      field: customer_table_config.answer_count.field,
      headerName: customer_table_config.answer_count.placeholder,
      valueFormatter: (record) => {
        if (!record?.answer_count) return "N/A";
        return (
          <LinkField
            onClick={() => {
              onResponsesClick(record);
            }}
            record={record}
            source={customer_table_config.answer_count.field}
            linkText="See responses"
          />
        );
      },
      noWrap: true,
    },

    [customer_table_config.country.field]: {
      field: `${customer_table_config.country.field}`,
      headerName: customer_table_config.country.placeholder,
      emptyField: "N/A",
      //desktop
      width: "100px",
      columnClassName: "word_break",
      ellipsis: true,
      maxCharCount: 25,
    },
    [customer_table_config.utm_info.field]: {
      field: customer_table_config.utm_info.field,
      headerName: customer_table_config.utm_info.placeholder,
      valueFormatter: (record) =>
        !is_empty(record.utm_info) ? <UTMInfoField record={record} /> : null,
      width: "10%",
      noWrap: true,
    },
    ...transformedObject,
    [customer_table_config.action.field]: hasPermissionToEditLogs(
      RESOURCE_KEYS.SUBSECTIONS.ALL_CUSTOMERS.ALL_CUSTOMERS
    )
      ? {
          field: "",
          headerName: customer_table_config.action.placeholder,
          valueFormatter: (record) => (
            <CustomerActionsField
              onClick={(record, e) => {
                const { id } = e.target;
                if (id === "note") setShowNotesModal(record?.customer_email);
                else
                  onShowTagsClicked(record?.customer_email, record?.username);
              }}
              record={record}
            />
          ),
          isCollapsed: true,
          hidden: true,
          noWrap: true,
          fixed: "right",
          width: "80px",
        }
      : null,
  };
};

export const filterConfig = [
  {
    source: "select",
    placeholder: "-select-",
    type: "input",
  },
  {
    source: customer_table_config.customer_name.filterField,
    placeholder: customer_table_config.customer_name.filterPlaceholder,
    type: "input",
    disableContains: true,
  },
  {
    source: `${customer_table_config.customer_email.field}__icontains`,
    placeholder: customer_table_config.customer_email.filterPlaceholder,
    type: "input",
    disableContains: true,
  },
  {
    source: customer_table_config.phone_number.field,
    placeholder: customer_table_config.phone_number.placeholder,
    type: "input",
  },
  {
    source: `${customer_table_config.utm_info.field}__source`,
    placeholder: "UTM source",
    type: "input",
  },
  {
    source: `${customer_table_config.utm_info.field}__medium`,
    placeholder: "UTM medium",
    type: "input",
  },
  {
    source: `${customer_table_config.utm_info.field}__campaign`,
    placeholder: "UTM campaign",
    type: "input",
  },
];

export const tabFilterConfig = [
  {
    label: "Loyal Customers",
    source: "loyal_customers",
    filterValue: "True",
    value: "loyal-customers_true",
  },
  {
    label: "Most valuable customers",
    source: "valuable_customers",
    filterValue: "True",
    value: "valuable-customers_true",
  },
  {
    label: "Need re-engagement",
    source: "re_engagement_needed",
    filterValue: "True",
    value: "re-engagement-needed_true",
  },
  {
    label: "New Customers",
    value: "new-customers_subTab",
    subTabs: [
      {
        label: "Joined in last 7 days",
        source: "days",
        filterValue: 7,
        value: "new-customers_7",
      },
      {
        label: "Joined in last 30 days",
        source: "days",
        filterValue: 30,
        value: "new-customers_30",
      },
    ],
  },
];

export const tableChipsFormatter = (customChipsToFormat) => (filter, value) => {
  // Check for custom chips first
  const customChip = formatCustomChip(customChipsToFormat, filter, value);
  if (customChip) return customChip;

  switch (filter) {
    case "loyal_customers":
      return null;
    case "valuable_customers":
      return null;
    case "re_engagement_needed":
      return null;
    case "days":
      return null;
    case customer_table_config.customer_name.filterField:
      return `${customer_table_config.customer_name.filterPlaceholder}: ${value}`;
    case `${customer_table_config.customer_email.field}__icontains`:
      return `${customer_table_config.customer_email.filterPlaceholder}: ${value}`;
    case `${customer_table_config.phone_number.field}__icontains`:
      return `${customer_table_config.phone_number.placeholder}: ${value}`;
    case `${customer_table_config.utm_info.field}__source__icontains`:
      return `UTM source: ${value}`;
    case `${customer_table_config.utm_info.field}__medium__icontains`:
      return `UTM medium: ${value}`;
    case `${customer_table_config.utm_info.field}__campaign__icontains`:
      return `UTM campaign: ${value}`;
    default:
      return undefined;
  }
};

//DEV TODO, this not being used by all customers table anymore but used at other places, need to clean overtime
export const getBroadcastActions = ({ sendEmail, sendWhatsappBroadcast }) => [
  {
    title: "Send WhatsApp Broadcast",
    description: "Broadcast will be sent to all the selected customers",
    onClick: sendWhatsappBroadcast,
    hidden: !orgPermissions.hasFeatureWriteAccess(
      RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS
    ),
    img: WhastappLogo,
  },
  {
    title: "Send an Email",
    description: "Send a Custom Email to the selected customers",
    onClick: sendEmail,
    hidden: !orgPermissions.hasFeatureWriteAccess(
      RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
    ),
    img: EmailLogo,
  },
];
