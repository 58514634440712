import React, { ChangeEvent, useRef, useState } from 'react';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import Button from '../../common/Button';
import Icon from '../../common/Icon';

import { isWidthUp, withWidth } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import isUrl from 'is-url';
import { insertEmbed } from '../../utils/embed';
import ImageForm from './ImageUploader/ImageForm';
import Popup from './ImageUploader/Popup';

interface EmbedProps {
  editor?: any;
  format: string;
  onChange?: Function;
  width: Breakpoint;
  imageUploadCallback?: Function;
  className?: string;
}

const Embed: React.FC<EmbedProps> = ({
  editor,
  format,
  onChange,
  width,
  imageUploadCallback,
  className,
}) => {
  const urlInputRef = useRef<any>();
  const [showInput, setShowInput] = useState(false);

  const [selection, setSelection] = useState();
  const [imgSrcValue, setImgSrcValue] = useState('');
  const [altValue, setAltValue] = useState('');
  const [loading, setLoading] = useState(false);

  const usingDesktop = isWidthUp('sm', width);

  const onImgSrcValueChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setImgSrcValue(event.target.value);

  const onAltValueChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setAltValue(event.target.value);

  const onClose = () => {
    setShowInput(false);
  };

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const addImageByFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const files = Array.from(event.target.files || []);

    await Promise.all(
      files.map(
        (file, index) =>
          new Promise(async (resolve, reject) => {
            try {
              if (imageUploadCallback) {
                const url = await imageUploadCallback(file);
                insertEmbed(editor, { url: url, alt: altValue }, format);
              } else {
                const b64String = await toBase64(file as File);
                insertEmbed(editor, { url: b64String, alt: altValue }, format);
              }
              resolve(file);
            } catch (error) {
              reject(error);
            }
          })
      )
    );

    setLoading(false);
    onClose();
  };

  const addImageUsingSrcValue = () => {
    imageUploadCallback?.(imgSrcValue);

    if (imgSrcValue && !isUrl(imgSrcValue)) {
      alert('URL is not an image');
      return;
    }

    selection && Transforms.select(editor, selection);
    selection && ReactEditor.focus(editor);

    insertEmbed(editor, { url: imgSrcValue, alt: altValue }, format);

    setShowInput(false);
    onClose();
    setImgSrcValue('');
  };

  const handleButtonClick = () => {
    setSelection(editor.selection);
    selection && ReactEditor.focus(editor);

    setShowInput((prev) => !prev);
  };

  return (
    <div ref={urlInputRef} className="popup-wrapper">
      <Button format={format} onClick={handleButtonClick} className={className}>
        <Icon icon={format} />
      </Button>

      <Popup
        drawerOpen={showInput}
        toggleDrawer={onClose}
        usingDesktop={usingDesktop}
        anchorEl={urlInputRef.current}
        onClose={onClose}
      >
        <ImageForm
          imgSrcValue={imgSrcValue}
          onImgSrcValueChange={onImgSrcValueChange}
          altValue={altValue}
          onAltValueChange={onAltValueChange}
          onSave={addImageUsingSrcValue}
          onClose={onClose}
          paddedForMobile={!usingDesktop}
          footerBtnsPosition={usingDesktop ? 'flex-end' : 'space-between'}
          onFileUpload={addImageByFileUpload}
          linkError={
            imgSrcValue && !isUrl(imgSrcValue)
              ? { error: true, helperText: 'Invalid URL' }
              : {}
          }
          loading={loading}
        />
      </Popup>
    </div>
  );
};

export default withWidth()(Embed);
