import React from "react";
import ExlyTableFilterInput from "./ExlyTableFilterInput";
import ExlyTableFilterDropdown from "./ExlyTableFilterDropdown";
import ExlyTableFilterDatePicker from "./ExlyTableFilterDatePicker";
import { ExlyTableFilterTimePicker } from "./ExlyTableFilterTimePicker/ExlyTableFilterTimePicker";

export const ALL = "all";
export const filterConstant = "filter";

export const filterSourceConstants = {
  segment_uid: "segment_uid",
};

export const FILTER_TYPES = {
  input: "input",
  dropdown: "dropdown",
  date: "date",
  time: "time",
};

export const getFilterTypes = (props) => {
  return {
    input: <ExlyTableFilterInput {...props} />,
    dropdown: <ExlyTableFilterDropdown {...props} />,
    date: <ExlyTableFilterDatePicker {...props} />,
    time: <ExlyTableFilterTimePicker {...props} />,
  };
};
