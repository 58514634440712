import React, { useState } from "react";
import ExlySpinner from "ui/modules/ExlySpinner/ExlySpinner";
import { Input } from "@my-scoot/component-library-legacy";
import { useCustomColumnUpdate } from "../../CustomColumn/utils/useCustomColumnUpdate";
import { UrlFieldEndAdornment } from "./modules/UrlFieldEndAdornment/UrlFieldEndAdornment";
import styles from "./UrlField.module.css";
import { validURLV2 } from "utils/validations";
import classnames from "classnames";
import { WarningRounded } from "@material-ui/icons";
import { is_empty } from "features/Common/utils/common.utils";

export const UrlField = ({
  targetObject,
  handleUpdate,
  defaultValue = "",
  disabled,
}) => {
  const [error, setError] = useState("");
  const { loading, selectedValue, handleUpdateColumnData, setSelectedValue } =
    useCustomColumnUpdate({
      handleUpdate,
      targetObject,
      defaultValue,
    });

  if (loading) return <ExlySpinner loaderWidth="24px" wrapperPadding="0 0" />;

  const handleBlur = ({ target: { value } }) => {
    if (is_empty(value) || validURLV2(value)) {
      handleUpdateColumnData(value);
      setError("");
    } else {
      setError("Invalid URL");
    }
  };
  return (
    <div className={classnames(styles.urlWrapper, "w-100")}>
      <Input
        placeholder="Paste URL"
        value={selectedValue}
        disabled={disabled}
        onChange={({ target: { value } }) => setSelectedValue(value)}
        onBlur={handleBlur}
        className={styles.urlInputClass}
        endAdornment={<UrlFieldEndAdornment value={selectedValue} />}
        fullWidth
      />
      {error && selectedValue && (
        <span className={classnames(styles.error, "text-danger")}>
          <WarningRounded
            className={classnames("mr-1", styles.errorIcon)}
            size="small"
          />
          {error}
        </span>
      )}
    </div>
  );
};
