import React from "react";
import { checkUserValidity } from "./ExlyConnect.utils";
import { isRequestSuccessful } from "utils/Utils";
import { logError } from "utils/error";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";

export const useCheckUserValidity = () => {
  const [errorMessage, setErrorMessage] = React.useState("");

  const [isUserValid, setIsUserValid] = React.useState(false);

  const handleCheckUserValidity = async () => {
    try {
      const response = await checkUserValidity();
      if (isRequestSuccessful(response?.status)) {
        setIsUserValid(true);
      }
    } catch (err) {
      const error = { ...err };
      setErrorMessage(error?.body?.message || SOMETHING_WENT_WRONG);
      logError({
        error: err,
        occuredAt:
          "src/features/ExlyConnect/pages/OndemandExlyConnect/index.js",
        when: "checking user validity",
      });
    }
  };
  return {
    isUserValid,
    handleCheckUserValidity,
    errorMessage,
  };
};
