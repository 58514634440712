import {
  ListsEditor,
  onKeyDown as slateListOnKeyDown,
} from '@prezly/slate-lists';
import classnames from 'classnames';
import isHotkey, { isKeyHotkey } from 'is-hotkey';
import { FC } from 'react';
import { Range, Transforms } from 'slate';
import { Editable, ReactEditor, useSlate } from 'slate-react';
import { EditableProps } from 'slate-react/dist/components/editable';
import { toggleMark } from '../../../RichTextEditorV2/utils/SlateUtilityFunctions';
import { IRTEEditable } from './RTEEditable.interfaces';
import useStyles from './RTEEditable.styles';
import { HOTKEYS } from './constants/RTEEditable.constants';
import { RTEElementRenderer } from './modules/RTEElementRenderer/RTEElementRenderer';
import { RTELeafRenderer } from './modules/RTELeafRenderer/RTELeafRenderer';

export const RTEEditable: FC<IRTEEditable> = ({
  isReadOnly,
  showChipsOnReadOnly,
  className,
  onKeyDown,
  ...props
}) => {
  const styles = useStyles();
  const editor = useSlate();
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf('android') > -1;

  const handleKeyDown: EditableProps['onKeyDown'] = (event) => {
    if (onKeyDown) onKeyDown(event);

    slateListOnKeyDown(editor as ListsEditor & ReactEditor, event);

    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event as any)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];
        toggleMark(editor, mark);
      }
    }

    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const { nativeEvent } = event;
      if (isKeyHotkey('left', nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: 'offset', reverse: true });
        return;
      }
      if (isKeyHotkey('right', nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: 'offset' });
        return;
      }
    }
  };

  const handleDOMBeforeInput = (event: InputEvent) => {
    if (isAndroid) {
      const { inputType } = event;

      if (inputType === 'deleteContentBackward') {
        event.preventDefault();
        Transforms.delete(editor, {
          unit: 'character',
          distance: 1,
          reverse: true,
        });

        return;
      }
    }
  };

  return (
    <Editable
      renderElement={(props) =>
        RTEElementRenderer({
          ...props,
          readOnly: isReadOnly,
          showChipsOnReadOnly,
        })
      }
      renderLeaf={RTELeafRenderer}
      readOnly={isReadOnly}
      onKeyDown={handleKeyDown}
      onDOMBeforeInput={handleDOMBeforeInput}
      renderPlaceholder={({ children, attributes }) => (
        <div className={styles.placeholder} {...attributes}>
          {children}
        </div>
      )}
      className={classnames(styles.root, className)}
      {...props}
    />
  );
};
