import FormatUnderlinedRoundedIcon from '@material-ui/icons/FormatUnderlinedRounded';
import { RTE_TOOLBAR_MARKS } from '../../constants/RTEToolbar.marks';
import { MarkToolButton } from '../MarkToolButton/MarkToolButton';

export const UnderlineTool = () => {
  return (
    <MarkToolButton markFormat={RTE_TOOLBAR_MARKS.underline}>
      <FormatUnderlinedRoundedIcon />
    </MarkToolButton>
  );
};
