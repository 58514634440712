import { getStylesObjFromCss } from "../../../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    flaggedTag: {
      display: "flex",
      alignItems: "center",
      gap: 3,
      flex: "none",
      padding: "4px 8px",
      backgroundColor: "#FFF0F0",
      borderRadius: 4,
      fontSize: "0.75rem",
      lineHeight: "normal",
      color: "#FF4A4A !important",
      fontFamily: "Helvetica Neue",
      fontWeight: 500,
      "& svg": {
        fontSize: 15,
      },
    },
  },
  componentName: "FlaggedTag",
});
