import React from "react";
import { Layout } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@my-scoot/component-library-legacy";
import {
  creatorToolTheme,
  ThemeProvider,
} from "@my-scoot/component-library-legacy";
import { lightTheme } from "./theme";
import AppBar from "./AppBar/AppBar";
import Menu from "./Menu/Menu";

import ExlyMobileNavigation from "../../common/Components/ExlyMobileNavigation";
import { EXLY_GLOBAL__CUSTOM_BACK_NAVIGATION } from "redux/actions";
import { SomethingWentWrong } from "features/SomethingWentWrong/SomethingWentWrong";

const useStyles = makeStyles(
  () => ({
    pageWrapper: {
      paddingBottom: ({ isDesktop, showBottomNav }) =>
        !isDesktop && showBottomNav ? "80px" : 0,
      //Padding to is added to have custom app bar height on mobile
      paddingTop: ({ isDesktop }) => (isDesktop ? 0 : "8px"),
    },
    navbar: {
      position: "fixed",
      top: "0",
      left: "0",
      zIndex: 10,
      width: "100%",
      height: "64px",
      background: "#fff",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      borderBottom: "2px solid #E7E6E4",
      paddingLeft: "16.21px",
      paddingRight: "16px",
      color: "#272522",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: 700,
    },
    navbarLeftSection: {
      display: "flex",
      gap: "11.79px",
      flexGrow: 1,
      alignItems: "center",
    },
    backBtn: {
      padding: "0",
      "& svg": {
        color: "#493AB1",
      },
    },
    layoutRoot: {
      "& header": {
        transition: "none !important",
        transform: "none !important",
        visibility: "visible !important",
      },
    },
    content: {
      position: "relative",
    },
    pageTitle: {
      width: "100%",
    },
  }),
  { name: "CreatorTool" }
);

export default function CustomLayout(props) {
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const {
    showAppBar,
    showBottomNav,
    pageTitle,
    navBarSecondaryAction,
    showPageTitle,
    customBackAction,
  } = useSelector((state) => state.global);
  const classes = useStyles({ isDesktop, showBottomNav });

  const dispatch = useDispatch();
  const NavigationBar = () => (
    <>
      {showPageTitle ? (
        <ThemeProvider theme={creatorToolTheme}>
          <div className={classes.navbar}>
            <div className={classes.navbarLeftSection}>
              <IconButton
                className={classes.backBtn}
                onClick={() => {
                  if (customBackAction) {
                    customBackAction();
                    dispatch({
                      type: EXLY_GLOBAL__CUSTOM_BACK_NAVIGATION,
                      payload: null,
                    });
                  } else window.history.go(-1);
                }}
              >
                <ArrowBackRoundedIcon />
              </IconButton>
              <div className={classes.pageTitle}>{pageTitle}</div>
            </div>
            {navBarSecondaryAction.show ? (
              <Button {...navBarSecondaryAction.btnProps}>
                {navBarSecondaryAction.label}
              </Button>
            ) : null}
          </div>
        </ThemeProvider>
      ) : (
        <> </>
      )}
    </>
  );

  return (
    <div className={classes.pageWrapper}>
      <Layout
        {...props}
        error={SomethingWentWrong}
        appBar={showAppBar ? AppBar : NavigationBar}
        classes={{ root: classes.layoutRoot, content: classes.content }}
        menu={Menu}
        theme={lightTheme}
      />
      {!isDesktop && showBottomNav && <ExlyMobileNavigation />}
    </div>
  );
}
