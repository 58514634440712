import React from "react";
import ExlySpinner from "ui/modules/ExlySpinner/ExlySpinner";
import { Input } from "@my-scoot/component-library-legacy";
import { useCustomColumnUpdate } from "../../CustomColumn/utils/useCustomColumnUpdate";
import { is_empty } from "features/Common/utils/common.utils";
import { LongTextFieldValue } from "./modules/LongTextFieldValue/LongTextFieldValue";
import { useLongTextFieldValueModal } from "./modules/LongTextFieldValueModal/utils/useLongTextFieldValueModal";
import { LongTextFieldValueModal } from "./modules/LongTextFieldValueModal/LongTextFieldValueModal";
import styles from "./LongTextField.module.css";
import classnames from "classnames";
import { LONG_TEXT_MAX_CHAR_LIMIT } from "./constants/LongTextField.constants";

export const LongTextField = ({
  targetObject,
  handleUpdate,
  defaultValue = "",
  disabled,
  inputClass,
}) => {
  const { loading, selectedValue, setSelectedValue, handleUpdateColumnData } =
    useCustomColumnUpdate({
      handleUpdate,
      targetObject,
      defaultValue,
    });

  const { longTextValueModalOpen, openLongTextValueModal } =
    useLongTextFieldValueModal();

  if (loading) {
    return <ExlySpinner loaderWidth="24px" wrapperPadding="0 0" />;
  }

  return (
    <div className="w-100">
      {longTextValueModalOpen === targetObject && (
        <LongTextFieldValueModal
          handleSubmit={handleUpdate}
          value={selectedValue}
          onChange={setSelectedValue}
          disabled={disabled}
        />
      )}
      {is_empty(selectedValue) ? (
        <Input
          placeholder="Add text..."
          value={selectedValue}
          disabled={disabled}
          onClick={() => !disabled && openLongTextValueModal(targetObject)}
          multiline
          className={classnames(styles.inputClass, inputClass)}
          inputElementClassName={styles.inputElement}
          fullWidth
          inputProps={{ maxLength: LONG_TEXT_MAX_CHAR_LIMIT }}
        />
      ) : (
        <LongTextFieldValue
          value={selectedValue}
          targetObject={targetObject}
          handleUpdate={(value) => handleUpdateColumnData(value)}
          wrapperClass={inputClass}
          disabled={disabled}
        />
      )}
    </div>
  );
};
