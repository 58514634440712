import React from "react";
import ExlySpinner from "ui/modules/ExlySpinner/ExlySpinner";
import { Dropdown } from "@my-scoot/component-library-legacy";
import { useCustomColumnUpdate } from "../../CustomColumn/utils/useCustomColumnUpdate";
import { empty_option } from "common/Components/AssignMemberDropdown/AssignMemberDropdown.constants";
import styles from "./AssignMemberDropdownField.module.css";

export const AssignMemberDropdownField = ({
  options,
  targetObject,
  handleUpdate,
  defaultValue,
  disabled,
  messageToNotify,
}) => {
  const { loading, selectedValue, handleUpdateColumnData } =
    useCustomColumnUpdate({
      messageToNotify: messageToNotify || "Member Assigned Successfully!",
      handleUpdate,
      targetObject,
      defaultValue,
    });

  if (loading) return <ExlySpinner loaderWidth="24px" wrapperPadding="0 0" />;
  return (
    <Dropdown
      options={options}
      placeholder="Assign Member"
      messageToNotify="Member assigned successfully"
      value={
        !selectedValue || selectedValue === empty_option.value
          ? ""
          : selectedValue
      }
      disabled={disabled}
      handleChange={handleUpdateColumnData}
      formControlClassName={styles.dropdownFormControl}
      fullWidth
    />
  );
};
