import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import MuiPopper from '@material-ui/core/Popper';

import classnames from 'classnames';
import React, { FC, useState } from 'react';
import { useStyles } from './DesktopTable.styles';
import { DesktopPaginationProps } from '../../atoms/DesktopPagination/DesktopPagination';
import {
  Avatar,
  Checkbox,
  Tooltip,
  DesktopPagination,
  Button,
  Card,
} from '../../atoms';
import EnhancedTableHead from './components/EnhancedTableHead/EnhancedTableHead';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import DesktopTableLoader from './components/DesktopTableLoader/DesktopTableLoader';
import withGenerateClassName from '../../../themes/withGenerateClassName';

export interface Column {
  field: string;
  headerName: string;
  width: string;
  editable?: boolean;
  avatar?: boolean;
  ellipsis?: boolean;
  align?: 'left' | 'right' | 'center';
  renderCell?: Function;
  renderHeader?: Function;
  valueFormatter?: Function;
  columnClassName?: any;
  headerClassName?: any;
  noWrap?: boolean;
  sortable?: boolean;
  hiddenDesktopColumn?: boolean;
  emptyField?: string;
  maxCharCount?: number;
  fixed?: 'left' | 'right' | any;
}

interface PrimaryAction {
  label?: string;
  renderCustomAction?: (
    row: any,
    rows: any[],
    setAnchorEl: Function,
    setOpenPopper: Function
  ) => React.ReactNode;
  onClick?: (row: any, rows: any[]) => void;
  onClose?: (row: any, rows: any[]) => void;
}

interface TableProps {
  variant?: 'striped';
  rows: any[];
  columns: Column[];
  selected?: any[];
  checkboxSelection?: boolean;
  disableSelectionOnClick?: boolean;
  border?: boolean;
  stickyHeader?: boolean;
  onCellEditCommit?: Function;
  onSortOrderChange?: Function;
  onRowSelected?: Function;
  getRowClassName?: (row: any, rows: any[]) => string;
  tableClassName?: any;
  tableContainerClassName?: any;
  rootWrapperClassName?: any;
  paginationClassName?: any;
  primaryKey?: string;
  paginationProps?: DesktopPaginationProps;
  noWrap?: boolean;
  primaryActions?: PrimaryAction[];
  shouldRenderPrimaryAction?: (row: any, rows: any[]) => boolean;
  onClickMoreButton?: (row: any, rows: any[], e: any) => void;
  desktopCustomPrimaryAction?: PrimaryAction;
  primaryColumnProps?: {
    title?: string;
    width?: any;
    align?: 'left' | 'right' | 'center';
    sticky?: boolean;
    className?: any;
  };
  loading?: boolean;
  allValuesSelected?: boolean;
}

export interface StyleProps {
  variant?: string;
}

const DesktopTable: FC<TableProps> = ({
  variant,
  rows,
  columns: tableColumns,
  checkboxSelection = false,
  disableSelectionOnClick = true,
  onCellEditCommit,
  border,
  onSortOrderChange,
  stickyHeader = false,
  onRowSelected,
  primaryKey = tableColumns[0].field,
  paginationProps,
  tableClassName,
  tableContainerClassName,
  rootWrapperClassName,
  paginationClassName,
  primaryActions,
  shouldRenderPrimaryAction,
  onClickMoreButton,
  desktopCustomPrimaryAction,
  primaryColumnProps,
  loading,
  selected,
  getRowClassName,
  allValuesSelected = false,
}) => {
  const classes = useStyles({ variant });

  const [order, setOrder] = useState<string>();
  const [orderBy, setOrderBy] = useState<string>();
  const [openEditCell, setOpenEditCell] = useState<{
    field: string;
    index: number;
  }>();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopper, setOpenPopper] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentAction, setCurrentAction] = useState<PrimaryAction | null>(
    null
  );

  // Utils
  const handleRequestSort = (event: any, property: string) => {
    if (orderBy === property && order === 'desc') {
      setOrderBy(undefined);
      onSortOrderChange?.(undefined, undefined);
      return;
    }

    const isAsc: boolean = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    onSortOrderChange?.(property, isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked)
      onRowSelected?.(rows.map((row) => row[primaryKey]));
    else onRowSelected?.([]);
  };

  const handleClick = (event: any, selectedRow: any) => {
    if (!selected) return;

    const selectedIndex = selected.indexOf(selectedRow[primaryKey]);

    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, selectedRow[primaryKey]);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    onRowSelected?.(newSelected);
  };

  const getEllipsisString = (str: string, max: number = 60) =>
    str.length > max ? str.substring(0, max) + '...' : str;

  const isSelected = (row: any) =>
    (selected || []).indexOf(row[primaryKey]) !== -1;

  const getFixedColumnStyle = (columnIndex: number, type: 'head' | 'cell') => {
    if (!tableColumns[columnIndex].fixed) return;

    let style: any = { position: 'sticky', zIndex: type === 'head' ? 3 : 1 };

    if (tableColumns[columnIndex].fixed === 'left') {
      style.left = checkboxSelection ? 32 : 0;

      let avatarCount = 0;

      for (let i = 0; i < columnIndex; ++i) {
        if (tableColumns[i].avatar) avatarCount++;
        style.left +=
          Number.parseFloat(tableColumns[i].width.split('px')[0]) +
          (tableColumns[i].sortable
            ? border
              ? 57.17
              : 55.17
            : border
            ? 50
            : 48);
      }

      style.left -= 8 * avatarCount;

      style.left = `${style.left}px`;
    } else {
      style.right = 0;

      for (let i = tableColumns.length - 1; i > columnIndex; --i)
        style.right +=
          Number.parseFloat(tableColumns[i].width.split('px')[0]) +
          (tableColumns[i].sortable
            ? border
              ? 57.17
              : 55.17
            : border
            ? 50
            : 48);

      columnIndex < tableColumns.length - 1 &&
        tableColumns[columnIndex + 1].avatar &&
        (style.left += 8);

      primaryActions &&
        primaryActions?.length > 1 &&
        tableColumns[tableColumns.length - 1]?.fixed &&
        (style.right += 48);

      primaryActions &&
        (primaryActions.length === 1 || desktopCustomPrimaryAction) &&
        tableColumns[tableColumns.length - 1]?.fixed &&
        (style.right += Number.parseFloat(
          primaryColumnProps?.width.split('px')[0]
        ));

      style.right = `${style.right}px`;
    }

    return style;
  };

  const handleMoreIconClick = (event: any, row: any) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper((prev) => anchorEl !== event.currentTarget || !prev);
    setCurrentRecord(row);
    onClickMoreButton?.(row, rows, event);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
    setOpenPopper(false);
    setCurrentAction(null);
    setCurrentRecord(null);
    currentAction?.onClose?.(currentRecord, rows);
  };

  const renderAction = (row: any, currentAction: PrimaryAction) =>
    currentAction.renderCustomAction ? (
      currentAction.renderCustomAction(row, rows, setAnchorEl, setOpenPopper)
    ) : (
      <Button
        size="thin"
        color="link"
        variant="text"
        disableTouchRipple={true}
        className={classes.seeMoreBtn}
        onClick={() => {
          setCurrentAction(currentAction);
          currentAction.onClick?.(row, rows);
        }}
      >
        <span>{currentAction.label}</span>
        <ChevronRightRoundedIcon />
      </Button>
    );

  const getPrimaryActions = (row: any) => {
    if (desktopCustomPrimaryAction)
      return (
        <TableCell
          className={classnames(
            classes.cell,
            border && classes.border,
            classes.moreIconButtonCell,
            (primaryColumnProps?.sticky ||
              tableColumns[tableColumns.length - 1]?.fixed) &&
              classes.stickyRight,
            (primaryColumnProps?.sticky ||
              tableColumns[tableColumns.length - 1]?.fixed) &&
              classes.shadowLeft
          )}
          align={primaryColumnProps?.align || 'center'}
        >
          <div style={{ width: primaryColumnProps?.width }}>
            {renderAction(row, desktopCustomPrimaryAction)}
          </div>
        </TableCell>
      );

    if (!primaryActions || primaryActions.length === 0) return;

    const action =
      primaryActions?.length === 1 ? (
        <TableCell
          className={classnames(
            classes.cell,
            border && classes.border,
            classes.moreIconButtonCell,
            (primaryColumnProps?.sticky ||
              tableColumns[tableColumns.length - 1]?.fixed) &&
              classes.stickyRight,
            primaryColumnProps?.sticky && classes.shadowLeft
          )}
          align={primaryColumnProps?.align || 'center'}
        >
          <div style={{ width: primaryColumnProps?.width }}>
            {renderAction(row, primaryActions[0])}
          </div>
        </TableCell>
      ) : (
        <TableCell
          className={classnames(
            classes.cell,
            border && classes.border,
            classes.moreIconButtonCell,
            (primaryColumnProps?.sticky ||
              tableColumns[tableColumns.length - 1]?.fixed) &&
              classes.stickyRight,
            primaryColumnProps?.sticky && classes.shadowLeft
          )}
          style={{
            padding: 0,
            paddingRight: '16px',
          }}
          align={primaryColumnProps?.align || 'center'}
        >
          <IconButton
            className={classes.moreIconButton}
            onClick={(e: any) => handleMoreIconClick(e, row)}
          >
            <MoreIcon />
          </IconButton>
        </TableCell>
      );

    return shouldRenderPrimaryAction ? (
      shouldRenderPrimaryAction(row, rows) ? (
        action
      ) : (
        <TableCell
          className={classnames(
            classes.cell,
            border && classes.border,
            classes.moreIconButtonCell,
            (primaryColumnProps?.sticky ||
              tableColumns[tableColumns.length - 1]?.fixed) &&
              classes.stickyRight,
            (primaryColumnProps?.sticky ||
              tableColumns[tableColumns.length - 1]?.fixed) &&
              classes.shadowLeft
          )}
          align="center"
        />
      )
    ) : (
      action
    );
  };
  // Utils

  if (loading) return <DesktopTableLoader />;

  return (
    <div className={classnames(classes.root, rootWrapperClassName)}>
      <TableContainer
        className={classnames(
          classes.tableContainerRoot,
          tableContainerClassName
        )}
      >
        <Table
          className={classnames(classes.table, tableClassName)}
          stickyHeader={stickyHeader}
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected?.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            columns={tableColumns}
            checkboxSelection={checkboxSelection}
            variant={variant}
            getFixedColumnStyle={getFixedColumnStyle}
            border={border}
            primaryActions={primaryActions}
            desktopCustomPrimaryAction={desktopCustomPrimaryAction}
            primaryColumnProps={primaryColumnProps}
            allValuesSelected={allValuesSelected}
          />
          <TableBody>
            {rows.map((row, index) => {
              const isItemSelected = isSelected(row) || allValuesSelected;
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  classes={{
                    root: classnames(
                      classes.tableRow,
                      getRowClassName?.(row, rows)
                    ),
                    hover: classes.hover,
                    selected: classes.selected,
                  }}
                  hover
                  onClick={
                    disableSelectionOnClick
                      ? undefined
                      : (event) => handleClick(event, row)
                  }
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={index}
                  selected={isItemSelected}
                  // @dev - assuming only single table is rendered in a page with the same data
                  // In future if the case arises for multiple tables with same data to be rendered on same page, we will need to add table specific prefix to the id to avoid collision.
                  id={row.id}
                >
                  {checkboxSelection && (
                    <TableCell
                      className={classnames(
                        classes.cell,
                        classes.checkboxCell,
                        border && classes.checkboxBorder
                      )}
                      padding="checkbox"
                      style={{
                        ...(tableColumns[0].fixed && {
                          position: 'sticky',
                          left: 0,
                          zIndex: 3,
                        }),
                      }}
                    >
                      <Checkbox
                        size="large"
                        className={classes.checkbox}
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                        onClick={
                          disableSelectionOnClick
                            ? (event: any) => handleClick(event, row)
                            : undefined
                        }
                      />
                    </TableCell>
                  )}

                  {tableColumns.map((column, columnIndex) => {
                    if (column.hiddenDesktopColumn) return;

                    const value = column.valueFormatter
                      ? column.valueFormatter(row)
                      : row[column.field] || column.emptyField;

                    return column.renderCell ? (
                      <TableCell
                        key={columnIndex}
                        className={classnames(
                          classes.cell,
                          border && classes.border,
                          columnIndex < tableColumns.length - 1 &&
                            column.fixed === 'left' &&
                            !tableColumns[columnIndex + 1].fixed &&
                            classes.shadowRight,
                          columnIndex > 0 &&
                            !tableColumns[columnIndex - 1].fixed &&
                            tableColumns[columnIndex].fixed === 'right' &&
                            classes.shadowLeft,
                          column.field === orderBy && classes.activeSortColumn,
                          column.columnClassName
                        )}
                        align={column.align}
                        style={{
                          width: column.width,
                          borderLeft: columnIndex === 0 && 'none',
                          borderRight:
                            columnIndex === tableColumns.length - 1 && 'none',
                          ...getFixedColumnStyle(columnIndex, 'cell'),
                        }}
                      >
                        <div
                          style={{
                            width:
                              column.width &&
                              column.width.indexOf('px') !== -1 &&
                              column.width,
                          }}
                        >
                          {column.renderCell(row)}
                        </div>
                      </TableCell>
                    ) : (
                      <TableCell
                        key={columnIndex}
                        className={classnames(
                          classes.cell,
                          border && classes.border,
                          columnIndex < tableColumns.length - 1 &&
                            column.fixed === 'left' &&
                            !tableColumns[columnIndex + 1].fixed &&
                            classes.shadowRight,
                          columnIndex > 0 &&
                            !tableColumns[columnIndex - 1].fixed &&
                            tableColumns[columnIndex].fixed === 'right' &&
                            classes.shadowLeft,
                          column.columnClassName,
                          column.avatar && classes.avatarCell,
                          column.field === orderBy && classes.activeSortColumn
                        )}
                        align={column.align}
                        style={{
                          width: column.width,
                          borderLeft: columnIndex === 0 && 'none',
                          borderRight:
                            columnIndex === tableColumns.length - 1 && 'none',
                          ...getFixedColumnStyle(columnIndex, 'cell'),
                        }}
                      >
                        <div
                          className={classnames(
                            column.avatar && classes.avatarContainer,
                            !column.avatar &&
                              column.editable &&
                              classnames(
                                classes.editable,
                                classes.editableCell,
                                openEditCell &&
                                  openEditCell.field === column.field &&
                                  openEditCell.index === index &&
                                  classes.hideCell
                              )
                          )}
                          style={{
                            width:
                              column.width &&
                              column.width.indexOf('px') !== -1 &&
                              column.width,
                          }}
                        >
                          {column.avatar && (
                            <Avatar
                              fontSize="large"
                              label={row?.[column?.field] ?? column.emptyField}
                              colorIndex={index as any}
                              className={classnames(
                                classes.avatar,
                                openEditCell &&
                                  openEditCell.field === column.field &&
                                  openEditCell.index === index &&
                                  classes.hideCell
                              )}
                            />
                          )}
                          <Tooltip
                            title={
                              column.ellipsis &&
                              value.length >= (column.maxCharCount || 60)
                                ? value
                                : ''
                            }
                            color="primary"
                            arrow
                            placement="top"
                          >
                            <span
                              className={classnames(
                                column.noWrap && classes.noWrap,
                                column.avatar &&
                                  column.editable &&
                                  classnames(
                                    classes.editable,
                                    classes.editableCell,
                                    openEditCell &&
                                      openEditCell.field === column.field &&
                                      openEditCell.index === index &&
                                      classes.hideCell
                                  )
                              )}
                              onDoubleClick={
                                column.editable
                                  ? () =>
                                      setOpenEditCell({
                                        field: column.field,
                                        index,
                                      })
                                  : undefined
                              }
                            >
                              {column.ellipsis
                                ? getEllipsisString(value, column.maxCharCount)
                                : value}
                            </span>
                          </Tooltip>
                        </div>

                        {column.editable &&
                          openEditCell &&
                          openEditCell.field === column.field &&
                          openEditCell.index === index && (
                            <TextField
                              defaultValue={row[column.field]}
                              id="outlined-basic"
                              variant="outlined"
                              InputProps={{
                                classes: {
                                  notchedOutline: classes.specialOutline,
                                  focused: classes.textFieldFocused,
                                  input: classes.textFieldInput,
                                },
                              }}
                              className={classes.textFieldRoot}
                              autoFocus
                              fullWidth
                              margin="none"
                              onBlur={(e) => {
                                setOpenEditCell(undefined);
                                onCellEditCommit?.({
                                  row,
                                  payload: {
                                    field: column.field,
                                    data: e.target.value,
                                  },
                                });
                              }}
                              onKeyDown={(e: any) => {
                                if (e.key === 'Enter' || e.key === 'Escape') {
                                  setOpenEditCell(undefined);
                                  onCellEditCommit?.({
                                    row,
                                    payload: {
                                      field: column.field,
                                      data: e.target.value,
                                    },
                                  });
                                }
                              }}
                            />
                          )}
                      </TableCell>
                    );
                  })}

                  {getPrimaryActions(row)}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {openPopper && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <MuiPopper
            open={openPopper}
            anchorEl={anchorEl}
            placement="bottom-end"
            transition
            style={{ zIndex: 9999 }}
          >
            <Card CardClassName={classes.moreActionPopper}>
              {primaryActions?.map((currentAction, index) =>
                currentAction.renderCustomAction ? (
                  currentAction.renderCustomAction(
                    currentRecord,
                    rows,
                    setAnchorEl,
                    setOpenPopper
                  )
                ) : (
                  <span
                    role="button"
                    key={index}
                    onClick={() => {
                      setCurrentAction(currentAction);
                      setOpenPopper(false);
                      currentAction.onClick?.(currentRecord, rows);
                    }}
                  >
                    {currentAction.label}
                  </span>
                )
              )}
            </Card>
          </MuiPopper>
        </ClickAwayListener>
      )}
      {paginationProps && (
        <DesktopPagination
          paginationClassName={classnames(
            classes.paginationClassName,
            paginationClassName
          )}
          {...paginationProps}
        />
      )}
    </div>
  );
};

export default withGenerateClassName(DesktopTable);
