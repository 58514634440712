import { useSlate } from 'slate-react';
import TableSelector from '../../../../../RichTextEditorV2/Elements/Table/TableSelector';
import { useStyles } from './TableTool.styles';

export const TableTool = () => {
  const editor = useSlate();
  const classes = useStyles();

  return <TableSelector className={classes.root} editor={editor} />;
};
