import { FC } from 'react';
import { useSlate } from 'slate-react';
import Embed from '../../../../../RichTextEditorV2/Elements/Embed/Embed';
import { useStyles } from './ImageTool.styles';

export const ImageTool: FC<{
  imageUploadCallback?: (file?: string | File) => void;
}> = ({ imageUploadCallback }) => {
  const editor = useSlate();
  const classes = useStyles();

  return (
    <Embed
      className={classes.root}
      format="image"
      editor={editor}
      imageUploadCallback={imageUploadCallback}
    />
  );
};
