import ReportGmailerrorredRoundedIcon from "@mui/icons-material/ReportGmailerrorredRounded";
import React, { FC } from "react";
import { styles } from "./FlaggedTag.styles";
import { classNames } from "../../../../../utils/common/styling";

export const FlaggedTag: FC<{ label: string; className?: string }> = ({
  label,
  className,
}) => {
  return (
    <div className={classNames(styles.flaggedTag, className)}>
      <ReportGmailerrorredRoundedIcon />
      {label}
    </div>
  );
};
