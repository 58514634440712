import { api, dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { useState, useEffect } from "react";
import { logError } from "utils/error";

const useOrgMemberList = () => {
  const [orgMembers, setOrgMembers] = useState([]);

  const getOrgMembers = async () => {
    try {
      const response = await dataProvider.custom_request(
        api.get_assignee_list,
        apiMethods.GET
      );
      const { data } = response || {};

      if (data && data.assignment_members) {
        const members = data.assignment_members.map((member) => ({
          id: member.org_uuid,
          name: member.member_name,
          label: member.member_name,
          value: member.org_uuid,
          ...member,
        }));
        setOrgMembers(members);
      }
    } catch (error) {
      logError({
        error: error,
        occuredAt: "getOrgMembers in src/utils/hooks/useOrgMemberList.js",
        when: "Error in fetching users/staff/assignee/list",
      });
    }
  };

  /**
   * Component Mounting functions
   */
  useEffect(() => {
    getOrgMembers();
  }, []);

  return {
    orgMembers,
  };
};

export default useOrgMemberList;
