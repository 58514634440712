// if: lines of code > 300
// then: breakdown this file and move to src/features/ExlyConnect/utils folder

import SessionStorageConstants from "constants/SessionStorage.constants";
import { app_route_keys } from "constants/urlPaths";
import { dataProvider } from "data";
import { navigateTo } from "utils/urlUtils";
import { EXLY_CONNECT_APIS } from "./ExlyConnect.api";
import { apiMethods } from "data/api.constants";

export const onTabChange = (_, value) => {
  sessionStorage.setItem(
    SessionStorageConstants.ACTIVE_EXLY_CONNECTED_TAB,
    value
  );
  navigateTo(app_route_keys.exly_connect, { args: value });
  window.location.reload();
};

export const checkUserValidity = async () => {
  return dataProvider.custom_request(
    EXLY_CONNECT_APIS.check_user_validity,
    apiMethods.GET
  );
};
