import IconButton from "@material-ui/core/IconButton";
import classnames from "classnames";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import {
  ExlyDatePicker,
  ExlyDropdown,
  ExlyInput,
  ExlyOfferingSelect,
  ExlyPhoneInput,
  ExlySelect,
} from "common/form";
import ExlyCheckbox from "common/form/ExlyCheckbox";
import { finalFormParsers, minLength } from "common/validate";
import constants from "constants/constants";
import { DEFAULT_TIME_FORMAT_12 } from "constants/dateTime";
import { PAYMENT_PLAN_TYPE, webinarMaxSpot } from "constants/schedule";
import { api } from "data";
import dataProvider from "data/dataProvider";
import { ADD_CUSTOMER_FORM_KEYS } from "features/Crm/Crm.constants";
import { PAYOUT_DETAILS_KEYS } from "features/Crm/modules/Bookings/modules/AddBookings/modules/AdvancedDetails/modules/hooks/usePayoutDetails/usePayoutDetails.constants";
import { getAvailableSlots } from "features/Listings/utils/Listings.utils";
import { fetchOfferingVariations } from "features/OfferingVariations/utils/OfferingVariations.utils";
import { useOfferings } from "hooks/useOfferings";
import { Field, useForm, useFormState } from "react-final-form";
import planConstants from "schedule-v2/Plans/Constants";
import { useFetchPlanListings } from "schedule-v2/Plans/utils";
import { PART_PAYMENT_FORM_KEYS } from "schedule-v2/commonPages/Components/PaymentPlanWithInstallments/PaymentPlanWithInstallments.data";
import { isInstallmentsPlanType } from "schedule-v2/commonPages/Components/PaymentPlanWithInstallments/utils/PaymentPlanWithInstallments.utils";
import { Label } from "schedule-v2/components/recorded-content/ExamCreation/components/HelperComponents/HelperComponents";
import { OFFERING_VARIANT_KEY } from "ui/pages/customers/ManageBookings/SubscriptionCustomers/NewCustomers.constants";
import { getUserTimezone, isInternationalTimezone } from "utils/AuthUtil";
import { handleDateFormat, is_class } from "utils/Utils";
import { composeValidators, is_empty } from "utils/validations";
import AdvancedDetails from "../Bookings/modules/AddBookings/modules/AdvancedDetails/AdvancedDetails";
import { timezoneOptions } from "../../../../ui/modules/modules.utils";
import {
  MIN_SELECTED_QUANTITY_FOR_MERCHANDISE,
  dateKey,
} from "./constants/AddCustomer.constants";
import styles from "./AddCustomer.module.css";
import useStyles from "./AddCustomer.styles";
import {
  required,
  validateEmail,
  validatePhoneNumber,
  validatePrice,
} from "./utils/AddCustomer.validations";
import InstalmentsField from "./modules/InstalmentsField/InstalmentsField";
import { ADD_CUSTOMER_INSTALMENT_FIELD_KEYS } from "./modules/InstalmentsField/modules/InstalmentField/InstalmentField.constants";
import PlanTypeSelector from "./modules/PlanTypeSelector/PlanTypeSelector";
import StateSelector from "./modules/StateSelector/StateSelector";
import { useInfiniteListingOptions } from "features/Listings/utils/useInfiniteListingOptions";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { useDispatch } from "react-redux";
import { EXLY_SCHEDULELIST__REQUEST_LIVE_OFFERINGS } from "ui/pages/schedule/redux/actions";
import { logError } from "utils/error";
import { FORMATTERS, getInitialValuesOnType } from "./utils/AddCustomer.utils";
import "react-phone-input-2/lib/style.css";
import { offering_availability_statuses } from "features/Listings/Listings.constants";

const AddCustomer = ({
  availibility_type,
  offeringVariations: offeringVariationsData,
  isPlan,
  showOfferingSelector = true,
  payoutDetails,
  entityDataProp,
  initialDataType,
  listingDataProp,
  listingTypeProp,
}) => {
  const { notify } = useNotifications();
  const dispatch = useDispatch();
  const { values } = useFormState();

  const [listingData, setListingData] = useState(listingDataProp);
  const [listing_type, set_listing_type] = useState(null);

  const classes = useStyles();
  const {
    offering_variant_uuid,
    currency,
    selected_quantity,
    selected_appointment_slots,
    appointment_slots,
    selected_price,
    available_currencies,
  } = values;
  const {
    [PART_PAYMENT_FORM_KEYS.payment_plan_type]: paymentPlanType,
    [PART_PAYMENT_FORM_KEYS.installment_data]: instalments,
  } = listingData ?? {};
  const isInstalmentsPlanTypeEnabled = isInstallmentsPlanType(paymentPlanType);
  const { [PAYOUT_DETAILS_KEYS.is_creator_gst_registered]: isGstEnabled } =
    payoutDetails ?? {};

  const form = useForm();
  const { values: formValues } = useFormState();
  const { getListingFromRedux } = useOfferings();
  const { plan_listings } = useFetchPlanListings(entityDataProp?.uuid);
  const live_plan_listing_options = plan_listings?.filter(
    (listing) => listing.status === offering_availability_statuses.live
  );
  const plan_listing_options =
    live_plan_listing_options?.map((listing, idx) => ({
      id: idx,
      title: listing?.title,
      value: listing?.plan_listing_map_uuid,
      ...getListingFromRedux(listing?.plan_listing_map_uuid),
    })) ?? [];

  const {
    options: listingOptions,
    loading: loadingListingOptions,
    lastElementRef: listingOptionsLastElementRef,
  } = useInfiniteListingOptions({
    formatOptions: FORMATTERS.listing_options,
  });

  const finalListingOptions = isPlan ? plan_listing_options : listingOptions;

  const { address_type: addressType, slots: listingSlots = [] } =
    listingData ?? {};

  const isWebinar = listing_type === constants.scheduleTypesId.webinar;
  const isMerchandise = listing_type === constants.scheduleTypesId.merchandise;
  // no schedule check
  const isNoSchedule =
    listing_type === constants.scheduleTypesId.no_schedule ||
    listing_type === constants.scheduleTypesId.whatsapp;
  const isCustomerLocationAddressType =
    addressType === constants.address_type_enum.customer_location;
  const showCustomerAddressInput =
    isMerchandise || isCustomerLocationAddressType;

  // reset data
  useEffect(() => {
    if (isPlan) {
      setListingData(undefined);
      set_listing_type(undefined);
    }
  }, []);

  useEffect(() => {
    !is_empty(listingDataProp)
      ? set_listing_type(listingDataProp.type)
      : set_listing_type(listingTypeProp);
  }, [listingDataProp]);

  const [offeringVariations, setOfferingVariations] = React.useState(
    offeringVariationsData
  );
  const [selectedPlanType, setSelectedPlanType] = React.useState(
    PAYMENT_PLAN_TYPE.ONE_TIME_PAYMENT
  );

  const isInstalmentsPlanTypeSelected =
    isInstallmentsPlanType(selectedPlanType);

  const priceFieldName = isInstalmentsPlanTypeSelected
    ? `${ADD_CUSTOMER_FORM_KEYS.installments}[0].${ADD_CUSTOMER_INSTALMENT_FIELD_KEYS.installment_price}` // 0 represent initial payment here as first instalment is considered as initial payment in instalments array
    : ADD_CUSTOMER_FORM_KEYS.selected_price;

  const handleChangePlanType = (value) => {
    setSelectedPlanType(value);
    form.change(ADD_CUSTOMER_FORM_KEYS.payment_type, value);
  };

  const handleSetListingData = (listingDataProp) => {
    setListingData(listingDataProp);
    const _initialValues = getInitialValuesOnType(initialDataType, {
      listing_data: listingDataProp,
      entity_data: entityDataProp,
    });

    // don't reset primary details
    [
      ADD_CUSTOMER_FORM_KEYS.full_name,
      ADD_CUSTOMER_FORM_KEYS.email,
      ADD_CUSTOMER_FORM_KEYS.customer_phone_number,
      ADD_CUSTOMER_FORM_KEYS.country_code,
      ADD_CUSTOMER_FORM_KEYS.country,
    ].forEach((key) => {
      _initialValues[key] = formValues[key];
    });

    form.reset(_initialValues);
  };

  const onChangeListing = async (selectedListingUuid) => {
    const [listing] = finalListingOptions.filter(
      (item) => item.uuid === selectedListingUuid
    );
    const data = await dataProvider.custom_request(
      `${api.get_listing_details}/${listing.uuid}`
    );
    const eventData = data?.data?.event;

    handleSetListingData(eventData);
    set_listing_type(listing?.type);

    if (isPlan) {
      if (currency === constants.usd)
        form.change(
          ADD_CUSTOMER_FORM_KEYS.selected_price,
          eventData.price_international
        );
      else
        form.change(
          ADD_CUSTOMER_FORM_KEYS.selected_price,
          eventData.price_per_head
        );
    }

    if (eventData.is_offering_variants_enabled) {
      const offeringVariationsData = await fetchOfferingVariations({
        offeringUuid: eventData.uuid,
      });

      setOfferingVariations(offeringVariationsData?.data?.offering_variants);
    }
  };

  const handleInitAppointmentSlots = () => {
    if (
      (listing_type === constants.scheduleTypesId.one_on_one_appointment ||
        listing_type === constants.scheduleTypesId.webinar) &&
      is_empty(appointment_slots)
    ) {
      let tempSlots = [...appointment_slots];

      tempSlots.push({
        date: "Select date",
        time_slots: [{ time: "Select time" }],
        index: 0,
      });

      // removing all expired date or time slots
      const availableSlots = getAvailableSlots({
        ...listingData,
        type: listing_type,
      });

      for (let slot of availableSlots) {
        let date = moment(slot.start_time)
          .tz(getUserTimezone())
          .format("DD/MM/YYYY");
        let time = moment(slot.start_time)
          .tz(getUserTimezone())
          .format(DEFAULT_TIME_FORMAT_12);
        let checkMatchingDateSlot = tempSlots.find(
          (item) => item.date === date
        );

        if (is_empty(checkMatchingDateSlot)) {
          // add new date option along with current time slot
          tempSlots.push({
            date: date,
            time_slots: [
              { time: "Select time", uuid: "" },
              {
                time: time,
                uuid: slot.uuid,
                expired:
                  moment(slot.start_time)
                    .tz(getUserTimezone())
                    .isBefore(moment(), "hours") || isWebinar
                    ? slot.spots_filled > webinarMaxSpot
                    : slot.spots_filled > 0,
              },
            ],
            index: tempSlots.length,
            expired: moment(slot.start_time)
              .tz(getUserTimezone())
              .isBefore(moment(), "days"),
          });
        } else if (tempSlots[checkMatchingDateSlot.index]) {
          // add the new time slot to the already added date option time slots
          tempSlots[checkMatchingDateSlot.index].time_slots.push({
            time: time,
            uuid: slot.uuid,
            expired:
              moment(slot.start_time)
                .tz(getUserTimezone())
                .isBefore(moment(), "hours") || isWebinar
                ? slot.spots_filled > webinarMaxSpot
                : slot.spots_filled > 0,
          });
        }
      }

      form.change("appointment_slots", tempSlots);
    }
  };

  useEffect(() => {
    handleInitAppointmentSlots();
  }, [appointment_slots, listing_type, listingSlots, form]);

  const appointmentDateOptions = useMemo(() => {
    return appointment_slots.map((item) => {
      const label =
        item.date === dateKey
          ? item.date
          : handleDateFormat(
              moment(item.date, "DD/MM/YYYY").format(
                constants.displayDateYearFormat
              ),
              false
            );
      return {
        ...item,
        id: item.index,
        label,
        value: item.index,
        disabled: item.expired,
      };
    });
  }, [appointment_slots]);

  const getAppointmentTimeOptions = useCallback(
    (slotIndex) => {
      return appointment_slots[
        selected_appointment_slots[slotIndex].date_index
      ].time_slots.map((item) => ({
        ...item,
        id: item.uuid,
        label: item.time,
        value: item.uuid,
        disabled: item.expired,
      }));
    },
    [appointment_slots, selected_appointment_slots]
  );

  const addAppointmentSlot = useCallback(() => {
    let _updatedSlots = [...selected_appointment_slots];
    _updatedSlots.push({
      date_index: 0,
      time: "",
      uuid: "",
      index: _updatedSlots.length,
    });
    form.change("selected_appointment_slots", _updatedSlots);
    form.change("selected_quantity", _updatedSlots.length);
  }, [selected_appointment_slots, form]);

  const deleteAppointmentSlot = useCallback(
    (slotIndex) => {
      const _updatedSlots = [];

      for (let i = 0; i < selected_appointment_slots.length; i++) {
        if (i === slotIndex) continue;
        _updatedSlots.push({
          date_index: selected_appointment_slots[i].date_index,
          time: selected_appointment_slots[i].time,
          uuid: selected_appointment_slots[i].uuid,
          index: _updatedSlots.length,
        });
      }

      const updated_appointment_slots = [...appointment_slots];
      let selected_time =
        updated_appointment_slots[
          selected_appointment_slots[slotIndex].date_index
        ].time_slots;
      let checkSelectedAppointment = selected_time.filter(
        (val) => val.uuid === selected_appointment_slots[slotIndex].uuid
      );
      if (selected_time[selected_time.indexOf(checkSelectedAppointment[0])])
        selected_time[
          selected_time.indexOf(checkSelectedAppointment[0])
        ].expired = false;

      form.change("appointment_slots", updated_appointment_slots);
      form.change("selected_appointment_slots", _updatedSlots);
      form.change("selected_quantity", _updatedSlots.length);
    },
    [selected_appointment_slots, form, appointment_slots]
  );

  const onChangeAppointmentTime = useCallback(
    (value, slot, prevValue) => {
      if (
        appointment_slots[selected_appointment_slots[slot.index]?.date_index]
          ?.time_slots
      ) {
        let temp1 = appointment_slots;
        let selected_time =
          temp1[selected_appointment_slots[slot.index].date_index].time_slots;
        let checkSelectedAppointment = selected_time.filter(
          (val) => val.uuid === value
        );
        let [checkSelectedAppointmentFirstElement] = checkSelectedAppointment;
        const oldCheckSelectedAppointment = selected_time.filter(
          (val) => val.uuid === prevValue
        );
        let [oldCheckSelectedAppointmentFirstElement] =
          oldCheckSelectedAppointment;

        if (checkSelectedAppointmentFirstElement.uuid)
          selected_time[
            selected_time.indexOf(checkSelectedAppointmentFirstElement)
          ].expired = true;
        if (
          selected_time[
            selected_time.indexOf(oldCheckSelectedAppointmentFirstElement)
          ]
        )
          selected_time[
            selected_time.indexOf(oldCheckSelectedAppointmentFirstElement)
          ].expired = false;

        form.change("appointment_slots", [...temp1]);
      }
    },
    [appointment_slots, form, selected_appointment_slots]
  );

  const isClass = useMemo(() => {
    return is_class({ type: listing_type });
  }, [listing_type]);

  const isOneOnOneClass =
    listing_type === constants.scheduleTypesId.one_on_one_class;

  const showBatchSelector = isClass || isOneOnOneClass;

  const batchOptions = useMemo(() => {
    if (!listingData?.classes) return [];
    return listingData.classes.map(({ timings, uuid }) => ({
      id: uuid,
      value: uuid,
      label: timings,
    }));
  }, [listingData]);

  const currencyOptions = useMemo(() => {
    return available_currencies.map((item) => ({
      id: item,
      value: item,
      label: `${item}(${constants.CURRENCY_SYMBOL_MAP[item]})`,
    }));
  }, [available_currencies]);

  const labelString = isPlan ? "Select a Offering" : "Select a Product";

  React.useEffect(() => {
    if (!is_empty(offering_variant_uuid)) {
      form.change(ADD_CUSTOMER_FORM_KEYS.selected_price, getListingPrice());
    }
  }, [offering_variant_uuid]);

  useEffect(() => {
    (async () => {
      try {
        dispatch({
          type: EXLY_SCHEDULELIST__REQUEST_LIVE_OFFERINGS,
          payload: { fetchAll: true },
        });
      } catch (err) {
        logError({
          error: err,
          occuredAt: "src/ui/modules/AddCustomerModal/AddCustomer.jsx",
          when: "fetching listings",
        });
        if (!is_empty(err.body) && !is_empty(err.body.message)) {
          notify(err.body.message, notification_color_keys.error);
        }
      }
    })();
  }, [listing_type]);

  const getListingPrice = (currencyValue = currency) => {
    let price =
      currencyValue === constants.usd
        ? listingData?.price_international
        : listingData?.price_per_head;

    let selectedVariation;
    if (!is_empty(offering_variant_uuid)) {
      selectedVariation = offeringVariations.find(
        (item) => item.uuid === offering_variant_uuid
      );
    }
    if (!is_empty(selectedVariation)) {
      // if domestice creator then select international price if usd is selected else select domestic price on ruppee selected
      price =
        currencyValue === constants.usd
          ? selectedVariation?.international_price
          : selectedVariation?.domestic_price;
    }
    return price;
  };

  const handlePhoneNumberChange = (_, countryData, e, formatedNum) => {
    const { dialCode, name: countryName } = countryData;
    let completeDialCode = "+" + dialCode;
    form.change(ADD_CUSTOMER_FORM_KEYS.customer_phone_number, formatedNum);
    form.change(ADD_CUSTOMER_FORM_KEYS.country_code, completeDialCode);
    form.change(ADD_CUSTOMER_FORM_KEYS.country, countryName);
  };

  return (
    <div className={classes.contentWrap}>
      {isPlan && is_empty(listingData) && (
        <div className={classnames(classes.mb, classes.gap_12, "d-flex")}>
          <div className={classes.imgWrap}>
            {!is_empty(entityDataProp?.cover_image) && (
              <img
                className={classes.listingImg}
                src={entityDataProp.cover_image}
                alt="cover_image"
              />
            )}
          </div>

          <div className={classes.listingDescription}>
            <div className={classnames(classes.formTitle, classes.mb_12)}>
              {entityDataProp?.title}{" "}
            </div>
            <div>
              Max Price - {constants.CURRENCY_SYMBOL_MAP[currency]}
              {!isInternationalTimezone() && currency === constants.usd
                ? entityDataProp?.max_price_international
                : entityDataProp?.max_price_per_head}
            </div>
            <div>
              Min Price - {constants.CURRENCY_SYMBOL_MAP[currency]}
              {!isInternationalTimezone() && currency === constants.usd
                ? entityDataProp?.min_price_international
                : entityDataProp?.min_price_per_head}
            </div>
          </div>
        </div>
      )}

      {!is_empty(listingData) && (
        <div className={classnames(classes.mb, classes.gap_12, "d-flex")}>
          <div className={classes.imgWrap}>
            {!is_empty(listingData?.cover_image) && (
              <img
                className={classes.listingImg}
                src={listingData.cover_image}
                alt="cover_image"
              />
            )}
          </div>

          <div className={classes.listingDescription}>
            <div className={classnames(classes.formTitle, classes.mb_12)}>
              {listingData?.title}{" "}
            </div>
            <div>
              Price per unit - {constants.CURRENCY_SYMBOL_MAP[currency]}
              {getListingPrice()} |{" "}
              <span>
                {" "}
                Total - {constants.CURRENCY_SYMBOL_MAP[currency]}{" "}
                {selected_price * selected_quantity || "0"}
              </span>
            </div>
          </div>
        </div>
      )}

      {isPlan || showOfferingSelector ? (
        <ExlyOfferingSelect
          value={listingData?.uuid}
          onChange={onChangeListing}
          label={`${labelString}*`}
          placeholder={labelString}
          mobileModalTitle={labelString}
          classes={{ wrapper: classes.mb }}
          emptyText={planConstants.NO_LIVE_OFFERING_PLAN_TEXT}
          options={finalListingOptions}
          lastElementRef={isPlan ? undefined : listingOptionsLastElementRef}
          loading={loadingListingOptions}
          hideEmptyCreateOfferingBtn
          showStatus
          fullWidth
        />
      ) : null}

      {isInstalmentsPlanTypeEnabled ? (
        <PlanTypeSelector
          isInstalmentsPlanTypeEnabled={isInstalmentsPlanTypeEnabled}
          value={selectedPlanType}
          onChange={handleChangePlanType}
        />
      ) : null}

      {(listing_type === constants.scheduleTypesId.one_on_one_appointment ||
        listing_type === constants.scheduleTypesId.webinar) &&
        availibility_type !== constants.avaibility_type.not_decided &&
        !is_empty(appointment_slots) && (
          <div className={classnames(classes.mb, classes.borderBottom)}>
            <div className={classnames(classes.formTitle, "mb-2")}>
              Select{" "}
              {listing_type === constants.scheduleTypesId.webinar
                ? "session"
                : "slots"}
            </div>
            {selected_appointment_slots?.map((slot, slotIndex) => (
              // eslint-disable-next-line react/jsx-key
              <div
                key={`${slot.uuid}-${slot.index}`}
                className={classnames(
                  "d-flex align-items-center mb-2",
                  classes.gap_24,
                  slotIndex < selected_appointment_slots.length - 1 &&
                    classes.mb
                )}
              >
                <Field
                  name={`selected_appointment_slots[${slot.index}].date_index`}
                  component={ExlyDropdown}
                  fullWidth
                  size="small"
                  options={appointmentDateOptions}
                  placeholder="Select date"
                />

                <Field
                  name={`selected_appointment_slots[${slot.index}].uuid`}
                  component={ExlyDropdown}
                  fullWidth
                  size="small"
                  options={getAppointmentTimeOptions(slot.index)}
                  placeholder="Select time"
                  onChange={(value) =>
                    onChangeAppointmentTime(
                      value,
                      slot,
                      selected_appointment_slots[slot.index].uuid
                    )
                  }
                />

                {listing_type !== constants.scheduleTypesId.webinar && (
                  <IconButton
                    onClick={() => deleteAppointmentSlot(slot.index)}
                    className={classes.p_0}
                  >
                    <RemoveCircleIcon className={classes.removeSvg} />
                  </IconButton>
                )}
              </div>
            ))}

            {listing_type !== constants.scheduleTypesId.webinar && (
              <div
                onClick={addAppointmentSlot}
                className={classnames(
                  classes.addApointmentBtn,
                  "d-flex align-items-center",
                  classes.formTitle
                )}
              >
                Add slot
                <AddCircleIcon className={classes.addSvg} />
              </div>
            )}
          </div>
        )}

      {showBatchSelector && (
        <Field
          name="selected_batch"
          component={ExlyDropdown}
          fullWidth
          size="small"
          options={batchOptions}
          placeholder="Select a batch"
          formControlClassName={classes.mb}
          dropdownLabel={{
            label: "Select a batch",
            size: "small",
            weight: "semi-bold",
          }}
        />
      )}

      {!is_empty(offeringVariations) && (
        <div className={`mb-3`}>
          <Field
            name={OFFERING_VARIANT_KEY}
            component={ExlyDropdown}
            validate={composeValidators(
              required,
              minLength(1, "Variation cannot be empty")
            )}
            fullWidth
            size="small"
            options={offeringVariations.map((item) => ({
              ...item,
              label: item.title,
              value: item.uuid,
            }))}
            placeholder="Select a variation"
            dropdownLabel={{
              label: "Select a variation",
              size: "small",
              weight: "semi-bold",
            }}
          />
        </div>
      )}

      {listing_type === constants.scheduleTypesId.merchandise && (
        <div className={classnames(classes.mb)}>
          <div className={classnames(classes.formTitle, "mb-2")}>Quantity</div>
          <div className={classnames("d-flex align-items-center")}>
            <IconButton
              onClick={() =>
                form.change("selected_quantity", selected_quantity - 1)
              }
              className={classnames(classes.p_0, "mr-2")}
              disabled={
                selected_quantity < MIN_SELECTED_QUANTITY_FOR_MERCHANDISE
              }
            >
              <RemoveCircleIcon className={classes.removeSvg} />
            </IconButton>

            <span className="ml-1">{selected_quantity}</span>

            <IconButton
              onClick={() =>
                form.change("selected_quantity", selected_quantity + 1)
              }
              className={classes.p_0}
            >
              <AddCircleIcon className={classes.addSvg} />
            </IconButton>
          </div>
        </div>
      )}

      <div className={classnames(classes.mb)}>
        <div className={classnames(classes.formTitle, "mb-2")}>Price</div>

        <Field
          name={priceFieldName}
          validate={validatePrice}
          size="small"
          component={ExlyInput}
          type="number"
          fullWidth
          placeholder="Enter Pricing"
          parse={finalFormParsers.number}
          currencyPrefix={{
            value: currency,
            onChange: (value) => {
              form.change(
                ADD_CUSTOMER_FORM_KEYS.selected_price,
                getListingPrice(value)
              );
              form.change("currency", value);
            },
            options: currencyOptions,
            type: "select",
            width: "100px",
          }}
        />
      </div>

      {isInstalmentsPlanTypeSelected ? (
        <InstalmentsField
          isInstalmentsPlanTypeSelected={isInstalmentsPlanTypeSelected}
          instalments={instalments}
        />
      ) : null}

      <Field
        name={ADD_CUSTOMER_FORM_KEYS.full_name}
        label="Customer Name *"
        validate={required}
        size="small"
        component={ExlyInput}
        fullWidth
        placeholder="Enter customer name"
        wrapperClassName={classes.mb}
        labelClassName="font_500"
      />

      <Field
        name={ADD_CUSTOMER_FORM_KEYS.email}
        label="Customer Email *"
        validate={validateEmail}
        size="small"
        component={ExlyInput}
        fullWidth
        placeholder="Enter customer email id"
        wrapperClassName={classes.mb}
        labelClassName="font_500"
      />

      <div className={classnames(classes.mb)}>
        <div className={classnames(classes.formTitle, "mb-2")}>
          Customer Phone number *
        </div>
        <Field
          component={ExlyPhoneInput}
          country={"in"}
          name="customer_phone_number"
          placeholder="Enter phone/whatsApp number"
          onChange={handlePhoneNumberChange}
          validate={validatePhoneNumber}
          copyNumbersOnly={false}
          inputProps={{
            name: "phone",
          }}
          source={"customer_phone_number"}
        />
      </div>

      {showCustomerAddressInput ? (
        <Field
          name={ADD_CUSTOMER_FORM_KEYS.address_details}
          label="Customer Address *"
          validate={required}
          size="small"
          component={ExlyInput}
          fullWidth
          placeholder="Enter customer address"
          wrapperClassName={classes.mb}
          labelClassName="font_500"
        />
      ) : null}

      {isGstEnabled ? (
        <div className={classnames(classes.mb)}>
          <StateSelector payoutDetails={payoutDetails} />
        </div>
      ) : null}

      <div className={classnames(classes.mb)}>
        <div className={classnames(classes.formTitle, "mb-2")}>
          Customer Timezone *
        </div>
        <Field
          component={ExlySelect}
          name={ADD_CUSTOMER_FORM_KEYS.customer_timezone}
          placeholder="Select timezone"
          validate={required}
          options={timezoneOptions}
          size="small"
          fullWidth
        />
      </div>

      {(isClass || isNoSchedule) && (
        <div className={classes.mb}>
          <div className={classnames(classes.formTitle, "mb-2")}>
            Booking Start Date
          </div>
          <Field
            name={ADD_CUSTOMER_FORM_KEYS.booking_start_date}
            component={ExlyDatePicker}
            fullWidth="100%"
            placeholder="Select Booking Start Date"
            format={(value) => (value ? moment(value).toDate() : null)}
            parse={(value) => moment(new Date(value)).format("YYYY-MM-DD")}
          />
        </div>
      )}

      <Label
        label="Send invoice to the customer for"
        marginBotton
        required={false}
      />

      <div className={classes.mb_12}>
        <Field
          id="send_comms_checkbox"
          name="send_comms"
          component={ExlyCheckbox}
          size="large"
          label="Booking"
          labelClassName={"font_500 ml-1"}
        />
      </div>

      {isInstalmentsPlanTypeSelected ? (
        <div className={classes.mb_12}>
          <Field
            id={`${ADD_CUSTOMER_FORM_KEYS.send_installment_comms}_checkbox`}
            name={ADD_CUSTOMER_FORM_KEYS.send_installment_comms}
            component={ExlyCheckbox}
            size="large"
            label="Instalment payment"
            labelClassName={"font_500 ml-1"}
          />
        </div>
      ) : null}

      <div className={styles.mb_large}>
        <hr />
      </div>

      <div className={styles.mb_large}>
        <AdvancedDetails payoutDetails={payoutDetails} />
      </div>
    </div>
  );
};

export default AddCustomer;
