import { useSlate } from 'slate-react';
import LinkButton from '../../../../../RichTextEditorV2/Elements/Link/LinkButton';
import { isBlockActive } from '../../../../../RichTextEditorV2/utils/SlateUtilityFunctions';
import { RTE_NODE_TYPES } from '../../../RTEEditable/constants/RTEEditable.constants';
import { useStyles } from './LinkTool.styles';

export const LinkTool = () => {
  const editor = useSlate();
  const classes = useStyles();

  return (
    <LinkButton
      className={classes.root}
      active={isBlockActive(editor, RTE_NODE_TYPES.LINK)}
      editor={editor}
    />
  );
};
