import { logError } from "utils/error";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "../../Storage/modules/SessionStorage/utils/SessionStorage.utils";
import { SESSION_STORAGE_KEYS } from "../../Storage/modules/SessionStorage/constants/SessionStorage.constant";

export const isChunkMissingError = async (errorMsg = "") => {
  if (errorMsg.toLowerCase().includes("chunk")) {
    const checkDeployment = await hasDeploymentOccured();
    // eslint-disable-next-line no-console
    console.log({ checkDeployment }); // to be removed after 31/01/25
    return checkDeployment;
  } else return false;
};

/**
 * Function to check the deployment timestamp and reload the app if a newer deployment is detected.
 *
 * why: when a creator has an already opened tab,and deploy something on creator tool production
 * and the creator tries to do some action in the same tab post deployment, without refreshing the tab getting chunk failed error
 *
 * This function fetches the latest deployment timestamp from a publicly accessible file,
 * compares it with a timestamp stored in session storage, and reloads the application if the
 * fetched timestamp is more recent than the stored timestamp. This ensures that users always
 * have the latest version of the application without requiring manual refreshes.
 */
export const hasDeploymentOccured = async () => {
  try {
    // Fetch the version from the public URL
    const response = await fetch(
      // using a dummy timestamp to avoid serving cached value
      `/latest_deployment_date.txt?ts=${Date.now()}`,
      { cache: "no-store" }
    ); // Adding cache: 'no-store' to ensure we always get the latest version
    if (!response.ok) {
      return false;
    }
    const timestampText = await response.text();
    const latestTimestamp = parseInt(timestampText.trim(), 10); // Parse the timestamp as an integer
    if (isNaN(latestTimestamp)) {
      return false;
    }
    // Get the stored timestamp from session storage
    const storedTimestamp = getSessionStorageItem(
      SESSION_STORAGE_KEYS.latest_deployment_timestamp
    );
    // eslint-disable-next-line no-console
    console.log({ latestTimestamp, storedTimestamp }); // to be removed after 31/01/25
    if (!storedTimestamp) {
      // if not timestamp is set the set & reload on chunk error
      setSessionStorageItem(
        SESSION_STORAGE_KEYS.latest_deployment_timestamp,
        latestTimestamp
      );
      return true;
    }

    const sessionTimestamp = parseInt(storedTimestamp, 10);
    if (isNaN(sessionTimestamp)) {
      return false;
    }

    // Compare timestamps and reload if necessary
    if (latestTimestamp > sessionTimestamp) {
      setSessionStorageItem(
        SESSION_STORAGE_KEYS.latest_deployment_timestamp,
        latestTimestamp
      );
      return true;
    } else {
      return false;
    }
  } catch (error) {
    logError({
      error,
      occuredAt:
        "src/features/Common/modules/Deployment/utils/Deployment.utils.js",
      when: "on checkDeploymentTimestampAndReload",
    });
    return false;
  }
};
