import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSidebarVisibility } from "react-admin";
import { InteractiveTooltip } from "@my-scoot/component-library-legacy";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import classnames from "classnames";

import whatsNextStyles from "../../widgets/whatsNextCard/Style.module.css";
import useStyles from "./Menu.styles";

import { ReactComponent as ManageWebpageIcon } from "assets/vectors/sidebar/webpage.svg";
import { ReactComponent as StarIcon } from "assets/vectors/sidebar/star.svg";
import { ReactComponent as ExlyPlusIcon } from "assets/vectors/sidebar/exly_plus.svg";
import { ReactComponent as CustomersIcon } from "assets/vectors/sidebar/customers.svg";
import { ReactComponent as CopyIcon } from "assets/vectors/filled_copy.svg";
import { ReactComponent as DashboardIcon } from "assets/vectors/sidebar/dashboard.svg";
import { ReactComponent as ListingsIcon } from "assets/vectors/sidebar/listings.svg";
import { ReactComponent as SalesIcon } from "assets/vectors/sidebar/sales.svg";
import { ReactComponent as TransactionIcon } from "assets/vectors/sidebar/transactions.svg";
import { ReactComponent as DoubleArrowIcon } from "assets/vectors/sidebar/doubleArrow.svg";
import { ReactComponent as IntegrationsIcon } from "assets/vectors/sidebar/integrations.svg";
import { ReactComponent as TalkToExpertIcon } from "assets/vectors/sidebar/talk_to_expert.svg";
import { ReactComponent as BoltIcon } from "assets/vectors/crm/bolt.svg";

import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import { is_empty } from "../../../utils/validations";
import ParentMenu from "./components/ParentMenu/ParentMenu";
import dataProvider from "../../../data/dataProvider";
import api from "../../../data/APIs";
import { getWhatsNextInfo } from "features/Onboarding/modules/WhatsNext/utils/whatsNext.utils";
import { orgPermissions, RESOURCE_KEYS } from "../../../utils/OrgPermissions";
import {
  getPermissions,
  getUserChecks,
  setNextStepsInLocalStorage,
  needToRefetchUserAuthDetails,
  fetchAndUpdateAuth,
} from "../../../utils/AuthUtil";
import { isRequestSuccessful } from "../../../utils/Utils";
import { getCreatorHostsiteURL } from "features/Account/utils/getCreatorPageDetails";
import { CustomMenuItemLink } from "features/UILayout/modules/Menu/modules/CustomMenuItemLink/CustomMenuItemLink";
import { useNotifications, useToggleState } from "utils/hooks";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import {
  app_pathnames,
  app_route_ids,
  app_route_keys,
  app_route_titles,
} from "constants/urlPaths";
import { isDeviceIOS } from "common/util";
import SessionStorageConstants from "constants/SessionStorage.constants";
import { comms } from "ui/pages/schedule/MangageCommunication/mangageCommunication.constants";
import { EXLY_CONNECT_TABS } from "features/ExlyConnect/ExlyConnect.constants";
import { apiMethods } from "data/api.constants";
import { WHATS_NEXT_APIS } from "features/Onboarding/modules/WhatsNext/WhatsNext.api";
import {
  LISTINGS_ROUTE_IDS,
  LISTINGS_ROUTE_KEYS,
} from "features/Listings/constants/Listings.urlPaths";
import { SESSION_STORAGE_KEYS } from "features/Common/modules/Storage/modules/SessionStorage/constants/SessionStorage.constant";
import styles from "features/UILayout/modules/Menu/MenuStyles.module.css";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import {
  DESKTOP_SEQUENCE_KEY,
  MENU_ITEM_IDS,
  MENU_ITEM_VALUES_CONFIG,
  MENU_SCROLLABLE_DIV_ID,
  MOBILE_SEQUENCE_KEY,
  SUB_MENU_CONFIG,
  TOOGLE_PARENT_MENU_KEYS,
} from "features/UILayout/modules/Menu/constants/Menu.constants";
import {
  findParentMenuKey,
  getSortedTooltipIDs,
  getVisibleMenuItemsConfig,
  returnEdgeElementsFromParentMenu,
} from "features/UILayout/modules/Menu/utils/Menu.utils";
import {
  UI_LAYOUT__DECREMENT_ACTIVE_TOOL_TIP_INDEX,
  UI_LAYOUT__INCREMENT_ACTIVE_TOOL_TIP_INDEX,
  UI_LAYOUT__SET_FILTERED_VISIBILITY_CONFIG,
} from "features/UILayout/redux/UILayout.actions";
import { whats_next_keys } from "features/Onboarding/modules/WhatsNext/WhatsNext.constants";
import { isInitialChecklistCompleted } from "features/Onboarding/modules/WhatsNext/modules/SetUpCheckList/utils/SetUpCheckList.utils";
import { useWhatsNextLocalStorageChange } from "features/Onboarding/modules/WhatsNext/utils/useWhatsNextLocalStorageChange";
import { OnboardingTooltipFooter } from "features/UILayout/modules/Menu/modules/OnboardingTooltipFooter/OnboardingTooltipFooter";
import { ShowTourSuggestionBackdrop } from "features/Onboarding/modules/WhatsNext/modules/ShowTourSuggestionBackdrop/ShowTourSuggestionBackdrop";
import { useOnboardingTooltip } from "features/UILayout/modules/Menu/modules/OnboardingTooltip/utils/useOnboardingTooltip";
import { EXLY_SUPPORT_CONSTANTS } from "../../../constants/exlySupport.constants";

const initialState = {
  menuManageSchedule: false,
  menuhandleToggleParentu: false,
  menuSchedule: false,
  menuMail: false,
  paymentLinks: false,
  myLeads: false,
  marketing: false,
  sales: false,
  menuCustomers: false,
  menuExlyPlus: false,
  menuWorkflowTools: false,
};

const whatsNextUrl = `${window.location.origin}/#/whats-next`;

const getUniqueMenuItemLinkProps = ({ routeKey, pathNameFunctionParams }) => {
  const pathNameFunction = app_pathnames[routeKey] || (() => {});
  return {
    to: pathNameFunction(pathNameFunctionParams),
    id: app_route_ids[routeKey],
    primaryText: app_route_titles[routeKey],
  };
};

const Menu = ({ onMenuClick = () => {}, dense = false }) => {
  const [state, setState] = useState(initialState);

  const [isLoading, startLoading, stopLoading] = useToggleState(false);
  const [premium_data, set_premium_data] = React.useState(null);
  const [liveFeatures, setLiveFeatures] = React.useState({});
  const [taskCompletedPercentage, setTaskCompletedPercentage] =
    React.useState(0);
  const [permissions, set_permissions] = React.useState(null);
  const { notify } = useNotifications();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const { isToolTipTourActive, activeTooltipIndex } = useSelector(
    (state) => state.uiLayout
  );

  const dispatch = useDispatch();
  useSelector((state) => state.theme); // force rerender on theme change
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isDesktop = useDesktopMediaQuery();

  const isMainUser = orgPermissions.isMainUser();
  const canAccessOrg = isMainUser || orgPermissions.isOwner();
  const hiddenOfferings =
    permissions?.[RESOURCE_KEYS.SECTIONS.CREATE_EDIT_LISTINGS] &&
    is_empty(permissions[RESOURCE_KEYS.SECTIONS.CREATE_EDIT_LISTINGS]);

  const hasExlyConnectAccessPermission = !(
    permissions?.[RESOURCE_KEYS.SECTIONS.EXLY_CONNECT] &&
    is_empty(permissions[RESOURCE_KEYS.SECTIONS.EXLY_CONNECT])
  );

  const showExlyConnect =
    !hiddenOfferings &&
    liveFeatures?.is_exlycon_active &&
    hasExlyConnectAccessPermission;

  const showTaskPercentContainer =
    open && isMainUser && orgPermissions.canCreate();

  const classes = useStyles({
    isXSmall,
    isDesktop,
    open,
    showTaskPercentContainer,
  });

  const setTaskCompletedPercentageValue = (value = 0) => {
    setTaskCompletedPercentage(Math.round(value));
  };

  // current this update is triggered from features/Onboarding/modules/WhatsNext/utils/whatsNext.utils.js
  const nextSteps = useWhatsNextLocalStorageChange();

  const { endOnboardingTooltipTour } = useOnboardingTooltip();

  useEffect(() => {
    setTaskCompletedPercentageValue(nextSteps?.completion_percentage);
  }, [nextSteps]);

  useEffect(() => {
    if (!open) setState(initialState);
  }, [open]);

  useEffect(() => {
    (async () => {
      try {
        const steps = await dataProvider.custom_request(
          WHATS_NEXT_APIS.GET_PROGRESS,
          apiMethods.GET,
          { request_params: JSON.stringify([]) }
        );
        if (steps && isRequestSuccessful(steps.status)) {
          /**
           * In case of any user auth details are edited from different session we will need to check
           * if the last updated at present in local storage match with the one came by progress call
           * if not refetch the userAuth data to make sure latest data is present in auth local storage.
           */
          if (
            needToRefetchUserAuthDetails(
              steps.data[whats_next_keys.last_profile_update.key]
            )
          )
            fetchAndUpdateAuth();

          setNextStepsInLocalStorage({ data: steps.data });
          setTaskCompletedPercentageValue(steps.data?.completion_percentage);
          set_premium_data(steps);
          window.sessionStorage.setItem(
            SESSION_STORAGE_KEYS.HAS_LISTING,
            steps.has_listing
          );
        }

        const check_live_profileFeature = await getUserChecks();
        if (!is_empty(check_live_profileFeature)) {
          setLiveFeatures(check_live_profileFeature);
        }
      } catch (err) {
        console.error("menu [] err", err);
        /**
         * there are 2 apis being called in this try catch
         * TODO: after understanding the errors of these 2 apis
         * 1. mention against the condition for which api is that error handling condition valid for
         * 2. explicitly mention if the conditions left out arer intentional
         */
        if (!is_empty(err?.body?.message)) {
          notify(err.body.message, "error");
        }
      }
    })();
  }, []);

  useEffect(() => {
    startLoading();
    getPermissions({ forceCall: true })
      .then((menuPermissions) => {
        if (menuPermissions) {
          const permissions = { ...menuPermissions };
          set_permissions(permissions.hidden_items);
        }
      })
      .finally(stopLoading);
  }, []);

  const handleToggle = (menu) => {
    if (window.sessionStorage.getItem("fromRoute")) {
      window.sessionStorage.removeItem("fromRoute");
      window.sessionStorage.removeItem("edit");
    }
    const data = state;
    Object.keys(data).forEach((item) => {
      if (item !== menu) {
        data[item] = false;
      }
    });
    setState(data);
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  const handleMenuClick = () => {
    if (window.sessionStorage.getItem(SessionStorageConstants.FROM_ROUTE)) {
      window.sessionStorage.removeItem(SessionStorageConstants.FROM_ROUTE);
      window.sessionStorage.removeItem(SessionStorageConstants.EDIT);
    }

    // when navigating through menu, clear the entity data for offerings
    if (window.sessionStorage.getItem(SessionStorageConstants.ENTITY_DATA)) {
      window.sessionStorage.removeItem(SessionStorageConstants.ENTITY_DATA);
      window.sessionStorage.removeItem(SessionStorageConstants.PLAN_ID);
    }

    // when navigating through menu, clear the listing comm data
    if (window.sessionStorage.getItem(comms.STORAGE_KEY)) {
      window.sessionStorage.removeItem(comms.STORAGE_KEY);
    }

    onMenuClick();
  };
  const userData = JSON.parse(localStorage.getItem("auth"));
  let display_image,
    sub_domain = null;
  const is_staff = !is_empty(localStorage.getItem("bdEmail"));
  if (userData) {
    ({ display_image, sub_domain } = userData);
  }

  const has_permission = (section) => {
    if (is_empty(permissions)) return true;
    let result = true;
    for (const key in permissions) {
      if (
        section === "customer_tools" &&
        permissions[RESOURCE_KEYS.SECTIONS.MY_BOOKINGS] &&
        permissions["my_leads"] &&
        is_empty(permissions["my_leads"]) &&
        permissions["all_customers"]
      ) {
        result = false;
        break;
      }

      if (
        section === "workflow_tools" &&
        permissions["attendance_tracking"] &&
        permissions[RESOURCE_KEYS.SECTIONS.MY_SCHEDULE] &&
        permissions["certificates"] &&
        !isMainUser &&
        permissions["feedbacks"] &&
        !canAccessOrg &&
        (permissions[RESOURCE_KEYS.SECTIONS.REPORTS] ||
          !liveFeatures.is_premium) &&
        permissions["automated_message"] &&
        is_empty(permissions["automated_message"]) &&
        (permissions["blogs"] || !liveFeatures?.is_blogs_active) &&
        (permissions[RESOURCE_KEYS.SECTIONS.MEDIA_LIBRARY] ||
          !liveFeatures?.enable_media_library) &&
        !showExlyConnect &&
        (permissions[RESOURCE_KEYS.SECTIONS.ZOOM_ATTENDANCE] ||
          !liveFeatures?.is_zoom_attendance_enabled)
      ) {
        result = false;
        break;
      }

      if (
        section === "sales" &&
        permissions["payment_links"] &&
        is_empty(permissions["payment_links"]) &&
        permissions["discount_codes"] &&
        permissions["limited_time_offer"] &&
        permissions["custom_mails"] &&
        permissions["integrations"] &&
        permissions["traffic_analysis"] &&
        permissions[RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS] &&
        permissions[RESOURCE_KEYS.SECTIONS.MEDIA_CREATIVES] &&
        permissions[RESOURCE_KEYS.SECTIONS.SEO] &&
        (!liveFeatures?.is_ads_enabled ||
          isDeviceIOS() ||
          !orgPermissions.isMainUser())
      ) {
        result = false;
        break;
      }
      if (
        section === "managing_money" &&
        permissions["manage_handleToggleParentu"] &&
        is_empty(permissions["manage_handleToggleParentu"])
      ) {
        result = false;
        break;
      }
      if (section === "org_tools" && permissions["org_logs"]) {
        result = false;
        break;
      }

      if (
        (key === section && is_empty(permissions[key])) ||
        (!is_empty(permissions[key]) && permissions[key]?.includes(section))
      ) {
        result = false;
        break;
      } else if (
        section === "webpage_details" &&
        key === "manage_webpage" &&
        is_empty(permissions["manage_webpage"])
      ) {
        result = false;
        break;
      } else if (
        section === "webpage_leads" &&
        key === "my_leads" &&
        is_empty(permissions["my_leads"])
      ) {
        result = false;
        break;
      }
    }
    return result;
  };

  const tutorialFlow = sessionStorage.getItem("flow");
  const share = getCreatorHostsiteURL();

  const showReferrals = useMemo(
    () =>
      liveFeatures?.is_creator_referral_active && // boolean to show referrals section or not
      has_permission(RESOURCE_KEYS.SUBSECTIONS.EXLY_PLUS.REFER_A_FRIEND),
    [liveFeatures, has_permission]
  );
  const showUserReferrals =
    liveFeatures?.user_referral_enabled === true && // boolean to show user referrals section or not
    has_permission(RESOURCE_KEYS.SUBSECTIONS.EXLY_PLUS.USER_REFERRALS);
  const showExlyPlusSection =
    !isLoading &&
    has_permission(RESOURCE_KEYS.SECTIONS.EXLY_PLUS) &&
    (showReferrals || showUserReferrals);

  const commonMenuItemLinkProps = {
    onClick: handleMenuClick,
    sidebarIsOpen: open,
    dense,
    disableRipple: true,
    classes: { root: classes.innerMenuLink },
    activeClassName: classes.activeMenuItem,
  };

  const commonCustomMenuItemLinkProps = {
    onClick: () => {
      handleMenuClick();
      setState(initialState);
    },
    sidebarIsOpen: open,
    dense,
    disableRipple: true,
    classes: { root: classes.menuLink },
    activeClassName: classes.activeMenuLink,
  };

  const getReadyToLaunchText = useMemo(() => {
    return `${
      isInitialChecklistCompleted() ? "Quick links" : "Get ready to launch"
    } (${taskCompletedPercentage}%)`;
  }, [taskCompletedPercentage]);

  const showReports =
    liveFeatures.is_premium && has_permission(RESOURCE_KEYS.SECTIONS.REPORTS);

  const VISIBLE_MENU_ITEMS_CONFIG = useMemo(() => {
    return getVisibleMenuItemsConfig({
      isDesktop,
      hasPermission: has_permission,
      liveFeatures,
      showExlyConnect,
      showReferrals,
      showReports,
      showUserReferrals,
      canAccessOrg,
      isMainUser,
    });
  }, [
    isDesktop,
    permissions,
    canAccessOrg,
    isMainUser,
    liveFeatures,
    showExlyConnect,
    showReferrals,
    showReports,
    canAccessOrg,
  ]);

  const config = useMemo(() => {
    const masterOrderedConfig = getSortedTooltipIDs({
      key: isDesktop ? DESKTOP_SEQUENCE_KEY : MOBILE_SEQUENCE_KEY,
    });
    return masterOrderedConfig.filter(
      (item) => VISIBLE_MENU_ITEMS_CONFIG[item]
    );
  }, [isDesktop, VISIBLE_MENU_ITEMS_CONFIG]);

  useEffect(() => {
    dispatch({
      type: UI_LAYOUT__SET_FILTERED_VISIBILITY_CONFIG,
      payload: config,
    });
  }, [config]);

  const onNext = () => {
    if (activeTooltipIndex === config?.length - 1) {
      endOnboardingTooltipTour();
    } else {
      dispatch({ type: UI_LAYOUT__INCREMENT_ACTIVE_TOOL_TIP_INDEX });
    }
  };

  const onPrev = () => {
    dispatch({ type: UI_LAYOUT__DECREMENT_ACTIVE_TOOL_TIP_INDEX });
    handleToggle("menuExlyPlus");
  };

  const closeMenu = () => dispatch(setSidebarVisibility(false));

  const moveToAnotherMenuItem = ({ forward = false }) => {
    const currentActive = forward
      ? config[activeTooltipIndex + 1]
      : config[activeTooltipIndex - 1];
    const foundParentMenu = findParentMenuKey(currentActive);
    setState({
      ...initialState,
      ...(foundParentMenu && { [foundParentMenu]: true }),
    });
  };

  // JUST MOVED THIS BELOW IN ONBOARDING FEATURE / NO CHANGES DONE
  const MANAGE_WEBPAGE =
    has_permission("webpage_tools") &&
    (() => {
      return (
        <div id="manage_webpage">
          {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.MANAGE_WEBSITE] && (
            <CustomMenuItemLink
              id={MENU_ITEM_IDS.MANAGE_WEBSITE}
              to={`/TemplatePreview`}
              handleToggleParent={({ forward = false }) => {
                moveToAnotherMenuItem({ forward });
              }}
              primaryText={`Create / Edit Webpage`}
              leftIcon={<ManageWebpageIcon />}
              {...commonCustomMenuItemLinkProps}
              classes={{
                root: classnames(classes.menuLink, classes.soloMenuItem),
              }}
            />
          )}
        </div>
      );
    })();

  // JUST MOVED THIS BELOW IN ONBOARDING FEATURE / NO CHANGES DONE
  const CUSTOMERS =
    has_permission("customer_tools") &&
    (() => {
      // Extract first and last visible menu items
      const { firstVisibleItem: firstContactsItem } =
        returnEdgeElementsFromParentMenu({
          parentMenuItemsArr: SUB_MENU_CONFIG[TOOGLE_PARENT_MENU_KEYS.CONTACTS],
          visibleItems: VISIBLE_MENU_ITEMS_CONFIG,
        });

      const toggleCustomersMenu = () =>
        handleToggle(TOOGLE_PARENT_MENU_KEYS.CONTACTS);

      return (
        <div id="Customer_Tools">
          <ParentMenu
            handleToggle={toggleCustomersMenu}
            isOpen={state.menuCustomers}
            sidebarIsOpen={open}
            name="Contacts"
            icon={<CustomersIcon />}
            dense={dense}
            initialState={{ my_bookings: false, my_leads: false }}
            {...(isToolTipTourActive &&
              config[activeTooltipIndex] === firstContactsItem && {
                menuItemClassName: styles.tooltipContainer,
              })}
          >
            {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.BOOKINGS] && (
              <CustomMenuItemLink
                to={`/my-bookings`}
                id={MENU_ITEM_IDS.BOOKINGS}
                primaryText={`My Bookings`}
                handleToggleParent={moveToAnotherMenuItem}
                {...commonMenuItemLinkProps}
              />
            )}
            {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.LEADS] && (
              <CustomMenuItemLink
                id={MENU_ITEM_IDS.LEADS}
                to={`/host/v2/interest/list`}
                primaryText={`Webpage Leads`}
                handleToggleParent={moveToAnotherMenuItem}
                {...commonMenuItemLinkProps}
              />
            )}
            {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.CUSTOMERS] && (
              <CustomMenuItemLink
                to={`/payments/exly/customers/list/v2`}
                primaryText={`All Customers`}
                id={MENU_ITEM_IDS.CUSTOMERS}
                handleToggleParent={moveToAnotherMenuItem}
                {...commonMenuItemLinkProps}
              />
            )}
            {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.SEGMENTS] && (
              <CustomMenuItemLink
                to={`/host/segments/list/customers`}
                id={MENU_ITEM_IDS.SEGMENTS}
                primaryText={`Segments`}
                handleToggleParent={moveToAnotherMenuItem}
                {...commonMenuItemLinkProps}
              />
            )}
          </ParentMenu>
        </div>
      );
    })();

  const WORKFLOW_TOOLS =
    has_permission("workflow_tools") &&
    // Extract first and last visible menu items
    (() => {
      const { firstVisibleItem } = returnEdgeElementsFromParentMenu({
        parentMenuItemsArr:
          SUB_MENU_CONFIG[TOOGLE_PARENT_MENU_KEYS.WORKFLOW_TOOLS],
        visibleItems: VISIBLE_MENU_ITEMS_CONFIG,
      });

      return (
        <div id="Workflow_Tools">
          <ParentMenu
            handleToggle={() => handleToggle("menuWorkflowTools")}
            isOpen={state.menuWorkflowTools}
            sidebarIsOpen={open}
            name="Workflow tools"
            icon={<StarIcon />}
            dense={dense}
            {...(isToolTipTourActive &&
              config[activeTooltipIndex] === firstVisibleItem && {
                menuItemClassName: styles.tooltipContainer,
              })}
          >
            {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.ZOOM_ATTENDANCE] && (
              <CustomMenuItemLink
                {...getUniqueMenuItemLinkProps({
                  routeKey: app_route_keys.zoom_attendance,
                })}
                menuId={MENU_ITEM_IDS.ZOOM_ATTENDANCE}
                handleToggleParent={moveToAnotherMenuItem}
                {...commonMenuItemLinkProps}
              />
            )}
            {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.MY_SCHEDULE] && (
              <CustomMenuItemLink
                to={`/myschedule`}
                id={MENU_ITEM_IDS.MY_SCHEDULE}
                primaryText={`My Schedule`}
                handleToggleParent={moveToAnotherMenuItem}
                {...commonMenuItemLinkProps}
              />
            )}
            {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.ATTENDANCE_REGISTER] && (
              <CustomMenuItemLink
                to={`/attendance-tracking`}
                primaryText={`Attendance Register`}
                id={MENU_ITEM_IDS.ATTENDANCE_REGISTER}
                handleToggleParent={moveToAnotherMenuItem}
                {...commonMenuItemLinkProps}
              />
            )}
            {(VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.CERTIFICATES] ||
              isMainUser) && (
              <CustomMenuItemLink
                to={`/create-certificate`}
                id={MENU_ITEM_IDS.CERTIFICATES}
                primaryText={`Certificates`}
                handleToggleParent={moveToAnotherMenuItem}
                {...commonMenuItemLinkProps}
              />
            )}
            {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.FEEDBACKS] && (
              <CustomMenuItemLink
                to={`/${api.feedbacks.fetch_feedbacks_list}`}
                id={MENU_ITEM_IDS.FEEDBACKS}
                primaryText={`Feedback`}
                handleToggleParent={moveToAnotherMenuItem}
                {...commonMenuItemLinkProps}
              />
            )}
            {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.EXLY_CONNECT] ? (
              <CustomMenuItemLink
                to={`/${app_pathnames[app_route_keys.exly_connect](
                  EXLY_CONNECT_TABS.scheduled.value
                )}`}
                id={MENU_ITEM_IDS.EXLY_CONNECT}
                primaryText="Exly Connect"
                onClick={handleMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                disableRipple={true}
                classes={{ root: classes.innerMenuLink }}
                activeClassName={classes.activeMenuItem}
                handleToggleParent={moveToAnotherMenuItem}
                {...commonMenuItemLinkProps}
              />
            ) : null}
            {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.MANAGE_STAFF] && (
              <CustomMenuItemLink
                to={`/${api.get_organisation_members}`}
                primaryText={`Add/Manage Staff`}
                id={MENU_ITEM_IDS.MANAGE_STAFF}
                handleToggleParent={moveToAnotherMenuItem}
                {...commonMenuItemLinkProps}
              />
            )}
            {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.REPORTS] && (
              <CustomMenuItemLink
                to={`/Reports`}
                primaryText={`Reports`}
                id={MENU_ITEM_IDS.REPORTS}
                handleToggleParent={moveToAnotherMenuItem}
                {...commonMenuItemLinkProps}
              />
            )}
            {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.AUTOMATED_MESSAGES] && (
              <CustomMenuItemLink
                to={`/notifications/automated-messages`}
                primaryText={`Automated Messages`}
                id={MENU_ITEM_IDS.AUTOMATED_MESSAGES}
                handleToggleParent={moveToAnotherMenuItem}
                {...commonMenuItemLinkProps}
              />
            )}
            {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.BLOG] && (
              <CustomMenuItemLink
                to={`/Blogs`}
                primaryText={`Blog`}
                id={MENU_ITEM_IDS.BLOG}
                handleToggleParent={moveToAnotherMenuItem}
                {...commonMenuItemLinkProps}
              />
            )}
            {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.MEDIA_LIBRARY] && (
              <CustomMenuItemLink
                to={`/media-library`}
                primaryText={`Media Library`}
                id={MENU_ITEM_IDS.MEDIA_LIBRARY}
                handleToggleParent={moveToAnotherMenuItem}
                {...commonMenuItemLinkProps}
              />
            )}
          </ParentMenu>
        </div>
      );
    })();

  const EXLY_PLUS =
    showExlyPlusSection &&
    (() => {
      // Extract first and last visible menu items
      const { firstVisibleItem: firstExlyItem } =
        returnEdgeElementsFromParentMenu({
          parentMenuItemsArr:
            SUB_MENU_CONFIG[TOOGLE_PARENT_MENU_KEYS.EXLY_PLUS],
          visibleItems: VISIBLE_MENU_ITEMS_CONFIG,
        });

      return (
        <div id="Exly_Plus">
          <ParentMenu
            handleToggle={() => handleToggle("menuExlyPlus")}
            isOpen={state.menuExlyPlus}
            sidebarIsOpen={open}
            name="Exly Plus"
            icon={<ExlyPlusIcon />}
            dense={dense}
            {...(isToolTipTourActive &&
              config[activeTooltipIndex] === firstExlyItem && {
                menuItemClassName: styles.tooltipContainer,
              })}
          >
            {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.REFER_A_FRND] && (
              <CustomMenuItemLink
                to={`/Referrals`}
                primaryText={`Refer a Friend`}
                id={MENU_ITEM_IDS.REFER_A_FRND}
                handleToggleParent={moveToAnotherMenuItem}
                {...commonMenuItemLinkProps}
              />
            )}
            {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.USER_REFERALS] && (
              <CustomMenuItemLink
                to={`/payments/exly/user/referrals/list`}
                primaryText={`User Referrals`}
                id={MENU_ITEM_IDS.USER_REFERALS}
                handleToggleParent={moveToAnotherMenuItem}
                {...commonMenuItemLinkProps}
              />
            )}
          </ParentMenu>
        </div>
      );
    })();
  if (is_empty(premium_data)) {
    return <></>;
  }

  return (
    <Box
      mt={1}
      className={`${classes.sidebarIsFixed} ${
        tutorialFlow ? "disableLeftClick" : ""
      } ${isToolTipTourActive && styles.pointerNone}`}
    >
      <ShowTourSuggestionBackdrop
        backdropClass={styles.overrideMenuBackdropStyle}
      />
      {isToolTipTourActive && <div className={"backdrop"}></div>}
      {isDesktop ? null : (
        <div className={classes.headerContainer}>
          <div>Exly</div>
          <IconButton onClick={closeMenu} className={classes.headerCloseBtn}>
            <CloseRoundedIcon />
          </IconButton>
        </div>
      )}

      {showTaskPercentContainer && (
        <InteractiveTooltip
          onCloseClick={endOnboardingTooltipTour}
          open={
            isToolTipTourActive &&
            config[activeTooltipIndex] === MENU_ITEM_IDS.GETTING_STARTED
          }
          title={
            MENU_ITEM_VALUES_CONFIG[config[activeTooltipIndex]]?.tooltipTitle
          }
          customFooter={
            <OnboardingTooltipFooter
              onNext={onNext}
              onPrev={onPrev}
              primaryBtnText={
                activeTooltipIndex === config?.length - 1 ? "Exit" : "Next"
              }
              hideNextBtn={activeTooltipIndex === config?.length - 1}
            />
          }
          showPrimaryBtn={false}
          totalSteps={0}
          enterDelay={0}
          leaveDelay={0}
          placement={isDesktop ? "right-start" : "bottom"}
          tooltipClasses={{
            popper: isToolTipTourActive
              ? styles.interactiveToolTipPopper
              : styles.unsetInteractiveToolTipPopper,
          }}
        >
          <div
            className={classnames(
              classes.taskPercentContainer,
              config[activeTooltipIndex] === MENU_ITEM_IDS.GETTING_STARTED &&
                isToolTipTourActive &&
                styles.gettingStartedTooltioContainer
            )}
          >
            <div
              {...(config[activeTooltipIndex] ===
                MENU_ITEM_IDS.GETTING_STARTED && {
                className: isToolTipTourActive && styles.tooltipContainer,
              })}
              onClick={() => {
                window.location.href = `${window.location.origin}/#/whats-next`;
                !isDesktop && closeMenu();
              }}
              className={classnames(
                "p-3 border-bottom position-relative",
                whatsNextStyles.sidebarLink,
                config[activeTooltipIndex] === MENU_ITEM_IDS.GETTING_STARTED &&
                  isToolTipTourActive &&
                  styles.tooltipContainer,
                window.location.href === whatsNextUrl &&
                  whatsNextStyles.sidebarLinkActive
              )}
            >
              <span style={{ fontSize: "14px" }} className="font-weight-bold">
                <img
                  alt={getReadyToLaunchText}
                  src={require("../../../assets/images/lightbulb-24px.svg")}
                />{" "}
                {getReadyToLaunchText}
              </span>

              <div className="progress mt-2" style={{ height: "6px" }}>
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{ width: `${taskCompletedPercentage}%` }}
                  aria-valuenow={taskCompletedPercentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </InteractiveTooltip>
      )}

      <div
        className={classnames(
          classes.scrollContainer,
          isToolTipTourActive && styles.scrollMenu
        )}
        id={MENU_SCROLLABLE_DIV_ID}
      >
        {isDesktop && VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.DASHBOARD] && (
          <CustomMenuItemLink
            id={MENU_ITEM_IDS.DASHBOARD}
            to={`/Dashboard`}
            primaryText={`Dashboard`}
            leftIcon={<DashboardIcon />}
            showTooltip
            {...commonCustomMenuItemLinkProps}
          />
        )}

        {isDesktop && VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.LISTINGS] && (
          <CustomMenuItemLink
            to={
              getWhatsNextInfo()?.has_listing ||
              !orgPermissions.canCreateOffering()
                ? LISTINGS_ROUTE_IDS[LISTINGS_ROUTE_KEYS.LISTINGS_LIST]
                : LISTINGS_ROUTE_IDS[LISTINGS_ROUTE_KEYS.CREATE_LISTING]
            }
            id={MENU_ITEM_IDS.LISTINGS}
            primaryText={`${
              orgPermissions.canCreateOffering()
                ? "Create / Edit Offerings"
                : "My Offerings"
            }`}
            leftIcon={<ListingsIcon />}
            showTooltip
            {...commonCustomMenuItemLinkProps}
          />
        )}
        {isDesktop && VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.SALES_PAGES] && (
          <CustomMenuItemLink
            id={MENU_ITEM_IDS.SALES_PAGES}
            to={`/sales-and-marketing`}
            primaryText={`Sales & Marketing`}
            leftIcon={<SalesIcon />}
            showTooltip
            {...commonCustomMenuItemLinkProps}
          />
        )}
        {isDesktop && VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.TRANSACTIONS] && (
          <CustomMenuItemLink
            id={MENU_ITEM_IDS.TRANSACTIONS}
            to={
              has_permission("all_transaction")
                ? `/${api.transaction_list}`
                : "/payments/exly/subscription/list"
            }
            primaryText={`Transactions`}
            leftIcon={<TransactionIcon />}
            handleToggleParent={({ forward = false }) => {
              if (forward) moveToAnotherMenuItem({ forward });
            }}
            showTooltip
            {...commonCustomMenuItemLinkProps}
          />
        )}

        {isDesktop ? (
          <>
            {CUSTOMERS}
            {MANAGE_WEBPAGE}
          </>
        ) : (
          <>
            {MANAGE_WEBPAGE}
            {CUSTOMERS}
          </>
        )}

        {WORKFLOW_TOOLS}

        {VISIBLE_MENU_ITEMS_CONFIG[MENU_ITEM_IDS.INTEGRATIONS] && (
          <CustomMenuItemLink
            to={app_route_ids[app_route_keys.integrations]}
            primaryText={app_route_titles[app_route_keys.integrations]}
            leftIcon={<IntegrationsIcon />}
            {...commonCustomMenuItemLinkProps}
            classes={{
              root: classnames(classes.menuLink, classes.soloMenuItem),
            }}
            id={MENU_ITEM_IDS.INTEGRATIONS}
            showTooltip
            handleToggleParent={({ forward = false }) => {
              moveToAnotherMenuItem({ forward });
            }}
          />
        )}

        {EXLY_PLUS}
      </div>

      {open ? (
        !is_empty(process.env.REACT_APP_FRILL_KEY) &&
        liveFeatures.is_premium &&
        !is_staff ? (
          <div
            className={classes.helpContainer}
            onClick={() => {
              window
                .open(`${window.location.origin}/#/ideas`, "_blank")
                .focus();
            }}
          >
            <BoltIcon />
            <span>Build Exly with Us</span>
            <DoubleArrowIcon />
          </div>
        ) : (
          <div
            className={classes.helpContainer}
            onClick={() => {
              window.open(EXLY_SUPPORT_CONSTANTS.exly_support_url, "_blank");
            }}
          >
            <TalkToExpertIcon />
            <span>Contact Support</span>
            <DoubleArrowIcon />
          </div>
        )
      ) : (
        <></>
      )}

      {display_image && open ? (
        <>
          {sub_domain ? (
            <div className={classes.webpageAddress}>
              <div>Your Webpage Address:</div>
              <div className={classes.linkWrapper}>
                <Link
                  target={"_blank"}
                  className={classnames(
                    classes.max1Line,
                    classes.subdomainLink
                  )}
                  rel={"noopener noreferrer"}
                  href={share.url}
                >
                  {share.previewUrl}
                </Link>
                <div
                  className={classes.copyLink}
                  onClick={() => {
                    if (navigator && navigator.clipboard) {
                      navigator.clipboard.writeText(share.url);
                      notify("Link Copied!", "info");
                    }
                  }}
                >
                  <CopyIcon />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default withComponentLibraryTheme(Menu);
