import { useSlate } from 'slate-react';
import { ToolbarDropdown } from '../../../../../RichTextEditorV2/Toolbar/components/ToolbarDropdown';
import { FONT_SIZE_TOOL_OPTIONS } from './constants/FontSizeTool.constants';
import { useStyles } from './FontSizeTool.styles';

export const FontSizeTool = () => {
  const editor = useSlate();
  const classes = useStyles();

  return (
    <ToolbarDropdown
      editor={editor}
      options={FONT_SIZE_TOOL_OPTIONS}
      inputBaseRootClassName={classes.root}
      selectClassName={classes.root}
      format="fontSize"
    />
  );
};
