import { create } from "zustand";

export const useCrmStore = create((set) => ({
  longTextValueModalOpen: "",
  setLongTextValueModalOpen: (value) => set({ longTextValueModalOpen: value }),
  customColumnsMasterObj: {},
  setCustomColumnsMasterObj: ({ value, featureKey }) =>
    set((state) => ({
      customColumnsMasterObj: {
        ...state.customColumnsMasterObj,
        [featureKey]: value,
      },
    })),
}));
