import { is_empty } from "features/Common/utils/common.utils";
import { useCallback, useEffect, useState } from "react";
import { logError } from "utils/error";
import { notification_color_keys, useNotifications } from "utils/hooks";

export const useCustomColumnUpdate = ({
  messageToNotify,
  handleUpdate,
  targetObject,
  defaultValue,
}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedValue !== defaultValue) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  const { notify } = useNotifications();

  const handleUpdateColumnData = useCallback(
    (value) => {
      if (is_empty(targetObject)) return;

      setLoading(true);

      handleUpdate(value)
        .then(() => {
          if (messageToNotify) {
            notify(messageToNotify, notification_color_keys.success);
          }
          setSelectedValue(value);
        })
        .catch((error) => {
          logError({
            error,
            occuredAt:
              "handleUpdate in src/common/Components/AssignMember/AssignMember.jsx",
            when: "Error while updating column data",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [targetObject]
  );

  return {
    loading,
    handleUpdateColumnData,
    setSelectedValue,
    selectedValue,
  };
};
