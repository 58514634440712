import { useSlate } from 'slate-react';
import {
  isBlockActive,
  toggleBlock,
} from '../../../../../RichTextEditorV2/utils/SlateUtilityFunctions';
import { RTE_TOOLBAR_BLOCKS } from '../../constants/RTEToolbar.blocks';
import { ToolbarButton } from '../ToolbarButton/ToolbarButton';
import { IToolbarButton } from '../ToolbarButton/ToolbarButton.interfaces';
import { useStyles } from './QuoteTool.styles';

export const QuoteTool = () => {
  const classes = useStyles();
  const editor = useSlate();

  const handleToggleUnorderedList: IToolbarButton['onMouseDown'] = (e) => {
    e.preventDefault();
    toggleBlock(editor, RTE_TOOLBAR_BLOCKS.QUOTE);
  };

  return (
    <ToolbarButton
      className={classes.root}
      active={isBlockActive(editor, RTE_TOOLBAR_BLOCKS.QUOTE)}
      onMouseDown={handleToggleUnorderedList}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
      >
        <mask
          id="mask0_1566_7860"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="19"
          height="19"
        >
          <rect width="19" height="19" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1566_7860)">
          <path
            d="M2.75 16.9999C2.5375 16.9999 2.35937 16.9304 2.21562 16.7915C2.07187 16.6525 2 16.4803 2 16.2749V3.225C2 3.01958 2.07187 2.84739 2.21562 2.70844C2.35937 2.56948 2.5375 2.5 2.75 2.5C2.9625 2.5 3.14062 2.56948 3.28437 2.70844C3.42812 2.84739 3.5 3.01958 3.5 3.225V16.2749C3.5 16.4803 3.42812 16.6525 3.28437 16.7915C3.14062 16.9304 2.9625 16.9999 2.75 16.9999ZM6.12499 10.765C5.81249 10.765 5.54687 10.6592 5.32812 10.4478C5.10937 10.2363 4.99999 9.97955 4.99999 9.67746C4.99999 9.37538 5.10937 9.11861 5.32812 8.90716C5.54687 8.6957 5.81249 8.58997 6.12499 8.58997H11.375C11.6875 8.58997 11.9531 8.6957 12.1719 8.90716C12.3906 9.11861 12.5 9.37538 12.5 9.67746C12.5 9.97955 12.3906 10.2363 12.1719 10.4478C11.9531 10.6592 11.6875 10.765 11.375 10.765H6.12499ZM6.12499 4.81999C5.81249 4.81999 5.54687 4.71426 5.32812 4.5028C5.10937 4.29135 4.99999 4.03458 4.99999 3.73249C4.99999 3.43041 5.10937 3.17364 5.32812 2.96219C5.54687 2.75073 5.81249 2.645 6.12499 2.645H15.875C16.1875 2.645 16.4531 2.75073 16.6718 2.96219C16.8906 3.17364 17 3.43041 17 3.73249C17 4.03458 16.8906 4.29135 16.6718 4.5028C16.4531 4.71426 16.1875 4.81999 15.875 4.81999H6.12499Z"
            fill="#1C1B1F"
          />
          <path
            d="M5.32815 16.6828C5.5469 16.8943 5.81253 17 6.12503 17H15.875C16.1875 17 16.4531 16.8943 16.6719 16.6828C16.8906 16.4714 17 16.2146 17 15.9125C17 15.6104 16.8906 15.3537 16.6719 15.1422C16.4531 14.9307 16.1875 14.825 15.875 14.825H6.12503C5.81253 14.825 5.5469 14.9307 5.32815 15.1422C5.1094 15.3537 5.00003 15.6104 5.00003 15.9125C5.00003 16.2146 5.1094 16.4714 5.32815 16.6828Z"
            fill="#1C1B1F"
          />
        </g>
      </svg>
    </ToolbarButton>
  );
};
