import { FC } from 'react';

export const AlignedElement: FC<any> = (props) => {
  const { element, children, align = 'start' } = props;
  const attributes = props.attributes ?? {};

  return (
    <div
      style={{
        minHeight: '18px',
        textAlign: align,
      }}
      {...attributes}
      {...element.attr}
    >
      {children}
    </div>
  );
};
