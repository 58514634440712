import {
  FONT_FAMILIES,
  FONT_SIZES,
  RTE_NODE_TYPES,
} from '../constants/RTEEditable.constants';
import { AlignedElement } from '../modules/RTEElementRenderer/modules/AlignedElement/AlignedElement';
import { BlockQuoteElement } from '../modules/RTEElementRenderer/modules/BlockQuoteElement/BlockQuoteElement';
import { DefaultElement } from '../modules/RTEElementRenderer/modules/DefaultElement/DefaultElement';
import { HeadingElement } from '../modules/RTEElementRenderer/modules/HeadingElement/HeadingElement';
import ImageElement from '../modules/RTEElementRenderer/modules/Image/Image';
import Link from '../modules/RTEElementRenderer/modules/Link/Link';
import { ListItemElement } from '../modules/RTEElementRenderer/modules/ListItemElement/ListItemElement';
import { ListItemTextElement } from '../modules/RTEElementRenderer/modules/ListItemElement/modules/ListItemTextElement/ListItemTextElement';
import { OrderedListElement } from '../modules/RTEElementRenderer/modules/OrderedListElement/OrderedListElement';
import { ParagraphElement } from '../modules/RTEElementRenderer/modules/ParagraphElement/ParagraphElement';
import { TableBodyElement } from '../modules/RTEElementRenderer/modules/Table/modules/TableBodyElement/TableBodyElement';
import { TableCellElement } from '../modules/RTEElementRenderer/modules/Table/modules/TableCellElement/TableCellElement';
import { TableHeadElement } from '../modules/RTEElementRenderer/modules/Table/modules/TableHeadElement/TableHeadElement';
import { TableRowElement } from '../modules/RTEElementRenderer/modules/Table/modules/TableRowElement/TableRowElement';
import Table from '../modules/RTEElementRenderer/modules/Table/Table';
import { UnorderedListElement } from '../modules/RTEElementRenderer/modules/UnorderedListElement/UnorderedListElement';
import VariableChip from '../modules/RTEElementRenderer/modules/VariableChip/VariableChips';
import ImageSerialize from '../modules/Serialized/ImageSerialize/ImageSerialize';
import LinkSerialize from '../modules/Serialized/LinkSerialize/LinkSerialize';
import VariableChipSerialize from '../modules/Serialized/VariableChipSerialize/VariableChipSerialize';

export const getJsxStyles = (styles = '') => {
  const o = styles.split(';').map((value) => {
    const arr = value.split(':');
    const key = arr[0];
    const val = arr[1];

    const newKey = key
      .split('-')
      .map((item, index) =>
        (index === 0
          ? item
          : item.charAt(0).toUpperCase() + item.slice(1)
        ).trim()
      )
      .join('');

    const ans: { [key: string]: string } = {};
    ans[newKey] = val?.trim();

    return ans;
  });

  return o.reduce((prev, style) => ({ ...prev, ...style }), {});
};

export const convertStringToCSSObject = (
  styleString: string
): { [key: string]: string } => {
  if (!styleString || styleString?.trim()?.length <= 0) return {};

  const stylesArray = styleString?.split(';');
  const cssObject: { [key: string]: string } = {};

  for (const style of stylesArray) {
    const [property, value] = style.split(':');
    if (property && value) {
      cssObject[property.trim()] = value.trim().replace(/['"]+/g, '');
    }
  }

  return cssObject;
};

export const getRTEMark = (leaf: any, children: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = (
      <code
        style={{
          fontFamily:
            "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
          backgroundColor: '#eee',
          borderRadius: '3px',
          padding: '0 3px',
        }}
      >
        {children}
      </code>
    );
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  if (leaf.strikethrough) {
    children = <del>{children}</del>;
  }
  if (leaf.monospace) {
    children = <tt>{children}</tt>;
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  if (leaf.superscript) {
    children = <sup>{children}</sup>;
  }
  if (leaf.subscript) {
    children = <sub>{children}</sub>;
  }
  if (leaf.color) {
    children = <span style={{ color: leaf.color }}>{children}</span>;
  }
  if (leaf.bgColor) {
    children = (
      <span style={{ backgroundColor: leaf.bgColor }}>{children}</span>
    );
  }
  if (leaf.fontSize) {
    const size = FONT_SIZES[leaf.fontSize];
    children = <span style={{ fontSize: size }}>{children}</span>;
  }
  if (leaf.fontFamily) {
    const family = FONT_FAMILIES[leaf.fontFamily];
    children = <span style={{ fontFamily: family }}>{children}</span>;
  }
  return children;
};

export const getRTEBlock = (props: any, isSerialize?: any) => {
  const { element } = props;
  switch (element.type) {
    case RTE_NODE_TYPES.PARAGRAPH:
      return <ParagraphElement {...props} />;
    case RTE_NODE_TYPES.ORDERED_LIST:
      return <OrderedListElement {...props} />;
    case RTE_NODE_TYPES.UNORDERED_LIST:
      return <UnorderedListElement {...props} />;
    case RTE_NODE_TYPES.LIST_ITEM:
      return <ListItemElement {...props} />;
    case RTE_NODE_TYPES.LIST_ITEM_TEXT:
      return <ListItemTextElement {...props} />;
    case RTE_NODE_TYPES.H1:
    case RTE_NODE_TYPES.H2:
    case RTE_NODE_TYPES.H3:
    case RTE_NODE_TYPES.H4:
    case RTE_NODE_TYPES.H5:
    case RTE_NODE_TYPES.H6:
      return <HeadingElement {...props} />;
    case RTE_NODE_TYPES.BLOCKQUOTE:
      return <BlockQuoteElement {...props} />;
    case RTE_NODE_TYPES.ALIGN_LEFT:
      return <AlignedElement {...props} align="start" />;
    case RTE_NODE_TYPES.ALIGN_CENTER:
      return <AlignedElement {...props} align="center" />;
    case RTE_NODE_TYPES.ALIGN_RIGHT:
      return <AlignedElement {...props} align="right" />;
    case RTE_NODE_TYPES.LINK:
      return isSerialize ? <LinkSerialize {...props} /> : <Link {...props} />;
    case RTE_NODE_TYPES.TABLE:
      return <Table {...props} />;
    case RTE_NODE_TYPES.TABLE_BODY:
      return <TableBodyElement {...props} />;
    case RTE_NODE_TYPES.TABLE_ROW:
      return <TableRowElement {...props} />;
    case RTE_NODE_TYPES.TABLE_HEAD:
      return <TableHeadElement {...props} />;
    case RTE_NODE_TYPES.TABLE_CELL:
      return <TableCellElement {...props} />;
    case RTE_NODE_TYPES.IMAGE:
      return isSerialize ? (
        <ImageSerialize {...props} />
      ) : (
        <ImageElement {...props} />
      );
    case RTE_NODE_TYPES.CHIP: {
      /**
       * Variable chips were handled specially, to clear it completely after backspace was pressed.
       * Due to this, the styles need to be applied specially on this.
       * As and when more issues are reported, we will keep add more handling here.
       *
       * Refer `getMarked` function to understand what needs to be done in each styling issue reported
       */
      const unprocessedNewStyles = element?.children?.[0];
      let chipStyles = {};
      if (unprocessedNewStyles.fontSize) {
        // @ts-ignore
        const size = sizeMap[unprocessedNewStyles.fontSize];
        chipStyles = { fontSize: size };
      }

      return isSerialize ? (
        <VariableChipSerialize {...props} chipStyles={chipStyles} />
      ) : (
        <VariableChip {...props} chipStyles={chipStyles} />
      );
    }
    default:
      return <DefaultElement {...props} />;
  }
};
