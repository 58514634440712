import { useSlate } from 'slate-react';
import { CustomDropdown } from '../../../../../RichTextEditorV2/Toolbar/components/CustomDropdown';
import { HEADING_TOOL_OPTIONS } from './constants/HeadingTool.constants';
import { useStyles } from './HeadingTool.styles';

export const HeadingTool = () => {
  const editor = useSlate();
  const classes = useStyles();

  return (
    <CustomDropdown
      className={classes.root}
      editor={editor}
      placeholder="Normal"
      minWidth="116.56px"
      options={HEADING_TOOL_OPTIONS}
    />
  );
};
