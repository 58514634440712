import React from "react";
import ExlySpinner from "ui/modules/ExlySpinner/ExlySpinner";
import { ComplexDropdown } from "@my-scoot/component-library-legacy";
import { useCustomColumnUpdate } from "../../CustomColumn/utils/useCustomColumnUpdate";
import styles from "./MultipleSelectDropdownField.module.css";
import classnames from "classnames";

export const MultipleSelectDropdownField = ({
  options,
  targetObject,
  handleUpdate,
  defaultValue,
  disabled,
  columnWidth,
}) => {
  const { loading, selectedValue, handleUpdateColumnData, setSelectedValue } =
    useCustomColumnUpdate({
      handleUpdate,
      targetObject,
      defaultValue,
    });

  if (loading) return <ExlySpinner loaderWidth="24px" wrapperPadding="0 0" />;

  return (
    <ComplexDropdown
      options={options}
      placeholder="Select"
      value={selectedValue || []}
      disabled={disabled}
      onClose={() => handleUpdateColumnData(selectedValue)}
      handleChange={setSelectedValue || []}
      formControlClassName={classnames(
        styles.complexDropdown,
        columnWidth && styles.complexDropdownWidth
      )}
      inputBaseRootClassName={classnames(
        styles.complexDropdown,
        columnWidth && styles.complexDropdownWidth
      )}
      checked
      fullWidth
    />
  );
};
