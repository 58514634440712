import ReactDOMServer from 'react-dom/server';
import { Text } from 'slate';
import {
  getRTEBlock,
  getRTEMark,
} from '../modules/RTEEditable/utils/RTEEditable.utils';

const { renderToStaticMarkup } = ReactDOMServer;

const decode = (str: string) => {
  return str
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"');
};

export const serialize = (node: any) => {
  if (Text.isText(node)) {
    let string = getRTEMark(node, node.text);
    string = renderToStaticMarkup(string);
    return string;
  }
  const children = node.children.map((n: any) => serialize(n)).join('');

  let block = getRTEBlock({ children, element: node }, true);
  return decode(renderToStaticMarkup(block));
};

export const serializer = (editorValue: any) => {
  if (!editorValue) return;

  if (editorValue.length > 0) {
    return editorValue.map((n: any) => serialize(n)).join('');
  }
};
