import { WhatsAppPhoneField } from "common/Components/TableFields/TableFields";
import { analyticsConstants } from "constants/analyticsConstants";
import React from "react";
import {
  NameField,
  TagsField,
} from "ui/pages/customers/AllCustomers/components/TableColumns/TableColumns";
import { RESOURCE_KEYS } from "utils/OrgPermissions";

import {
  EmailField,
  ConvertedLeadStatus,
  RemarkStatusField,
  ReasonOfContactField,
  LinkField,
  LeadActionsField,
} from "./components/CustomColumns";
import { assignLeadToStaff, hasPermissionToEditLogs } from "./webpageHelpers";
import { is_empty } from "utils/validations";
import { UTMInfoField } from "common/form/UTMInfoField";
import { ENTITY_TYPE } from "constants/crmConstants";
import { getDateInMonthDayYearFormat } from "features/Common/DateFns.utils";
import { leads_table_config } from "features/Crm/modules/WebpageLeads/WebpageLeads.constants";
import { empty_option } from "common/Components/AssignMemberDropdown/AssignMemberDropdown.constants";
import { AssignMemberDropdownField } from "features/Crm/modules/ManageColumns/modules/TableFieldsMapping/AssignMemberDropdownField/AssignMemberDropdownField";
import {
  formatCustomChip,
  transformCustomColumns,
} from "features/Crm/modules/ManageColumns/modules/CustomColumn/utils/CustomColumn.utils";

export const columnConfig = ({
  orgPermissions,
  orgMembers,
  setOpenDetailOnLoadRecordId,
  handleUserClick,
  setScrollPosition,
  setShowNotesModal,
  setAddTagsModal,
  setShowTagsModal,
  disabled,
  onResponsesClick,
  customColumnsToRender = [],
}) => {
  const transformedObject = transformCustomColumns({ customColumnsToRender });

  return {
    [leads_table_config.full_name.field]: {
      field: leads_table_config.full_name.field,
      headerName: leads_table_config.full_name.placeholder,
      isPrimary: true,
      isDrawerTitle: true,
      emptyField: "N/A",
      valueFormatter: (record) => (
        <NameField
          record={record}
          source={leads_table_config.full_name.field}
          onClick={handleUserClick}
        />
      ),
      //desktop
      avatar: true,
      sortable: false,
      width: "200px",
      columnClassName: "word_break",
      ellipsis: true,
      maxCharCount: 25,
      fixed: "left",
    },
    [leads_table_config.org__uuid.field]: {
      field: leads_table_config.org__uuid.field,
      headerName: leads_table_config.org__uuid.placeholder,
      hidden: !orgPermissions.canAssign(),
      hiddenDesktopColumn: !orgPermissions.canAssign(),
      valueFormatter: (record) => (
        <AssignMemberDropdownField
          options={[empty_option, ...orgMembers]}
          targetObject={record[leads_table_config.id.field]}
          handleUpdate={(e) =>
            assignLeadToStaff({ value: e, leadId: record.lead_id })
          }
          disabled={disabled}
          defaultValue={record[leads_table_config.org__uuid.field]}
          messageToNotify={`${ENTITY_TYPE.lead.label}  assigned successfully!`}
        />
      ),
      width: "15%",
      noWrap: true,
    },
    [leads_table_config.phone_number.field]: {
      field: leads_table_config.phone_number.field,
      headerName: leads_table_config.phone_number.placeholder,
      valueFormatter: (record) => (
        <WhatsAppPhoneField
          record={record}
          source={leads_table_config.phone_number.field}
          countryCodeKey="country_code"
        />
      ),
      width: "10%",
      noWrap: true,
    },
    [leads_table_config.email.field]: {
      field: leads_table_config.email.field,
      headerName: leads_table_config.email.placeholder,
      valueFormatter: (record) =>
        record.email ? <EmailField record={record} /> : "N/A",
      width: "10%",
      isUnique: true,
      noWrap: true,
    },
    [leads_table_config.last_occurrence_time.field]: {
      field: leads_table_config.last_occurrence_time.field,
      headerName: leads_table_config.last_occurrence_time.placeholder,
      valueFormatter: (record) =>
        getDateInMonthDayYearFormat(record.last_occurrence_time),
      width: "10%",
      noWrap: true,
      sortable: false,
    },
    [leads_table_config.reason.field]: {
      field: leads_table_config.reason.field,
      headerName: leads_table_config.reason.placeholder,
      emptyField: "N/A",
      width: "300px",
      renderCell: (record) =>
        record.reason ? (
          <ReasonOfContactField
            record={record}
            source={leads_table_config.reason.field}
          />
        ) : (
          "N/A"
        ),
      noWrap: true,
    },
    [leads_table_config.status.field]: {
      field: leads_table_config.status.field,
      headerName: leads_table_config.status.placeholder,
      valueFormatter: (record) => (
        <ConvertedLeadStatus
          record={record}
          setOpenDetailOnLoadRecordId={setOpenDetailOnLoadRecordId}
          hideLabel
          setScrollPosition={setScrollPosition}
        />
      ),
      // isCollapsed: true,
      width: "10%",
    },
    [leads_table_config.first_occurrence_time.field]: {
      field: leads_table_config.first_occurrence_time.field,
      headerName: leads_table_config.first_occurrence_time.placeholder,
      valueFormatter: (record) =>
        getDateInMonthDayYearFormat(record.first_occurrence_time),
      width: "10%",
      noWrap: true,
      sortable: false,
    },
    [leads_table_config.lead_remarks.field]: {
      field: leads_table_config.lead_remarks.field,
      headerName: leads_table_config.lead_remarks.placeholder,
      renderColumn: (record) => (
        <RemarkStatusField
          record={record}
          source={leads_table_config.lead_remarks.field}
          setScrollPosition={setScrollPosition}
        />
      ),
      renderCell: (record) => (
        <RemarkStatusField
          record={record}
          hideLabel
          source={leads_table_config.lead_remarks.field}
          setScrollPosition={setScrollPosition}
        />
      ),
      isCollapsed: true,
      noWrap: true,
      width: "10%",
    },

    [leads_table_config.referer_group_id.field]: {
      field: `${leads_table_config.referer_group_id.field}`,
      headerName: leads_table_config.referer_group_id.placeholder,
      emptyField: "N/A",
      width: "100px",
      columnClassName: "word_break",
      renderCell: (row) =>
        `${
          row.referer_group_id !== null
            ? analyticsConstants.lead_source[row.referer_group_id - 1]?.label //Getting ids from indexes doing -1 as mapping is id - 1
            : "N/A"
        }`,

      ellipsis: true,
      maxCharCount: 25,
    },
    [leads_table_config.source_link.field]: {
      field: `${leads_table_config.source_link.field}`,
      headerName: leads_table_config.source_link.placeholder,
      emptyField: "N/A",
      valueFormatter: (record) =>
        record.source_link ? (
          <LinkField
            onClick={(record) => {
              window.location.href = record.source_link;
            }}
            linkText={record.source_link}
            record={record}
            source={`${leads_table_config.source_link.field}`}
          />
        ) : (
          "N/A"
        ),
      noWrap: true,
    },
    [leads_table_config.country.field]: {
      field: `${leads_table_config.country.field}`,
      headerName: leads_table_config.country.placeholder,
      emptyField: "N/A",
      width: "100px",
      columnClassName: "word_break",
      ellipsis: true,
      maxCharCount: 25,
    },

    [leads_table_config.answer_count.field]: {
      field: leads_table_config.answer_count.field,
      headerName: leads_table_config.answer_count.placeholder,
      valueFormatter: (record) => {
        if (!record?.answer_count) return "N/A";
        return (
          <LinkField
            onClick={() => {
              onResponsesClick(record);
            }}
            record={record}
            source={leads_table_config.answer_count.field}
            linkText="See responses"
          />
        );
      },
      noWrap: true,
    },
    [leads_table_config.tags.field]: {
      field: leads_table_config.tags.field,
      headerName: leads_table_config.tags.placeholder,
      valueFormatter: (record) => (
        <TagsField record={record} onClick={() => setShowTagsModal(record)} />
      ),
      noWrap: true,
      sortable: false,
    },
    [leads_table_config.utm_info.field]: {
      field: leads_table_config.utm_info.field,
      headerName: leads_table_config.utm_info.placeholder,
      valueFormatter: (record) =>
        !is_empty(record.utm_info) ? <UTMInfoField record={record} /> : null,
      width: "10%",
      noWrap: true,
      isCollapsed: true,
    },
    ...(transformedObject || {}),
    [leads_table_config.action.field]: hasPermissionToEditLogs(
      RESOURCE_KEYS.SUBSECTIONS.LEADS.WEBPAGE_LEADS
    )
      ? {
          field: "",
          headerName: leads_table_config.action.placeholder,
          valueFormatter: (record) => (
            <LeadActionsField
              onClick={(record, e) => {
                const { id } = e.target;
                if (id === "note") setShowNotesModal(record?.email);
                else setAddTagsModal(record?.email, record?.id);
              }}
              record={record}
            />
          ),
          isCollapsed: true,
          hidden: true,
          noWrap: true,
          fixed: "right",
          width: "80px",
        }
      : null,
  };
};

export const filterConfig = (
  listingsFilter,
  statusFilter,
  orgMembersFilter,
  showStaffFilter,
  customFiltersToAppend
) => [
  {
    source: "select",
    placeholder: "-select-",
    type: "input",
  },
  {
    source: leads_table_config.full_name.field,
    placeholder: leads_table_config.full_name.filterPlaceholder,
    type: "input",
  },
  {
    source: leads_table_config.email.field,
    placeholder: leads_table_config.email.placeholder,
    type: "input",
  },
  {
    source: leads_table_config.phone_number.field,
    placeholder: leads_table_config.phone_number.placeholder,
    type: "input",
  },
  {
    source: leads_table_config.org__uuid.filterField,
    placeholder: leads_table_config.org__uuid.filterPlaceholder,
    type: "dropdown",
    disableContains: true,
    options: orgMembersFilter,
    hidden: !showStaffFilter,
  },
  {
    source: "listing_id",
    placeholder: "Offering",
    options: listingsFilter,
    type: "dropdown",
  },
  {
    source: leads_table_config.status.field,
    placeholder: leads_table_config.status.placeholder,
    options: statusFilter,
    type: "dropdown",
  },
  {
    source: `${leads_table_config.last_occurrence_time.field}__start_range`,
    placeholder: leads_table_config.last_occurrence_time.placeholder,
    type: "date",
  },
  {
    source: `${leads_table_config.first_occurrence_time.field}__start_range`,
    placeholder: leads_table_config.first_occurrence_time.placeholder,
    type: "date",
  },
  {
    source: `${leads_table_config.utm_info.field}__source`,
    placeholder: "UTM source",
    type: "input",
  },
  {
    source: `${leads_table_config.utm_info.field}__medium`,
    placeholder: "UTM medium",
    type: "input",
  },
  {
    source: `${leads_table_config.utm_info.field}__campaign`,
    placeholder: "UTM campaign",
    type: "input",
  },
  ...customFiltersToAppend,
];

export const tabFilterConfig = [
  {
    label: "Incomplete payments",
    source: "reason_type",
    filterValue: 21,
    value: "reason-type_21",
  },
  {
    label: "Lead Intent",
    value: "lead-intent_subTabs",
    subTabs: [
      {
        label: "High Intent",
        source: "lead_intent",
        filterValue: 1,
        value: "lead-intent_1",
      },
      {
        label: "Low Intent",
        source: "lead_intent",
        filterValue: 3,
        value: "lead-intent_3",
      },
    ],
  },
  {
    label: "New Leads",
    value: "new-intent_subTabs",
    subTabs: [
      {
        label: "Captured in last 7 days",
        source: "days",
        filterValue: 7,
        value: "new_intent_7",
      },
      {
        label: "Captured in last 30 days",
        source: "days",
        filterValue: 30,
        value: "new_intent_30",
      },
    ],
  },
  // Commenting this out as not required in CRmv2 scope
  // Might become a usecase in future hence not removing
  // {
  //   label: "Lead Source",
  //   value: "lead-source_subTabs",
  //   subTabs: [
  //     {
  //       label: "Interested in an offering",
  //       source: "reason_type",
  //       filterValue: 0,
  //       value: "lead-source_0",
  //     },
  //     {
  //       label: "Payment Failed",
  //       source: "reason_type",
  //       filterValue: 4,
  //       value: "lead-source_4",
  //     },
  //     {
  //       label: "Payment Initiated",
  //       source: "reason_type",
  //       filterValue: 5,
  //       value: "lead-source_5",
  //     },
  //     {
  //       label: "Requested to be contacted",
  //       source: "reason_type",
  //       filterValue: 1,
  //       value: "lead-source_1",
  //     },
  //     {
  //       label: "Viewed an offering",
  //       source: "reason_type",
  //       filterValue: 2,
  //       value: "lead-source_2",
  //     },
  //     {
  //       label: "Viewed your website",
  //       source: "reason_type",
  //       filterValue: 3,
  //       value: "lead-source_3",
  //     },
  //     {
  //       label: "Imported leads",
  //       source: "reason_type",
  //       filterValue: 8,
  //       value: "lead-source_8",
  //     },
  //   ],
  // },
];

export const tableChipsFormatter =
  (listingsFilter, statusFilter, orgMembersFilter, customChipsToFormat) =>
  (filter, value) => {
    // Check for custom chips first
    const customChip = formatCustomChip(customChipsToFormat, filter, value);
    if (customChip) return customChip;

    switch (filter) {
      case "days":
        return null;
      case "lead_intent":
        return null;
      case "reason_type":
        return null;
      case leads_table_config.status.field: {
        const status = statusFilter?.find((status) => status.id == value);
        return `${leads_table_config.status.placeholder}: ${status?.name}`;
      }
      case leads_table_config.org__uuid.filterField: {
        const staffMember = orgMembersFilter?.find(
          (staff) => staff.id == value
        );
        return `${leads_table_config.org__uuid.filterPlaceholder}: ${staffMember?.name}`;
      }
      case "listing_id": {
        const offering = listingsFilter?.find(
          (offering) => offering.id == value
        );
        return offering ? `Offering: ${offering?.title}` : null;
      }
      case `${leads_table_config.email.field}__icontains`:
        return `${leads_table_config.email.placeholder}: ${value}`;
      case `${leads_table_config.utm_info.field}__source__icontains`:
        return `UTM source: ${value}`;
      case `${leads_table_config.utm_info.field}__medium__icontains`:
        return `UTM medium: ${value}`;
      case `${leads_table_config.utm_info.field}__campaign__icontains`:
        return `UTM campaign: ${value}`;
      case `${leads_table_config.full_name.field}__icontains`:
        return `${leads_table_config.full_name.filterPlaceholder}: ${value}`;
      case `${leads_table_config.phone_number.field}__icontains`:
        return `${leads_table_config.phone_number.placeholder}: ${value}`;
      case `${leads_table_config.last_occurrence_time.field}__start_range`:
        return `${leads_table_config.last_occurrence_time.placeholder}: ${value}`;
      case `${leads_table_config.first_occurrence_time.field}__start_range`:
        return `${leads_table_config.first_occurrence_time.placeholder}: ${value}`;
      default:
        return undefined;
    }
  };
