// Third party imports
import React, { useCallback, useEffect, useState } from "react";
import { useListController } from "react-admin";
import { useDispatch } from "react-redux";
import {
  Button,
  ThemeProvider,
  creatorToolTheme,
} from "@my-scoot/component-library-legacy";

// Styles and icons
import useStyles from "./AllCustomers.styles";

// Util functions and providers
import { is_empty } from "../../../../utils/validations";
import dataProvider from "../../../../data/dataProvider";
import {
  RESOURCE_KEYS,
  orgPermissions,
} from "../../../../utils/OrgPermissions";
import { useNotifications } from "utils/hooks/useNotifications";
import { useToggleState } from "utils/hooks/useToggleState";
import {
  getTableConfig,
  tabFilterConfig,
  filterConfig,
  tableChipsFormatter,
} from "./allCustomersTableConfig";
import {
  ALL_CUSTOMERS_LEARN_MORE_HREFS,
  customer_table_config,
} from "features/Crm/modules/AllCustomers/AllCustomers.constants";
import { exportLargeListingData } from "../../../../utils/Utils";
import constants from "../../../../constants/constants";
import { getSubdomain } from "features/Account/utils/getCreatorPageDetails";

// Components
import ExlyTable from "../../../../common/Components/ExlyTable";
import { WhatsAppButton } from "webpage-leads/components/CustomColumns";
import { BroadcastModal } from "common/Components/BroadcastModal/BroadcastModal";
import ExlyImage from "common/Components/ExlyImage";

import ManageColumns from "assets/vectors/crm/show_columns.svg";
import ManageColumnsModal from "webpage-leads/components/CrmModals/ManageColumnsModal/ManageColumnsModal";
import { app_pathnames, app_route_keys } from "constants/urlPaths";
import CustomerTransactionsV2 from "../../../../features/Crm/modules/Bookings/modules/CustomerTransactionsModal/CustomerTransactionsModal";
import useWebpageLeads from "webpage-leads/useWebpageLeads";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useColumnsToShow from "features/Crm/utils/hooks/useColumnsToShow";

import CustomFiltersCustomer from "./components/CustomFiltersCustomers/CustomFiltersCustomer";
import { CustomerActionsField } from "./components/TableColumns/TableColumns";
import { REPORT_TYPES } from "ui/pages/Reports/constants";
import BukImportStrip from "webpage-leads/components/BulkImportStrip/BukImportStrip";
import BulkImportModal from "../ImportClients/components/BulkImportModal/BulkImportModal";
import BulkImportProgressModal from "../ImportClients/components/BulkImportProgressModal/BulkImportProgressModal";
import { ENTITY_TYPE } from "constants/crmConstants";
import styles from "./AllCustomers.module.css";
import usePostBulkUpload from "../ImportClients/hooks/usePostBulkUpload";
import { import_type } from "../ImportClients/importClientsConstants";
import { getColumns } from "webpage-leads/webpageHelpers";
import ExlyResponsesModal from "features/Crm/modules/CrmResponses/components/ExlyResponsesModal/ExlyResponsesModal";
import { useCrmResponses } from "features/Crm/modules/CrmResponses/utils/hooks/useCrmResponses";
import { RESET_RESPONSES_PAYLOAD } from "features/Crm/modules/CrmResponses/utils/redux/Crm.action";
import SelectionCard from "features/BulkActions/modules/SelectionCard/SelectionCard";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useBulkActions from "features/BulkActions/utils/hooks/useBulkActions";
import { BULK_ACTION_TABLES } from "features/BulkActions/BulkActions.constants";
import { DEFAULT_PER_PAGE } from "constants/numbers";
import AssignTags from "features/Crm/modules/AssignTags/AssignTags";
import { Header } from "common/Components/Header";
import CrmNotes from "features/Crm/modules/CrmNotes/CrmNotes";
import { EXLY_TABLE_EMPTY_STATES_VERSIONS } from "common/Components/ExlyTable/ExlyTableEmptyState/constants/ExlyTableEmptyState.constants";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";
import { useCustomerDetailsNavigations } from "features/Crm/utils/hooks/useCustomerDetailsNavigations";
import { TABLE_ACTIONS_IDS } from "features/Crm/Crm.constants";
import useOrgMemberList from "utils/hooks/useOrgMemberList";
import { CUSTOM_COLUMN_SECTIONS } from "features/Crm/modules/ManageColumns/modules/CustomColumn/constants/CustomColumn.constants";
import { useCustomColumnsConfig } from "features/Crm/modules/ManageColumns/modules/CustomColumn/utils/useCustomColumnsConfig";
import { getPerPageOptions } from "features/Crm/utils/Crm.utils";

// Local constants
let form_data = {
  history: "View activity",
};

const customer_entity_name = ENTITY_TYPE.customer.key;

const getColumnsHelper = () =>
  getColumns(constants.entity_type.customers.value);

const handleClientUpload = () => {
  window.location.href = `${window.location.origin}/#/${app_pathnames[
    app_route_keys.import_clients
  ](ENTITY_TYPE.customer.key)}`;
};

// Main Component
const AllCustomers = (props) => {
  const [customerUsername, setCustomerUsername] = useState(null);
  const { canBroadcastMessage, onTagsAssign: onTagsAssignPerRecord } =
    useWebpageLeads();

  const {
    getTableActions,
    showAddTagsModal,
    setShowAddTagsModal,
    showBroadcastModal,
    setShowBroadcastModal,
    handleRowsSelected,
    selectedRecords,
    onTagsAssign,
    initiateBroadcast,
    currentPageFullSelection,
    selectAll,
    getBroadcastActions,
    handleSelectAll,
    generateFooterProps,
    setEmailId: setLeadEmailId,
    emailId: leadEmailId,
  } = useBulkActions(props);

  const {
    isOnboardedPrevVal: isFeatureVisited,
    flagLoading: isFeatureFlagLoading,
    handleFeatureOnboardChange,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.KNOWLEDGE_BASE.ALL_CUSTOMERS,
    successCallback: (marked) => !marked && handleFeatureOnboardChange(true),
  });

  const {
    isBulkImportProcessing,
    bulkUploadApiResponse,
    showBulkUploadModal,
    showImportAlreadyInProgress,
    handleShowImportAlreadyInProgressModal,
    showBulkErrorModal,
    debouncedHandleBulkImportStripRefresh,
    bulkImportSuccessModalProps,
    bulkImportErrorModalProps,
    bulkImportAlreadyInProgressModalProps,
  } = usePostBulkUpload({ type: import_type.customer, handleClientUpload });

  const { handleCustomerDetailNavigation, scrollToLastViewedRow } =
    useCustomerDetailsNavigations();
  const { setResponsesPayloadToRedux } = useCrmResponses();

  const dispatch = useDispatch();

  const [isModalActive, hideModal] = useToggleState(false); // CustomerTransactionsModal
  const [customerName, setCustomerName] = useState();
  const [customerTagsArray, setCustomerTagsArray] = useState([]);
  const [showResponsesModal, setShowResponsesModal] = useState(false);

  const { notify } = useNotifications();
  const { filterValues, selectedIds, setPerPage, perPage, total, onSelect } =
    useListController({ ...props, perPage: DEFAULT_PER_PAGE });

  const { columnsToShow } = useColumnsToShow({
    getColumnsHelper,
  });
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles();
  const canEditCustomers = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.ALL_CUSTOMERS
  );

  const [isNotesModalActive, showNotesModal, hideNotesModal] =
    useToggleState(false);
  const [isShowTagsModalActive, openShowTagsModal, closeShowTagsModal] =
    useToggleState(false);
  const [isManageColumnsActive, showManageColumns, hideManageColumns] =
    useToggleState(false);

  const showActions = canEditCustomers || canBroadcastMessage;

  if (!is_empty(filterValues) && perPage === 10) {
    setPerPage(50);
  }

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_RESPONSES_PAYLOAD });
      onSelect([]); //@Dev need to clear the selectedIds when moved to another route
    };
  }, []);

  const setShowNotesModal = useCallback((email) => {
    setLeadEmailId(email);
    showNotesModal();
  }, []);

  const setShowTagsModal = useCallback((record) => {
    const { tags, customer_name, customer_email } = record;
    setCustomerTagsArray(tags);
    setCustomerName(customer_name);
    setLeadEmailId(customer_email);
    openShowTagsModal();
  }, []);

  const onViewActivity = (record) => {
    dataProvider.postAnalyticalEvent("allCustomers", "viewTransactionHistory", {
      flow: "allCustomers",
      funnel: "allCustomers",
      ui_component: "Button",
      identifier: form_data.history,
      user_input: record.username,
    });
    handleCustomerDetailNavigation({
      entityType: constants.entity_type.customers.value,
      customerId: record.username,
    });
  };

  const onShowTagsClicked = useCallback((email, customerUsername) => {
    setLeadEmailId(email);
    setCustomerUsername(customerUsername);
    setShowAddTagsModal(true);
  }, []);

  window.sessionStorage.setItem("fromRoute", "All Customers");

  const hideTotalSpent = orgPermissions.isFieldHidden(
    RESOURCE_KEYS.SECTIONS.ALL_CUSTOMERS,
    RESOURCE_KEYS.SUBSECTIONS.ALL_CUSTOMERS.ALL_CUSTOMERS,
    RESOURCE_KEYS.FIELDS.ALL_CUSTOMERS.ALL_CUSTOMERS.TOTAL_SPENT
  );

  const hideViewActivity = orgPermissions.isFieldHidden(
    RESOURCE_KEYS.SECTIONS.ALL_CUSTOMERS,
    RESOURCE_KEYS.SUBSECTIONS.ALL_CUSTOMERS.ALL_CUSTOMERS,
    RESOURCE_KEYS.FIELDS.ALL_CUSTOMERS.ALL_CUSTOMERS.VIEW_ACTIVITY
  );

  const broadcastActions = getBroadcastActions({
    action: ({ tableName, broadcastType }) =>
      initiateBroadcast({ tableName, broadcastType }),
    entityName: customer_entity_name,
    tableName: BULK_ACTION_TABLES.CUSTOMERS,
  });

  const { appendCustomTableConfig } = useCustomColumnsConfig({
    sectionName: CUSTOM_COLUMN_SECTIONS.CUSTOMERS.section_name,
    recordSlug: CUSTOM_COLUMN_SECTIONS.CUSTOMERS.record_slug,
  });

  const { orgMembers } = useOrgMemberList();
  const {
    customColumnsToRender = [],
    customFiltersToAppend,
    customChipsToFormat,
  } = appendCustomTableConfig({
    orgMembers,
    disabled: !canEditCustomers,
  });
  const onResponsesClick = (record) => {
    setShowResponsesModal(true);
    setResponsesPayloadToRedux({
      entityType: constants.entity_type.customers.value,
      uniqueId: record.username,
    });
  };
  const columnsObj = getTableConfig({
    onClickNameField: (record) =>
      handleCustomerDetailNavigation({
        entityType: constants.entity_type.customers.value,
        customerId: record.username,
      }),
    hideTotalSpent,
    hideViewActivity,
    onViewActivity,
    setShowNotesModal,
    onShowTagsClicked,
    setShowTagsModal,
    onResponsesClick: (record) => onResponsesClick(record),
    customColumnsToRender,
  });

  const customColumn = [
    ...columnsToShow,
    ...customColumnsToRender?.map((item) => item.field),
  ];
  // Getting filtered columns array of length 5
  let columnsObjFilters =
    customColumn?.map((column) => {
      return columnsObj[`${column}`];
    }) || [];

  //Pushing first and last column irrespective of any colum selected
  columnsObjFilters.push(columnsObj[customer_table_config.action.field]);
  columnsObjFilters.unshift(
    columnsObj[customer_table_config.customer_name.field]
  );

  //filtering out columns to render
  const columnsToRender = columnsObjFilters?.filter((value) => {
    return !is_empty(value);
  });

  const secondaryComponent = (
    <div className={classes.desktop_actions}>
      {canEditCustomers && isDesktop && (
        <div className={classes.buttonWrapper}>
          <Button
            color="tertiary"
            variant="outlined"
            onClick={() => showManageColumns()}
            size="medium"
            aria-controls="simple-menu"
            aria-haspopup="true"
          >
            <ExlyImage
              src={ManageColumns}
              alt={"show-column"}
              height={13}
              width={13}
              className={classes.manageColumnsImage}
            />
            Manage Columns
          </Button>
          <Button
            color="secondary"
            size="medium"
            onClick={
              isBulkImportProcessing
                ? handleShowImportAlreadyInProgressModal
                : handleClientUpload
            }
          >
            Import Customers
          </Button>
        </div>
      )}
    </div>
  );

  const bulkActions = getTableActions(
    RESOURCE_KEYS.SUBSECTIONS.ALL_CUSTOMERS.ALL_CUSTOMERS
  );

  const footerProps = generateFooterProps({ bulkActions });

  useEffect(() => {
    scrollToLastViewedRow();
  }, [columnsToRender]);

  const perPageOptions = getPerPageOptions({ customColumnsToRender });

  return (
    <div>
      {isDesktop && (
        <Header
          title="All Customers"
          learnMoreHref={ALL_CUSTOMERS_LEARN_MORE_HREFS.ALL_CUSTOMERS}
          about="about managing your customers"
          right={secondaryComponent}
          classNames={{ container: styles.headerContainer }}
          hideBackButton
        />
      )}

      {columnsToRender.length > 0 ? (
        <ExlyTable
          ra_props={{
            ...props,
            perPageOptions,
          }}
          entityName={customer_entity_name}
          bulkActionComponent={
            <SelectionCard
              actions={bulkActions}
              selectedRecords={Object.keys(selectedRecords)}
              totalRecords={total}
              currentPageFullSelection={currentPageFullSelection}
              entityName={customer_entity_name}
              selectAll={selectAll}
              handleSelectAll={handleSelectAll}
            />
          }
          className={isBulkImportProcessing && styles.custom_filter_bulk_import}
          noExportButton={!orgPermissions.canExport()}
          allValuesSelected={selectAll}
          tableTitle={isDesktop ? undefined : "All Customers"}
          layoutProps={{
            paddingDesktop: "0",
            paddingBottom: showActions ? "120px" : "68px",
            layoutDesktopMargin: "20px 24px",
            noMobileBoxShadow: true,
            learnMoreHref: ALL_CUSTOMERS_LEARN_MORE_HREFS.ALL_CUSTOMERS,
          }}
          customHandleExport={({ filterValues, isEmpty }) =>
            exportLargeListingData({
              isEmpty,
              filterValues,
              reportType: REPORT_TYPES.CUSTOMERS,
              notify,
            })
          }
          statsComponent={() =>
            isBulkImportProcessing ? (
              <BukImportStrip
                handleRefreshClick={debouncedHandleBulkImportStripRefresh}
                lastSyncTime={bulkUploadApiResponse.current_server_time}
              />
            ) : (
              <></>
            )
          }
          checkboxSelection
          selected={selectedIds}
          entityType={constants.entity_type.customers.value}
          onRowSelected={handleRowsSelected}
          columnConfig={columnsToRender}
          tableFilters={[...filterConfig, ...customFiltersToAppend]}
          hasMobileFooter={showActions}
          tabFilterConfig={tabFilterConfig}
          tableChipsFormatter={tableChipsFormatter(customChipsToFormat)}
          renderPrimaryColumnRightNode={(record) => {
            return (
              <>
                {is_empty(record.phone_number) ? null : (
                  <WhatsAppButton
                    record={record}
                    source="phone_number"
                    countryCodeKey="country_code"
                  />
                )}
              </>
            );
          }}
          renderSecondaryAction={(record) => (
            <CustomerActionsField
              record={record}
              onClick={(record, e) => {
                const { id } = e.target;
                if (id === TABLE_ACTIONS_IDS.NOTE)
                  setShowNotesModal(record?.customer_email);
                else
                  onShowTagsClicked(record?.customer_email, record?.username);
              }}
            />
          )}
          showSelectAllcheckBox={true}
          CustomFilters={CustomFiltersCustomer}
          borderedFields
          fieldsLeftPadded
          drawerFieldsBordered
          fieldsAlignment="space-between"
          drawerFieldsAlignment="space-between"
          recordFooterVariant="secondary"
          footerProps={footerProps}
          customPrimaryAction={
            !hideViewActivity && {
              label: form_data.history,
              onClick: onViewActivity,
            }
          }
          primaryKey="id"
          emptyStateProps={{
            isLoading: isFeatureFlagLoading,
            version: EXLY_TABLE_EMPTY_STATES_VERSIONS.V2,
            title: "No customers yet",
            description:
              "As soon as we have a new customer, it will show up here. View the video to learn more.",
            videoSrc: ALL_CUSTOMERS_LEARN_MORE_HREFS.ALL_CUSTOMERS_VIDEO,
            playerProps: {
              playing: !isFeatureFlagLoading && !isFeatureVisited, // autoplay the knowledge base video on the first visit to this feature
            },
            primaryCtaProps: {
              title: "Add a session",
              onClick: () => {
                window.sessionStorage.setItem("fromRoute", "All Customers");
                window.location.href = !is_empty(getSubdomain())
                  ? `${window.location.origin}/#/ScheduleList`
                  : `${window.location.origin}/#/TemplatePreview`;
              },
            },
            className: "mt-4",
          }}
        />
      ) : (
        <></>
      )}

      <ThemeProvider theme={creatorToolTheme}>
        {isNotesModalActive && (
          <CrmNotes
            feature={RESOURCE_KEYS.SUBSECTIONS.ALL_CUSTOMERS.ALL_CUSTOMERS}
            open={isNotesModalActive}
            onClose={hideNotesModal}
            emailId={leadEmailId}
          />
        )}
        <AssignTags
          feature={RESOURCE_KEYS.SUBSECTIONS.ALL_CUSTOMERS.ALL_CUSTOMERS}
          tableName={BULK_ACTION_TABLES.CUSTOMERS}
          entityName={customer_entity_name}
          selectedRecords={selectedRecords}
          onTagsAssign={onTagsAssign}
          onTagsAssignPerRecord={onTagsAssignPerRecord}
          isShowTagsModalActive={isShowTagsModalActive}
          closeShowTagsModal={closeShowTagsModal}
          customerTagsArray={customerTagsArray}
          customerName={customerName}
          setShowAddTagsModal={setShowAddTagsModal}
          uniqueRowId={customerUsername}
          leadEmailId={leadEmailId}
          setLeadEmailId={setLeadEmailId}
          showAddTagsModal={showAddTagsModal}
        />
        {canBroadcastMessage && showBroadcastModal && (
          <BroadcastModal
            open={showBroadcastModal}
            onClose={() => setShowBroadcastModal(false)}
            modal_props={{ title: "Send Message" }}
            mobile_modal_props={{ header: "Select Broadcast medium" }}
            broadcastActions={broadcastActions}
          />
        )}
        {isManageColumnsActive && (
          <ManageColumnsModal
            key={"ManageColumnsModal"}
            open={isManageColumnsActive}
            onClose={hideManageColumns}
            columnsToShow={columnsToShow}
            type={constants.entity_type.customers.value}
          />
        )}
        {showBulkUploadModal && (
          <BulkImportModal {...bulkImportSuccessModalProps} />
        )}
        {showImportAlreadyInProgress && (
          <BulkImportProgressModal {...bulkImportAlreadyInProgressModalProps} />
        )}
        {showBulkErrorModal && (
          <BulkImportProgressModal {...bulkImportErrorModalProps} />
        )}
        {isModalActive && (
          <CustomerTransactionsV2 open={isModalActive} onClose={hideModal} />
        )}
        <ExlyResponsesModal
          showResponsesModal={showResponsesModal}
          setShowResponsesModal={setShowResponsesModal}
        />
      </ThemeProvider>
    </div>
  );
};

export default withComponentLibraryTheme(AllCustomers);
