import { MOBILE_SCREEN_WIDTH } from "../../../constants/common/screen";
import { ICreatorTheme } from "../../../features/Common/modules/Theme/theme.interface";
import { getColorsFromCreatorTheme } from "../../../features/Common/modules/Theme/utils/Theme.utils";
import { getStylesObjFromCss } from "../../../utils/common/styling";

export const styles = getStylesObjFromCss({
  styles: {
    container: {
      backgroundColor: "white",
      [`@media(min-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
        borderRadius: "8px",
        border: "1px solid #E2E2E2",
      },
    },
    flaggedContainer: {
      [`@media(min-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
        borderColor: "#C0392B",
      },
    },
    header: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      [`@media(max-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
        padding: "8px 16px 0",
      },
      [`@media(min-width: ${MOBILE_SCREEN_WIDTH}px)`]: {
        padding: "16px 16px 0",
      },
    },
    postAuthorDetails: {
      display: "flex",
      gap: "10px",
    },
    postAuthorPic: {
      borderRadius: "50%",
      objectFit: "cover",
    },
    authorNameAndCreatedAtDateContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
    },
    authorName: {
      fontSize: "16px",
      fontWeight: 500,
    },
    authorNameClickable: {
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    authorIsCurrentUserIndicator: {
      color: "rgba(0, 0, 0, 0.40)",
      fontSize: "16px",
      fontWeight: 400,
    },
    createdAtDate: {
      fontSize: "14px",
      color: " rgba(0, 0, 0, 0.50)",
      fontWeight: 500,
    },
    pinAndMoreMenuBtnContainer: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    postTextContainer: {
      padding: "16px",
      wordBreak: "break-word",
    },
    postText: {
      whiteSpace: "pre-wrap",
      fontSize: "14px",
    },
    seeMoreButtonContainer: {
      position: "relative",
      display: "flex",
      flexDirection: "row-reverse",
    },
    seeMoreButton: {
      textAlign: "right",
      cursor: "pointer",
      backgroundColor: "white",
      position: "absolute",
      paddingLeft: "8px",
      bottom: "0px",
      color: "rgba(0, 0, 0, 0.5)",
      fontSize: "14px",
    },
    postDocContainer: {
      marginRight: "16px",
      marginLeft: "16px",
    },
    likeAndCommentCountContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "16px",
    },
    likeCountContainer: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      cursor: "pointer",
    },
    likesCountIconContainer: {
      width: "18px",
      height: "18px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
    },
    heartIcon: {
      fontSize: "10px !important",
    },
    likesCount: {
      fontWeight: "400",
      fontSize: "14px",
    },
    commentsCount: {
      fontSize: "14px",
      color: "rgba(39, 37, 34, 0.60) !important",
    },
    reactionStripContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "rgba(39, 37, 34, 0.7)",
      padding: "13px 16px",
      gap: "7px",
      borderTop: "1px solid #E7E6E4",
      fontSize: "14px",
    },
    verticalSeparator: {
      width: "1px",
      height: "20px",
      backgroundColor: "#E7E6E4",
    },
    reactionBtn: {
      cursor: "pointer",
      flexBasis: "0",
      flexGrow: "1",
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    justifyContentCenter: {
      justifyContent: "center",
    },
    justifyContentStart: {
      justifyContent: "flex-start",
    },
    showCommentInputIcon: {
      transform: "rotate3d(0, 1, 0, 180deg)",
    },
    replyTextAreaContainer: {
      padding: "7px 16px 16px",
    },
    commentSectionContainer: {
      padding: "0 16px 16px",
      overflow: "hidden",
    },
    flaggedTag: { marginRight: 7 },
  },
  componentName: "Post",
});

export const getThemedStyles = ({ theme }: { theme: ICreatorTheme }) => {
  const colors = getColorsFromCreatorTheme(theme);
  return getStylesObjFromCss({
    styles: {
      likesCountIconContainer: {
        backgroundColor: colors.color1 || "#493ab1",
      },
      likesCount: {
        color: colors.color1 || "#493ab1",
      },
      likedByUserIcon: {
        color: colors.color1 || "#493ab1",
      },
    },
    componentName: "Post",
  });
};
