import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { parse } from "query-string";
import { is_empty } from "features/Common/utils/common.utils";
import { isRequestSuccessful } from "utils/Utils";
import { ONBOARDING_APIS } from "features/Onboarding/utils/Onboarding.api";
import { WHATS_NEXT_APIS } from "../modules/WhatsNext/WhatsNext.api";
import { navigateTo, getRAUrlParams } from "utils/urlUtils";
import { whats_next_keys } from "../modules/WhatsNext/WhatsNext.constants";
import { app_route_keys } from "constants/urlPaths";
import { getWhatsNextInfo } from "features/Onboarding/modules/WhatsNext/utils/whatsNext.utils";
import { handleOnboardingQuestionsRedirection } from "../modules/OnboardingQuestions/utils/OnboardingQuestions.utils";
import {
  ONBOARDING_FLOW_KEY,
  ONBOARDING_ROUTE_KEYS,
} from "../constants/Onboarding.urlPaths";
import { THANKYOU_PAGE_VISIT_STATES } from "../modules/OnboardingThankyouScreen/constants/OnboardingThankyouScreen.constants";
import {
  getPermissions,
  handleLoginRedirect,
  setNextStepsInLocalStorage,
} from "utils/AuthUtil";
import { logError } from "utils/error";
import { orgPermissions } from "utils/OrgPermissions";
import { LOCAL_STORAGE_KEYS } from "features/Common/modules/Storage/modules/LocalStorage/constants/LocalStorage.constants";
import { getLocalStorageItem } from "features/Common/modules/Storage/modules/LocalStorage/utils/LocalStorage.utils";
import { URL_PARAMS_KEYS } from "features/Common/modules/Urls&Routing/constants/URL.constant";
import { appendUrlParams } from "utils/urlUtils";
import { isInitialChecklistCompleted } from "../modules/WhatsNext/modules/SetUpCheckList/utils/SetUpCheckList.utils";
import { SET_UP_FUNNEL_KEYS } from "../modules/WhatsNext/modules/SetUpCheckList/modules/SetUpFunnel/constants/SetUpFunnel.constants";
import { SET_UP_WEBSITE_KEYS } from "../modules/WhatsNext/modules/SetUpCheckList/modules/SetUpWebsite/constants/SetUpWebsite.constants";
import { CONNECT_BANK_ACCOUNT_KEYS } from "../modules/WhatsNext/modules/SetUpCheckList/modules/ConnectBankAccount/constants/ConnectBankAccount.constants";

/**
 * Fetches the onboarding questions data.
 *
 * @returns {Promise<{status: number, data: Object}>} The status and data of the response.
 */
export const getOnboardingData = async () => {
  const response = await dataProvider.custom_request(
    ONBOARDING_APIS.GET_DATA,
    apiMethods.GET
  );
  const { status, data } = response || {};

  return { status, data };
};

/**
 * Updates the onboarding details.
 *
 * @param {Object} params - The parameters for the update.
 * @param {Object} params.payload - The payload to be sent in the update request.
 * @returns {Promise<{data: Object, status: number, message: string}>} The data, status, and message of the response.
 * @throws Will throw an error if the request is unsuccessful.
 */
export const updateOnboardingData = async ({ payload }) => {
  if (is_empty(payload)) return;

  const response = await dataProvider.custom_request(
    ONBOARDING_APIS.UPDATE_DATA,
    apiMethods.POST,
    payload
  );
  const { data, status } = response || {};
  if (!isRequestSuccessful(status))
    throw new Error(`Error updating onboarding details`);

  return { data, status };
};

/**
 * Redirects the user based on their onboarding progress:
 *
 * - Redirects to the thank you page if it has not been visited.
 * - Redirects to the onboarding questions flow if questions are incomplete.
 * - Redirects main users who haven't completed all steps to the "what's next" page.
 * - Otherwise, handles login redirect.
 *
 * @param {Object} [options={}] - Configuration options for the redirection logic.
 * @param {boolean} [options.fallbackEnterCT=false] - If `true`, checks if the user has completed all steps and redirects to the "what's next" page; otherwise, performs a default redirection.
 * @param {boolean} [options.forceFetchProgressSteps=false] - If `true`, forces a fetch of the user's progress steps even if they are already stored in local storage.
 * @param {boolean} [options.fetchAll=false] - If `true`, fetches all progress steps data regardless of what is in local storage.
 *
 * @returns {Promise<void>} Resolves when the redirection logic is executed.
 * @critical : Please discuss with Muskan/Dhruva before using or updating this function, as it controls ther navigation flow throughout CT based on creator's onboarding stage
 */
export async function redirectBasedOnCreatorOnboardingStage({
  fallbackEnterCT = false,
  forceFetchProgressSteps = false,
  fetchAll = false,
} = {}) {
  const isBdLogin =
    getLocalStorageItem(LOCAL_STORAGE_KEYS.BD_LOGIN) ||
    getLocalStorageItem(LOCAL_STORAGE_KEYS.IS_STAFF);

  const urlParams = getRAUrlParams();
  const nextURL = urlParams.get(URL_PARAMS_KEYS.next);

  // get keys from localstorage
  let steps = getWhatsNextInfo();
  // following are the required onboarding keys
  const keys = [
    whats_next_keys.plan_thank_you_visit_state.key,
    whats_next_keys.is_onboarded.key,
    whats_next_keys.has_completed_all_steps.key,
    whats_next_keys.has_double_transacted.key,
    whats_next_keys.has_leads.key,
    ...Object.values(SET_UP_FUNNEL_KEYS),
    ...Object.values(SET_UP_WEBSITE_KEYS),
    ...Object.values(CONNECT_BANK_ACCOUNT_KEYS),
  ];
  // check if any key is missing from localstorage
  const missingKey = keys.find((key) => !(key in steps));
  try {
    // if any key is missing or forceFetchProgressSteps is true -> call whats_next_ api
    if (missingKey || forceFetchProgressSteps) {
      const progressSteps = await dataProvider.custom_request(
        WHATS_NEXT_APIS.GET_PROGRESS,
        apiMethods.GET,
        {
          request_params: JSON.stringify(fetchAll ? [] : keys),
        }
      );
      if (isRequestSuccessful(progressSteps.status)) {
        steps = progressSteps.data;
        setNextStepsInLocalStorage({ data: progressSteps.data });
      }
    }

    if (isBdLogin) {
      handleLoginRedirect();
      return;
    }

    if (nextURL) {
      handleLoginRedirect();
      return;
    }

    if (steps) {
      if (
        steps?.[whats_next_keys.plan_thank_you_visit_state.key] ===
        THANKYOU_PAGE_VISIT_STATES.PENDING
      ) {
        // if thank page visit state is pending -> navigate to thankyou page
        navigateTo(ONBOARDING_ROUTE_KEYS.ONBOARDING_THANKYOU_PAGE);
      } else if (steps?.is_onboarded === false) {
        // if is_onboarding is false -> navigate to onboarding questions
        if (!window.location.href.includes(ONBOARDING_FLOW_KEY))
          handleOnboardingQuestionsRedirection();
      } else if (
        (!isInitialChecklistCompleted() ||
          !steps?.[whats_next_keys.has_leads.key] ||
          !steps?.[whats_next_keys.has_double_transacted.key]) &&
        fallbackEnterCT
      ) {
        // We pass fallbackEnterCT: true to ensure that if the user has not completed all the onboarding steps
        // (i.e., `has_completed_all_steps` is false) or if there is an issue determining the user's onboarding state,
        // the function will attempt to handle the user entry into CT by fetching permissions and redirecting based on user role.
        // This fallback mechanism allows the user to proceed to the main application flow (`whats_next` page)
        // if they are the main user, or handle login redirection if they are not, ensuring a seamless transition
        // even if some onboarding checks fail or are incomplete.
        await getPermissions();
        const isMainUser = await orgPermissions.isMainUserAsync();
        if (isMainUser) {
          navigateTo(app_route_keys.whats_next);
        } else {
          handleLoginRedirect();
        }
      } else if (!fallbackEnterCT) {
        return;
      } else {
        handleLoginRedirect();
      }
    } else {
      handleLoginRedirect();
    }
  } catch (error) {
    logError({
      error,
      occuredAt:
        "redirectBasedOnCreatorOnboardingStage in src/features/Onboarding/utils/Onboarding.utils.js",
      when: "Error fetching progress steps:",
    });
    handleLoginRedirect();
  }
}

/**
 * Redirects the user to the login page if they attempt to access a private route without being logged in.
 * The login page will include a `next` parameter, which specifies the page the user was trying to access.
 * After successful login, the user will be redirected back to the intended page.
 * @param {string} [params] - Optional query parameters to append to the login URL in addition to `next`.
 *                            These can be any additional data such as session info or state.
 * @example : /mySchedule?listing_uuid=”listing_uuid” is tried to access without logging in , it will throw to login page and after login , the user will land upon intended page.
 */
export const handleRedirectToLoginPage = (params) => {
  let queryObject = {};

  if (!is_empty(params)) {
    queryObject = parse(params);
  }

  queryObject = {
    next: encodeURIComponent(window.location.href),
    ...queryObject,
  };

  window.location.href = appendUrlParams(
    `${window.location.origin}/#/`,
    queryObject
  );
};
