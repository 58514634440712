import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ReportGmailerrorredRoundedIcon from "@mui/icons-material/ReportGmailerrorredRounded";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Button } from "@mui/material";
import React, { MouseEventHandler } from "react";
import { useCreatorThemeContext } from "../../../../../features/Common/modules/Theme/CreatorThemeContext";
import { COMMENT_STATUS } from "../../../../../features/Offerings/modules/RecordedContent/CommentSection/CommentSection.data";
import Popover from "../../../../common/Popover/Popover";
import { getThemedStyles, styles } from "./MoreButtonAndMenu.styles";
import { IMoreButtonAndMenuProps } from "./MoreButtonAndMenu.types";

const MoreButtonAndMenu = ({
  MoreButtonComponent,
  canEdit,
  onEditClick,
  canDelete,
  onDeleteClick,
  currentCommentStatus,
  canHideUnhide,
  onHideUnhideClick,
  canReport,
  onReport,
  reportCtaText = "Report post",
}: IMoreButtonAndMenuProps) => {
  if (!canEdit && !canDelete && !canHideUnhide && !canReport) return null;
  const theme = useCreatorThemeContext();
  const themedStyles = getThemedStyles({ theme });

  const isCurrentCommentHidden = currentCommentStatus == COMMENT_STATUS.HIDDEN;

  return (
    <Popover
      AnchorComponent={({ openPopover }) => (
        <MoreButtonComponent
          className={styles.moreButton}
          onClick={openPopover}
          fontSize="small"
        />
      )}
      PopoverComponent={({ closePopover }) => {
        const getClickHandler =
          (callback?: () => void): MouseEventHandler =>
          (e) => {
            if (callback) callback();
            closePopover(e);
          };

        const actions = [
          {
            id: 1,
            label: "Edit",
            icon: <EditOutlinedIcon className={themedStyles.editIcon} />,
            onClick: getClickHandler(onEditClick),
            show: canEdit,
          },
          {
            id: 2,
            label: "Unhide",
            icon: isCurrentCommentHidden ? (
              <VisibilityOutlinedIcon />
            ) : (
              <VisibilityOffOutlinedIcon />
            ),
            onClick: getClickHandler(
              () =>
                onHideUnhideClick?.({
                  status: isCurrentCommentHidden
                    ? COMMENT_STATUS.SHOWN
                    : COMMENT_STATUS.HIDDEN,
                }),
            ),
            show: canHideUnhide,
          },
          {
            id: 3,
            label: "Delete",
            icon: <DeleteRoundedIcon />,
            onClick: getClickHandler(onDeleteClick),
            show: canDelete,
            className: styles.deteleCta,
          },
          {
            id: 4,
            label: reportCtaText,
            icon: <ReportGmailerrorredRoundedIcon />,
            onClick: getClickHandler(onReport),
            show: canReport,
            className: styles.reportCta,
          },
        ].filter((cta) => cta.show);

        return (
          <div className={styles.moreMenuContainer}>
            {actions.map(({ id, icon, label, className, onClick }, index) => (
              <div key={id}>
                {index > 0 && <div className={styles.divider} />}

                <Button
                  className={className}
                  startIcon={icon}
                  onClick={onClick}
                  fullWidth
                >
                  {label}
                </Button>
              </div>
            ))}
          </div>
        );
      }}
      popoverProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      }}
    />
  );
};

export default MoreButtonAndMenu;
