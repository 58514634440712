import { RTE_TOOLBAR_MARKS } from '../../constants/RTEToolbar.marks';
import { MarkToolButton } from '../MarkToolButton/MarkToolButton';
import { useStyles } from './MonospaceTool.styles';

export const MonospaceTool = () => {
  const classes = useStyles();
  return (
    <MarkToolButton
      className={classes.root}
      markFormat={RTE_TOOLBAR_MARKS.monospace}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
      >
        <path
          d="M9.90156 14.6663H7.9849L7.2099 12.2496H3.70156L2.97656 14.6663H1.10156L4.5099 3.97461H6.3849L9.90156 14.6663ZM6.66823 10.4413L5.4349 6.47461L4.24323 10.4413H6.66823Z"
          fill="black"
        />
        <path
          d="M11.7753 14.6663L10.1836 3.97461H11.5669L12.5586 11.3329L13.7753 3.98294H15.3753L16.5419 11.4829L17.5669 3.98294H18.9169L17.3169 14.6663H15.8836L14.5503 6.66628L13.2336 14.6663H11.7753Z"
          fill="black"
        />
        <path
          d="M9.4651 15.458V16.6163H1.57344V15.458H1.14844V17.0497H1.3651H1.57344H9.4651H9.8901V15.458H9.4651Z"
          fill="black"
        />
        <path
          d="M18.4768 15.5V16.6583H10.5768V15.5H10.1602V17.0917H10.3768H10.5768H18.4768H18.8935H18.9018V15.5H18.4768Z"
          fill="black"
        />
      </svg>
    </MarkToolButton>
  );
};
