import { FC, useMemo } from 'react';
import { Slate } from 'slate-react';
import { createSlateEditor } from '../../../RichTextEditorV2/plugins';
import { TYPES } from '../../../RichTextEditorV2/utils/SlateUtilityFunctions';
import { IRTEContext } from './RTEContext.interfaces';

const EmptyState = [{ type: TYPES.PARAGRAPH, children: [{ text: '' }] }];

export const RTEContext: FC<IRTEContext> = ({
  children,
  initialValue = EmptyState,
  onChange,
}) => {
  const editor = useMemo(() => createSlateEditor(), []);

  return (
    <Slate editor={editor} initialValue={initialValue} onChange={onChange}>
      {children}
    </Slate>
  );
};
