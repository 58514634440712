export const SUBSCRIPTION_STATUSES = {
  ACTIVE: 1,
  DUE: 2,
  OVERDUE: 3,
  EXPIRED: 4,
};

export const SUBSCRIPTION_STATUS_MAP = {
  [SUBSCRIPTION_STATUSES.ACTIVE]: {
    title: "Active",
    className: "active_status",
  },
  [SUBSCRIPTION_STATUSES.DUE]: {
    title: "Due",
    className: "due_status",
  },
  [SUBSCRIPTION_STATUSES.OVERDUE]: {
    title: "Overdue",
    className: "over_due_status",
  },
  [SUBSCRIPTION_STATUSES.EXPIRED]: {
    title: "Expired",
    className: "inactive_status",
  },
};
