import { Editor, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { RTEUtils } from './RTEUtils';

export const useRTE = () => {
  const editor = useSlate();

  const focus = (focusDelay?: number) => {
    if (typeof focusDelay !== 'undefined')
      setTimeout(() => {
        ReactEditor.focus(editor as ReactEditor);
      }, focusDelay);
  };

  const addText = (text: string) => {
    if (text) {
      const { selection: newSelection } = editor;

      // if the editor is not focused or there's no active selection
      if (!newSelection) {
        // set the selection to the start of the document
        Transforms.select(editor, {
          anchor: { path: [0, 0], offset: 0 },
          focus: { path: [0, 0], offset: 0 },
        });
      }

      Editor.insertText(editor, text);
    }
  };

  const resetEditor = ({ focusDelay }: { focusDelay?: number } = {}) => {
    Transforms.delete(editor, {
      at: {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
      },
    });
    Transforms.removeNodes(editor, {
      at: [0],
    });
    Transforms.insertNodes(editor, RTEUtils.emptyEditorState);

    focus(focusDelay);
  };

  return { addText, resetEditor, focus };
};
