import { format, isToday, isYesterday } from "date-fns";
import {
  MONTH_DAY_YEAR_FORMAT,
  YEAR_MONTH_DAY_FORMAT,
} from "features/Common/modules/DateTime/DateTime.constants";
import { CREATED_AT, CRM_ASSETS_RELATIVE_PATH } from "../Crm.constants";
import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";
import {
  PER_PAGE_OPTIONS_100,
  PER_PAGE_OPTIONS_250,
  PER_PAGE_OPTIONS_50,
} from "features/Common/constants/ExlyTableConstants";
import { is_empty } from "features/Common/utils/common.utils";

export const groupDataByDate = ({ data = [], dateKey = CREATED_AT }) => {
  const groupedData = {};

  // Iterate over the input data
  data.forEach((item) => {
    const date = format(new Date(item[dateKey]), YEAR_MONTH_DAY_FORMAT); // Extract the date part only

    // Check if the date key exists in the groupedData object
    if (!groupedData[date]) {
      // If not, create a new array for this date
      groupedData[date] = [];
    }

    // Push the entire item to the corresponding date's array
    groupedData[date].push(item);
  });

  return groupedData;
};

export const getLastElementUniqueId = ({ data }) => {
  const [firstElement] = data;

  return firstElement?.uuid;
};

export const getDisplayDate = ({ date }) => {
  if (!date) return "";

  const dateObject = new Date(date);

  if (isToday(dateObject)) {
    return "Today";
  } else if (isYesterday(dateObject)) {
    return "Yesterday";
  } else {
    return format(dateObject, MONTH_DAY_YEAR_FORMAT);
  }
};

export const getCrmAsset = (key) => {
  return getS3AssetCDNUrl(`${CRM_ASSETS_RELATIVE_PATH}/${key}`);
};

export const getPerPageOptions = ({ customColumnsToRender }) => {
  return [
    PER_PAGE_OPTIONS_50,
    ...(is_empty(customColumnsToRender)
      ? [PER_PAGE_OPTIONS_100, PER_PAGE_OPTIONS_250]
      : []),
  ];
};
