import { CallMade } from "@material-ui/icons";
import React from "react";
import styles from "./UrlFieldEndAdornment.module.css";
import classnames from "classnames";

export const UrlFieldEndAdornment = ({ value }) => {
  const disabled = !value;

  const handleAdornmentClick = (event) => {
    event.preventDefault();
    window.open(value, "_blank");
  };
  return (
    <div
      className={classnames(
        styles.iconWrapper,
        disabled && styles.disabledIconWrapper
      )}
      onMouseDown={handleAdornmentClick} // Prevent blur here
    >
      <CallMade
        className={classnames(styles.icon, disabled && styles.disabledIcon)}
      />
    </div>
  );
};
