import React, { useEffect, useState } from "react";
import styles from "./SomethingWentWrong.module.css";
import { Button } from "@my-scoot/component-library-legacy";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { SESSION_STORAGE_KEYS } from "features/Common/modules/Storage/modules/SessionStorage/constants/SessionStorage.constant";
import { removeSessionStorageItem } from "features/Common/modules/Storage/modules/SessionStorage/utils/SessionStorage.utils";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { isChunkMissingError } from "features/Common/modules/Deployment/utils/Deployment.utils";
import { logError } from "utils/error";
import { setSessionStorageItem } from "utils/getterAndSetters";
import posthog from "posthog-js";

export const SomethingWentWrong = withComponentLibraryTheme((props) => {
  const [showPage, setShowPage] = useState(false);

  useEffect(() => {
    (async () => {
      const errorMsg = props?.error?.message;
      const from = window.location.href;
      const errorObj = {
        errorMsg,
        errorStack: props?.error?.stack,
        errorInfo: props?.errorInfo,
        from,
      };
      setSessionStorageItem(
        SESSION_STORAGE_KEYS.SOMETHING_WENT_WRONG_ERROR,
        errorObj
      );
      logError({
        error: JSON.stringify(props),
      });

      // if error is of chunk missing and new deployment has occured then only refresh
      const chunkMissing = await isChunkMissingError(errorMsg);
      // eslint-disable-next-line no-console
      console.log({ chunkMissing }); // to be removed after 31/01/25

      if (chunkMissing) {
        window.location.reload();
      } else {
        posthog.capture("something_went_wrong", errorObj);
        setShowPage(true);
      }
    })();

    return () => {
      removeSessionStorageItem(SESSION_STORAGE_KEYS.SOMETHING_WENT_WRONG_ERROR);
    };
  }, []);

  const goBack = () => {
    const errorMsg = props?.error?.message;
    window.history.go(-1);
    if (errorMsg.toLowerCase().includes("chunk")) {
      window.location.reload();
    }
  };

  return (
    <>
      {showPage ? (
        <div className={styles.container}>
          <ErrorOutline className={`${styles.erroricon} mb-4`} />
          <h1 className={styles.heading}>Oops! Something Went Wrong</h1>
          <p className={styles.message}>
            We&apos;re sorry, but something went wrong. Please try refreshing
            the page or come back later.
          </p>
          <Button className={`mt-4`} onClick={goBack}>
            Go back
          </Button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
});
