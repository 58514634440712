import {
  WEBPAGE_LEADS_ROUTE_IDS,
  WEBPAGE_LEADS_ROUTE_KEYS,
} from "../WebpageLeads.constants";

export const WEBPAGE_LEADS_RESPONSE_API = {
  [WEBPAGE_LEADS_ROUTE_IDS[WEBPAGE_LEADS_ROUTE_KEYS.WEBPAGE_LEADS_LIST]]: ({
    listData,
  }) => {
    return {
      data: listData.map((i) => ({ ...i, lead_id: i.id, id: i.uuid })),
    };
  },
};
