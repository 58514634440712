import { custom_column_types } from "ui/pages/schedule/BookedSession/bookedSession.constants";
import { MultipleSelectDropdownField } from "../../TableFieldsMapping/MultipleSelectDropdownField/MultipleSelectDropdownField";
import { SingleSelectDropdownField } from "../../TableFieldsMapping/SingleSelectDropdownField/SingleSelectDropdownField";
import { indian_cities_dropdown_options } from "features/Common/constants/indianCity";
import { indian_states_and_uts_dropdown_options } from "features/Common/constants/indianState";
import { ShortTextField } from "../../TableFieldsMapping/ShortTextField/ShortTextField";
import { UrlField } from "../../TableFieldsMapping/UrlField/UrlField";
import { DateField } from "../../TableFieldsMapping/DateField/DateField";
import { TimeField } from "../../TableFieldsMapping/TimeField/TimeField";
import { LongTextField } from "../../TableFieldsMapping/LongTextField/LongTextField";
import { AssignMemberDropdownField } from "../../TableFieldsMapping/AssignMemberDropdownField/AssignMemberDropdownField";
import { GENDER_OPTIONS } from "features/Common/constants/genderConstants";
import {
  CUSTOM_COLUMN_DATA_TYPE,
  ORG_STAFF_EMPTY_OPTION,
  SINGLE_SELECT_EMPTY_OPTION,
} from "../constants/CustomColumn.constants";

export const getCustomColumnTableRenderConfig = ({
  type = CUSTOM_COLUMN_DATA_TYPE.SHORT_TEXT,
  orgMembers = [],
  columnOptions = [],
  isDesktop = false,
}) => {
  const columnOptionsMapping = columnOptions.map((city, idx) => ({
    id: `${city}_${idx}`,
    label: city,
    value: city,
  }));
  switch (type) {
    case custom_column_types.organisation_type.value: // assignee
      return {
        Component: AssignMemberDropdownField,
        filterType: "dropdown",
        disableContains: true,
        options: [ORG_STAFF_EMPTY_OPTION, ...orgMembers],
      };
    case CUSTOM_COLUMN_DATA_TYPE.SINGLE_SELECT:
      return {
        Component: SingleSelectDropdownField,
        filterType: "dropdown",
        disableContains: true,
        options: [SINGLE_SELECT_EMPTY_OPTION, ...columnOptionsMapping],
        columnWidth: "198px",
      };
    case CUSTOM_COLUMN_DATA_TYPE.MULTIPLE_SELECT:
      return {
        Component: MultipleSelectDropdownField,
        filterType: "dropdown",
        disableContains: true,
        options: columnOptionsMapping,
        columnWidth: "198px",
      };
    case CUSTOM_COLUMN_DATA_TYPE.GENDER:
      return {
        Component: SingleSelectDropdownField,
        filterType: "dropdown",
        disableContains: true,
        options: [SINGLE_SELECT_EMPTY_OPTION, ...Object.values(GENDER_OPTIONS)],
      };
    case CUSTOM_COLUMN_DATA_TYPE.INDIAN_CITY:
      return {
        Component: SingleSelectDropdownField,
        filterType: "dropdown",
        disableContains: true,
        options: [
          SINGLE_SELECT_EMPTY_OPTION,
          ...indian_cities_dropdown_options,
        ],
      };
    case CUSTOM_COLUMN_DATA_TYPE.INDIAN_STATE:
      return {
        Component: SingleSelectDropdownField,
        filterType: "dropdown",
        disableContains: true,
        options: [
          SINGLE_SELECT_EMPTY_OPTION,
          ...indian_states_and_uts_dropdown_options,
        ],
      };
    case CUSTOM_COLUMN_DATA_TYPE.SHORT_TEXT:
      return {
        Component: ShortTextField,
        filterType: "input",
        disableContains: true,
        columnWidth: "255px",
        inputClass: isDesktop && "mt-12 mb-12",
      };

    case CUSTOM_COLUMN_DATA_TYPE.LONG_TEXT:
      return {
        Component: LongTextField,
        filterType: "input",
        disableContains: true,
        columnWidth: "255px",
        inputClass: isDesktop && "mt-12 mb-12",
      };
    case CUSTOM_COLUMN_DATA_TYPE.DATE:
      return {
        Component: DateField,
        filterType: "date",
        disableContains: true,
        columnWidth: "155px",
      };
    case CUSTOM_COLUMN_DATA_TYPE.TIME:
      return {
        Component: TimeField,
        filterType: "time",
        disableContains: true,
        columnWidth: "160px",
      };
    case CUSTOM_COLUMN_DATA_TYPE.URL:
      return {
        Component: UrlField,
        filterType: "input",
        disableContains: true,
        columnWidth: "255px",
      };

    default:
      return {
        Component: ShortTextField,
        filterType: "input",
        disableContains: true,
        columnWidth: "255px",
        inputClass: isDesktop && "mt-12 mb-12",
      };
  }
};

export const formatCustomChip = (customChipsToFormat, filter, value) => {
  const columnToFilter = customChipsToFormat?.find(
    (chipToFormat) =>
      chipToFormat.columnSlug === filter ||
      `${chipToFormat.columnSlug}__icontains` === filter
  );

  if (columnToFilter) {
    const staff = columnToFilter?.options?.find((item) => item.id == value);
    return `${columnToFilter?.columnName}: ${staff?.label || value}`;
  }

  return null;
};

export const transformCustomColumns = ({ customColumnsToRender = {} } = {}) => {
  return customColumnsToRender.reduce((acc, item) => {
    acc[item.field] = item;
    return acc;
  }, {});
};

/**
 * Combines two arrays of column configurations by merging details from one array into another
 * based on matching `uuid` properties.
 *
 * @param {Object} params - The parameters object.
 * @param {Array} params.customColumnsFromDetails - Array of custom column details containing `uuid` and additional data.
 * @param {Array} params.allCustomColumns - Array of all available custom columns to be enriched.
 * @returns {Array} A new array where each object from `allCustomColumns` is merged with matching
 *                  details from `customColumnsFromDetails` if a `uuid` match is found.
 **/
export const generateCustomColumnsDetailsConfig = ({
  customColumnsFromDetails = [],
  allCustomColumns = [],
}) => {
  const lookupMap = customColumnsFromDetails.reduce((map, item) => {
    map[item.uuid] = item;
    return map;
  }, {});

  // Step 2: Map over allCustomColumns and append the value from the lookup map
  const combinedArray = allCustomColumns.map((item1) => ({
    ...item1,
    ...(lookupMap[item1.uuid] || {}), // Add value if exists, otherwise undefined
  }));
  return combinedArray || [];
};
