import { FC } from 'react';
import { useSlate } from 'slate-react';
import {
  isMarkActive,
  toggleMark,
} from '../../../../../RichTextEditorV2/utils/SlateUtilityFunctions';
import { ToolbarButton } from '../ToolbarButton/ToolbarButton';
import { IToolbarButton } from '../ToolbarButton/ToolbarButton.interfaces';

export const MarkToolButton: FC<{ markFormat: string } & IToolbarButton> = ({
  children,
  markFormat,
  ...btnProps
}) => {
  const editor = useSlate();

  const handleToggleBold: IToolbarButton['onMouseDown'] = (e) => {
    e.preventDefault();
    toggleMark(editor, markFormat);
  };

  return (
    <ToolbarButton
      active={isMarkActive(editor, markFormat)}
      onMouseDown={handleToggleBold}
      {...btnProps}
    >
      {children}
    </ToolbarButton>
  );
};
