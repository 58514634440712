export const PER_PAGE_OPTIONS_50 = {
  id: "50",
  label: "50",
  value: "50",
};

export const PER_PAGE_OPTIONS_100 = {
  id: "100",
  label: "100",
  value: "100",
};

export const PER_PAGE_OPTIONS_250 = {
  id: "250",
  label: "250",
  value: "250",
};
