import React, { useEffect, useState } from "react";
import { setSidebarVisibility } from "react-admin";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { BottomNavigation } from "@my-scoot/component-library-legacy";

import useStyles from "./ExlyMobileNavigation.styles";
import { getTabConfig } from "./ExlyMobileNav.config";
import { useDispatch, useSelector } from "react-redux";
import {
  UI_LAYOUT__DECREMENT_ACTIVE_TOOL_TIP_INDEX,
  UI_LAYOUT__INCREMENT_ACTIVE_TOOL_TIP_INDEX,
} from "features/UILayout/redux/UILayout.actions";
import styles from "features/UILayout/modules/Menu/MenuStyles.module.css";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { OnboardingTooltipFooter } from "features/UILayout/modules/Menu/modules/OnboardingTooltipFooter/OnboardingTooltipFooter";
import { ShowTourSuggestionBackdrop } from "features/Onboarding/modules/WhatsNext/modules/ShowTourSuggestionBackdrop/ShowTourSuggestionBackdrop";
import { useOnboardingTooltip } from "features/UILayout/modules/Menu/modules/OnboardingTooltip/utils/useOnboardingTooltip";

const ExlyMobileNavigation = (props) => {
  const [value, setValue] = useState(0);
  const [permissions, setPermissions] = useState(null);
  const [tabs, setTabs] = useState([]);
  const classes = useStyles();
  const history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const selectedTab = tabs.find((tab) => tab.path === location?.pathname);
    if (selectedTab && Object.keys(selectedTab).length !== -1) {
      setValue(tabs.indexOf(selectedTab) + 1);
    }
  }, [location.pathname, tabs]);

  useEffect(() => {
    let menuPermissions = sessionStorage.getItem("menu_permissions");

    if (menuPermissions) {
      let permissions = JSON.parse(menuPermissions);
      setPermissions(permissions.hidden_items);
    }
  }, []);

  useEffect(() => {
    const protectedTabs = getTabConfig(permissions);
    setTabs(protectedTabs);
  }, [permissions]);

  const onChange = (e, newValue) => {
    const newPath = tabs?.[newValue - 1]?.path;
    if (!newPath) return;
    history.push(newPath);
    setValue(newValue);
  };

  const {
    isToolTipTourActive,
    visibleConfig: config,
    activeTooltipIndex,
    showTourSuggestion,
  } = useSelector((state) => state.uiLayout);

  const { endOnboardingTooltipTour } = useOnboardingTooltip();

  const toggleMenu = ({ value }) => {
    dispatch(setSidebarVisibility(value));
  };

  const onPrev = () =>
    dispatch({ type: UI_LAYOUT__DECREMENT_ACTIVE_TOOL_TIP_INDEX });

  const onNext = () => {
    dispatch({ type: UI_LAYOUT__INCREMENT_ACTIVE_TOOL_TIP_INDEX });
    if (config[activeTooltipIndex] === tabs?.[tabs?.length - 1]?.id)
      toggleMenu({ value: true });
  };

  return (
    <>
      <BottomNavigation
        items={tabs}
        fixed_bottom
        value={value}
        onChange={onChange}
        className={classes.bottomNavigation}
        open={isToolTipTourActive}
        onClose={endOnboardingTooltipTour}
        customFooter={
          <OnboardingTooltipFooter
            onNext={onNext}
            onPrev={onPrev}
            hidePrevBtn={config[activeTooltipIndex] === tabs?.[1]?.id}
          />
        }
        tooltipClasses={{
          popper: isToolTipTourActive
            ? styles.interactiveToolTipPopperBottomNavigation
            : styles.unsetInteractiveToolTipPopper,
        }}
        tooltipRootClassname={styles.tooltipContainer}
        activeId={config[activeTooltipIndex]}
        {...props}
      >
        <ShowTourSuggestionBackdrop
          backdropClass={
            showTourSuggestion && styles.overrideMobileNavBackdropStyle
          }
          showBackdrop={isToolTipTourActive}
        />
      </BottomNavigation>
    </>
  );
};

export default withComponentLibraryTheme(ExlyMobileNavigation);
