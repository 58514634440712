export const indian_cities = [
  "Agwar",
  "Ahmedabad",
  "Alawalpur",
  "Aligarh",
  "Ambattur",
  "Amritsar",
  "Arshakunti",
  "Aurangabad",
  "Babura",
  "Baj Baj",
  "Balasore",
  "Bangalore",
  "Belgharia",
  "Bendravadi",
  "Bhayandar",
  "Bhopal",
  "Bhalswa Jahangirpur",
  "Bhatpara",
  "Bilaspur",
  "Bommayapalaiyam",
  "Byatarayanpur",
  "Bagalur",
  "Bali",
  "Bansbaria",
  "Barakpur",
  "Cawnpore",
  "Chakapara",
  "Chandannagar",
  "Chandigarh",
  "Chennai",
  "Chinchvad",
  "Chinnasekkadu",
  "Cachohalli",
  "Dam Dam",
  "Darbhanga",
  "Devanandapur",
  "Dhanbad",
  "Doddajivanhalli",
  "Dasarhalli",
  "Faridabad",
  "Ghaziabad",
  "Gundur",
  "Guwahati",
  "Gwalior",
  "Gyan Chak",
  "Harchandi",
  "Hariladih",
  "Harna Buzurg",
  "Herohalli",
  "Hesarghatta",
  "Hugli",
  "Hunasamaranhalli",
  "Huttanhalli",
  "Hyderabad",
  "Haora",
  "Hirapur",
  "Indore",
  "Jabalpur",
  "Jaipur",
  "Jamshedpur",
  "Jethuli",
  "Jodhpur",
  "Jalhalli",
  "Kadabgeri",
  "Kalamboli",
  "Kammanhalli",
  "Kasgatpur",
  "Kedihati",
  "Kendraparha",
  "Kitanelli",
  "Kodagihalli",
  "Kolkata",
  "Kota",
  "Krishnanagar",
  "Kadiganahalli",
  "Kalkaji Devi",
  "Kukatpalli",
  "Lucknow",
  "Ludhiana",
  "Madhavaram",
  "Madipakkam",
  "Madurai",
  "Mahuli",
  "Mailanhalli",
  "Manali",
  "Meerut",
  "Mirchi",
  "Mirzapur",
  "Mumbai",
  "Muzaffarpur",
  "Madavar",
  "Madnaikanhalli",
  "Nagtala",
  "Najafgarh",
  "Nanmangalam",
  "Nathupur",
  "Nerkunram",
  "New Delhi",
  "Nagpur",
  "Nangloi Jat",
  "Narayanpur Kola",
  "Nasik",
  "Oulgaret",
  "Pakri",
  "Pallavaram",
  "Patna",
  "Prayagraj",
  "Puducherry",
  "Pune",
  "Punadih",
  "Puri",
  "Pappakurichchi",
  "Raipur",
  "Rajkot",
  "Ranchi",
  "Sabalpur",
  "Saino",
  "Salt Lake City",
  "Salua",
  "Santoshpur",
  "Secunderabad",
  "Shekhpura",
  "Shimla",
  "Shrirampur",
  "Sijua",
  "Simli Murarpur",
  "Sondekoppa",
  "Sonnappanhalli",
  "Sonudih",
  "Sonawan",
  "Srinagar",
  "Sultanpur",
  "Sultanpur Mazra",
  "Surat",
  "Taliganja",
  "Tarchha",
  "Thane",
  "Tiruvottiyur",
  "Titagarh",
  "Tribeni",
  "Vadodara",
  "Vasai",
  "Vijayavada",
  "Vishakhapatnam",
  "Vajarhalli",
  "Varanasi",
  "Yelahanka",
  "Zeyadah Kot",
  "Agra",
];

export const indian_cities_dropdown_options = indian_cities.map(
  (city, idx) => ({
    id: `${city}_${idx}`,
    label: city,
    value: city,
  })
);
