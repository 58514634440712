import React from "react";
import classes from "./CustomColumnetailsFieldValueMapperStyles.module.css";

const CustomColumnDetailsFieldValueMapper = ({ data, columns }) => {
  return (
    <>
      {columns?.map((item) => {
        return (
          <div className={classes.row} key={item.column_slug}>
            <div className={classes.row_title}>{item.headerName} :</div>
            <div className={classes.row_value}>{item.valueFormatter(data)}</div>
          </div>
        );
      })}
    </>
  );
};

export default CustomColumnDetailsFieldValueMapper;
