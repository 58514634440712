import React, { useEffect } from "react";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Button,
  Modal,
  MobileModal,
  Textarea,
} from "@my-scoot/component-library-legacy";

import { getAnswerText, getS3SignedUrl } from "utils/Utils";
import dataProvider from "data/dataProvider";
import { is_empty } from "utils/validations";
import api from "data/APIs";

import useStyles from "./bookingQuestions.styles";
import { useNotifications } from "utils/hooks";
export const BookingQuestions = ({ data, type, hideModal, title, isOpen }) => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });
  const { pushNotification } = useNotifications();
  const [apiData, setApiData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (!data) return;
    if (type === "lead") {
      let query_params;
      if (!is_empty(data.email)) {
        query_params.email = data.email;
      } else {
        query_params = {
          phone_number: data.phone_number,
          country_code: data.country_code.substring(1),
        };
      }

      dataProvider
        .custom_request(api.lead_answers, "GET", {
          ...query_params,
        })
        .then(
          (response) => response.status === 200 && setApiData(response.data)
        )
        .catch(() => {
          hideModal();
          pushNotification("An error occurred.", {
            variant: "outlined",
            color: "coral_red",
          });
          setApiData([]);
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }
    dataProvider
      .custom_request(api.transaction_answers, "GET", {
        transaction_uid: data.transaction_uuid,
        username: data.username,
      })
      .then((response) => response.status === 200 && setApiData(response.data))
      .catch(() => {
        hideModal();
        pushNotification("An error occurred.", {
          variant: "outlined",
          color: "coral_red",
        });
        setApiData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [data]);

  const onDownloadFile = async (item) => {
    if (is_empty(item.text_answer)) return;
    const url = await getS3SignedUrl(item.text_answer);
    if (!is_empty(url)) window.open(url);
  };

  const content = (
    <div className={classes.bookingQuestionsWrapper}>
      {loading ? (
        <CircularProgress size={30} color="primary" />
      ) : (
        <>
          {apiData?.map((item, idx) =>
            item.question_type === 5 ? (
              <div
                className={classes.qna_input_wrapper}
                // eslint-disable-next-line react/no-array-index-key
                key={`${item.question_type}_${idx}`}
              >
                <div className={classes.qna_file_label}>{`Q${idx + 1}. ${
                  item.question_text
                }`}</div>
                <Button size="small" onClick={() => onDownloadFile(item)}>
                  View file
                </Button>
              </div>
            ) : (
              <Textarea
                // eslint-disable-next-line react/no-array-index-key
                key={`${item.question_type}_${idx}`}
                label={`Q${idx + 1}. ${item.question_text}`}
                placeholder="N/A"
                value={getAnswerText(item)}
                size="small"
                disabled={true}
                fullWidth={true}
                wrapperClassName={classes.qna_input_wrapper}
              />
            )
          )}
        </>
      )}
    </div>
  );

  return isDesktop ? (
    <Modal open={isOpen} title={title} onClose={hideModal} customFooter={<></>}>
      {content}
    </Modal>
  ) : (
    <MobileModal
      open={isOpen}
      onClose={hideModal}
      header={title}
      customFooter={<></>}
    >
      {content}
    </MobileModal>
  );
};
