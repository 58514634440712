import {
  getS3SignedUrl as _getS3SignedUrl,
  // uploadToS3 as _uploadToS3,
} from "@my-scoot/exly-react-component-lib/build/cjs/features/Common/modules/S3/utils/S3.utils";
import {
  EXPIRY_TIME,
  url_required_for_keys,
  GET_SIGNED_URL_API,
} from "@my-scoot/exly-react-component-lib/build/cjs/features/Common/modules/S3/constants/S3.constants";
import axios from "axios";
import { is_empty } from "features/Common/utils/common.utils";
import { stringify } from "querystring";
import { logError } from "utils/error";
import { isRequestSuccessful } from "utils/Utils";
import { apiMethods } from "data/api.constants";

export const getPathFromS3URL = (url = "") => {
  return url.split("amazonaws.com/")?.[1];
};

export const getS3SignedUrl = async ({
  bucket = process.env.REACT_APP_S3_BUCKET,
  key,
}) => {
  try {
    const url = await _getS3SignedUrl({
      authorizationToken: process.env.REACT_APP_AWS_AUTHORISATION_TOKEN,
      bucket,
      key,
      lamdaBaseURL: process.env.REACT_APP_AWS_LAMDA_BASE_URL,
    });
    return url;
  } catch (error) {
    logError({
      error,
      when: "getS3SignedUrl",
      occuredAt: "src/features/Common/modules/S3/utils/S3.utils.js",
    });
    throw error;
  }
};

// keeping it here till axios is not a function issue in _uploadToS3 isn't resolved
// export const uploadToS3 = async ({
//   bucket = process.env.REACT_APP_S3_BUCKET,
//   key,
//   file,
//   abortSignal,
//   onProgress,
//   onAbort,
//   isPublic = false,
//   getFileLocation = false,
// }) => {
//   try {
//     const uploadUrl = await _uploadToS3({
//       authorizationToken: process.env.REACT_APP_AWS_AUTHORISATION_TOKEN,
//       bucket,
//       file,
//       key,
//       lamdaBaseURL: process.env.REACT_APP_AWS_LAMDA_BASE_URL,
//       isFilePublic: isPublic || bucket === process.env.REACT_APP_S3_BUCKET,
//       abortSignal,
//       onProgress,
//       onAbort,
//       getFileLocation,
//     });
//     return uploadUrl;
//   } catch (error) {
//     logError({
//       error,
//       when: "uploadToS3",
//       occuredAt: "src/features/Common/modules/S3/utils/S3.utils.js",
//     });
//     throw error;
//   }
// };

export const uploadToS3 = async ({
  bucket = process.env.REACT_APP_S3_BUCKET,
  key,
  file, // file to be in file/blob format
  isFilePublic = false,
  lamdaBaseURL = process.env.REACT_APP_AWS_LAMDA_BASE_URL,
  authorizationToken = process.env.REACT_APP_AWS_AUTHORISATION_TOKEN,
  abortSignal,
  onProgress,
  onAbort,
  contentType,
}) => {
  try {
    const payload = {
      key,
      url_required_for: url_required_for_keys.UPLOAD,
      expirytime: EXPIRY_TIME,
      bucket_name: bucket,
      content_type: contentType ?? file.type,
    };

    if (isFilePublic || bucket === process.env.REACT_APP_S3_BUCKET) {
      payload.is_public_read = true;
    }

    const queryUrl = `${lamdaBaseURL}/${GET_SIGNED_URL_API}?${stringify(
      payload
    )}`;

    const options = {
      method: apiMethods.GET,
      headers: {
        authorizationToken,
      },
    };
    const response = await fetch(queryUrl, options);
    const uploadObj = await response.json();

    if (is_empty(uploadObj?.url) || is_empty(uploadObj?.fields)) {
      throw new Error("Invalid upload object received from lamda");
    }

    const formData = new FormData();

    for (const field in uploadObj.fields) {
      formData.append(field, uploadObj.fields[field]);
    }
    formData.append("file", file);

    const uploadOptions = {
      headers: {
        authorizationToken,
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total && progressEvent.loaded) {
          if (onProgress) {
            onProgress({
              total: progressEvent.total,
              loaded: progressEvent.loaded,
            });
          }
        }
      },
    };

    if (abortSignal) {
      uploadOptions.signal = abortSignal;
    }

    const uploadResponse = await axios({
      ...uploadOptions,
      method: "post",
      url: uploadObj?.url,
      data: formData,
    });

    if (isRequestSuccessful(uploadResponse.status)) {
      return {
        Location: `https://${bucket}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/${key}`,
        Bucket: bucket,
        Key: key,
      };
    }
  } catch (error) {
    logError({ error, when: "uploadToS3", occuredAt: "S3.utils.js" });
    if (axios.isCancel(error)) {
      if (onAbort) onAbort();
    } else {
      throw error;
    }
  }
};
