import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  {
    root: {
      '&:focus-visible': {
        outline: 'none !important',
      },
    },
    placeholder: {
      top: 'unset !important',
    },
  },
  { name: 'ComponentLibrary' }
);

export default useStyles;
