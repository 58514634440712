export const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

export const RTE_NODE_TYPES = {
  PARAGRAPH: 'paragraph',
  SPAN: 'span',
  ORDERED_LIST: 'ordered-list',
  UNORDERED_LIST: 'unordered-list',
  LIST_ITEM: 'list-item',
  LIST_ITEM_TEXT: 'list-item-text',
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  BLOCKQUOTE: 'blockquote',
  ALIGN_LEFT: 'alignLeft',
  ALIGN_CENTER: 'alignCenter',
  ALIGN_RIGHT: 'alignRight',
  LINK: 'link',
  TABLE: 'table',
  TABLE_BODY: 'table-body',
  TABLE_ROW: 'table-row',
  TABLE_CELL: 'table-cell',
  TABLE_HEAD: 'table-head',
  IMAGE: 'image',
  HTML_CODE: 'html-code',
  CHIP: 'chip',
};

export const FONT_SIZES = {
  small: '0.75em',
  normal: '1em',
  medium: '1.75em',
  huge: '2.5em',
};

export const FONT_FAMILIES = {
  sans: 'Helvetica,Arial, sans serif',
  serif: 'Georgia, Times New Roaman,serif',
  monospace: 'Monaco, Courier New,monospace',
};
