import React from 'react';
import { useSelected } from 'slate-react';
import { getJsxStyles } from '../../../../utils/RTEEditable.utils';

interface LinkProps {
  attributes?: any;
  element?: any;
}

const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    style={{
      fontSize: 0,
    }}
  >
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);

const Link: React.FC<LinkProps> = ({ attributes, element, children }) => {
  const selected = useSelected();
  const styles: any = getJsxStyles(element?.style?.value);

  return (
    <a
      href={element.href}
      {...attributes}
      {...element.attr}
      target={element.target}
      style={{
        boxShadow: selected && '0 0 0 3px #ddd',
        ...styles,
      }}
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  );
};

export default Link;
