import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  {
    root: {
      height: 20,
      width: 20,
    },
  },
  {
    name: 'MonospaceTool',
  }
);
