import { QueryClient } from "@tanstack/react-query";
import { FIVE_MINUTE_STALE_TIME, MAX_FAILURE_COUNT } from "data/api.constants";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // @dev - same query data will not be fetched till 5 minutes unless it is being manually refeched or invalidated.
      staleTime: FIVE_MINUTE_STALE_TIME,
      retry: (failureCount, error) => {
        // Retry only for network errors
        return error.isNetworkError && failureCount < MAX_FAILURE_COUNT;
      },
    },
  },
});
