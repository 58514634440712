import { FC } from 'react';

export const UnorderedListElement: FC<any> = (props) => {
  const { element, children } = props;
  const attributes = props.attributes ?? {};

  return (
    <ul {...element.attr} {...attributes} style={{ padding: 'revert' }}>
      {children}
    </ul>
  );
};
