/**
 * Add query params to a url
 * @param obj: object of queries to be added in url
 * @param prefix: prefix key to added before every query key
 * @returns string of query param
 */
export const queryParamsStringify = (
  obj: Record<string, any>,
  prefix = "",
): string => {
  return Object.keys(obj)
    .map((key) => {
      const value = obj[key];
      const prefixedKey = prefix
        ? `${prefix}[${encodeURIComponent(key)}]`
        : encodeURIComponent(key);

      if (value === null || value === undefined) {
        return "";
      }

      if (typeof value === "object" && !Array.isArray(value)) {
        return queryParamsStringify(value, prefixedKey); // Recursively handle nested objects.
      }

      if (Array.isArray(value)) {
        return value
          .map((v) => `${prefixedKey}[]=${encodeURIComponent(v)}`)
          .join("&"); // Handle arrays.
      }

      return `${prefixedKey}=${encodeURIComponent(value)}`;
    })
    .filter((pair) => pair.length > 0) // Remove empty strings for null/undefined.
    .join("&");
};
