import React, { useState, useCallback, useEffect } from "react";
import { useListController } from "react-admin";

import { RESOURCE_KEYS, orgPermissions } from "../utils/OrgPermissions";
import useWebpageLeads from "./useWebpageLeads";
import {
  columnConfig,
  filterConfig,
  tabFilterConfig,
  tableChipsFormatter,
} from "./webpageLeadsTableConfig";
import constants from "constants/constants";
import ExlyTable from "../common/Components/ExlyTable";
import WebpageLeadsStats from "./components/WebpageLeadsStats";
import LayoutTopActions from "./components/LayoutTopActions";
import BukImportStrip from "./components/BulkImportStrip/BukImportStrip";
import {
  WhatsAppButton,
  DetailsDrawerFooter,
  BookingQuestions,
  MessagesModal,
  ShareModal,
  LeadActionsField,
} from "./components/CustomColumns";
import { useToggleState } from "utils/hooks/useToggleState";
import { is_empty } from "utils/validations";
import { BroadcastModal } from "common/Components/BroadcastModal/BroadcastModal";

import QuickAddModal from "./components/CrmModals/QuickAddModal/QuickAddModal";
import ManageColumnsModal from "./components/CrmModals/ManageColumnsModal/ManageColumnsModal";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useColumnsToShow from "../features/Crm/utils/hooks/useColumnsToShow";
import CustomFiltersLeads from "./components/CustomFiltersLeads/CustomFiltersLeads";
import { filterDays, WEBPAGE_LEADS_LEARN_MORE_HREFS } from "./moduleConstants";
import { getColumns } from "./webpageHelpers";
import { useDispatch } from "react-redux";
import { EXLY_DASHBOARD__REQUEST_LEADS } from "ui/pages/Dashboard/redux/actions";
import { REPORT_TYPES } from "ui/pages/Reports/constants";
import { exportLargeListingData } from "utils/Utils";
import BulkImportModal from "ui/pages/customers/ImportClients/components/BulkImportModal/BulkImportModal";
import BulkImportProgressModal from "ui/pages/customers/ImportClients/components/BulkImportProgressModal/BulkImportProgressModal";
import usePostBulkUpload from "ui/pages/customers/ImportClients/hooks/usePostBulkUpload";
import { import_type } from "ui/pages/customers/ImportClients/importClientsConstants";
import ExlyResponsesModal from "features/Crm/modules/CrmResponses/components/ExlyResponsesModal/ExlyResponsesModal";
import { useCrmResponses } from "features/Crm/modules/CrmResponses/utils/hooks/useCrmResponses";
import { RESET_RESPONSES_PAYLOAD } from "features/Crm/modules/CrmResponses/utils/redux/Crm.action";
import useBulkActions from "features/BulkActions/utils/hooks/useBulkActions";
import { BULK_ACTION_TABLES } from "features/BulkActions/BulkActions.constants";
import SelectionCard from "features/BulkActions/modules/SelectionCard/SelectionCard";
import ExlyImage from "common/Components/ExlyImage";
import { ENTITY_TYPE } from "constants/crmConstants";
import { DEFAULT_PER_PAGE } from "constants/numbers";
import { leads_table_config } from "features/Crm/modules/WebpageLeads/WebpageLeads.constants";
import AssignTags from "features/Crm/modules/AssignTags/AssignTags";
import { Header } from "common/Components/Header";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import styles from "./index.module.css";
import CrmNotes from "features/Crm/modules/CrmNotes/CrmNotes";
import { EXLY_TABLE_EMPTY_STATES_VERSIONS } from "common/Components/ExlyTable/ExlyTableEmptyState/constants/ExlyTableEmptyState.constants";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { TABLE_ACTIONS_IDS } from "features/Crm/Crm.constants";
import { useCustomerDetailsNavigations } from "features/Crm/utils/hooks/useCustomerDetailsNavigations";
import { getCrmAsset, getPerPageOptions } from "features/Crm/utils/Crm.utils";
import { useCustomColumnsConfig } from "features/Crm/modules/ManageColumns/modules/CustomColumn/utils/useCustomColumnsConfig";
import { CUSTOM_COLUMN_SECTIONS } from "features/Crm/modules/ManageColumns/modules/CustomColumn/constants/CustomColumn.constants";

const lead_entity_name = ENTITY_TYPE.lead.key;

const getColumnsHelper = () => getColumns(constants.entity_type.leads.value);
const WebpageLeadsOne = (props) => {
  const dispatch = useDispatch();
  const { handleCustomerDetailNavigation, scrollToLastViewedRow } =
    useCustomerDetailsNavigations();
  const {
    fetchAllOfferings,
    listingsFilter,
    statusFilter,
    orgMembers,
    handleClientUpload,
    notify,
    onQuickAddLeads,
    classes,
    canBroadcastMessage,
    onTagsAssign: onTagsAssignPerRecord,
  } = useWebpageLeads();

  const {
    isOnboardedPrevVal: isFeatureVisited,
    flagLoading: isFeatureFlagLoading,
    handleFeatureOnboardChange,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.KNOWLEDGE_BASE.WEBPAGE_LEADS,
    successCallback: (marked) => !marked && handleFeatureOnboardChange(true),
  });

  const {
    getTableActions,
    showAddTagsModal,
    setShowAddTagsModal,
    showBroadcastModal,
    setShowBroadcastModal,
    handleRowsSelected,
    selectedRecords,
    onTagsAssign,
    initiateBroadcast,
    currentPageFullSelection,
    selectAll,
    getBroadcastActions,
    handleSelectAll,
    generateFooterProps,
    setEmailId: setLeadEmailId,
    emailId: leadEmailId,
  } = useBulkActions(props);

  const {
    isBulkImportProcessing,
    bulkUploadApiResponse,
    showBulkUploadModal,
    showImportAlreadyInProgress,
    handleShowImportAlreadyInProgressModal,
    showBulkErrorModal,
    debouncedHandleBulkImportStripRefresh,
    bulkImportSuccessModalProps,
    bulkImportAlreadyInProgressModalProps,
    bulkImportErrorModalProps,
  } = usePostBulkUpload({ type: import_type.lead, handleClientUpload });
  const { data, selectedIds, loaded, filterValues, total, onSelect } =
    useListController({
      ...props,
      perPage: DEFAULT_PER_PAGE,
    });
  const broadcastActions = getBroadcastActions({
    action: ({ tableName, broadcastType }) =>
      initiateBroadcast({
        tableName,
        broadcastType,
        entityName: lead_entity_name,
      }),
    entityName: lead_entity_name,
    tableName: BULK_ACTION_TABLES.LEADS,
  });

  React.useEffect(() => {
    dispatch({ type: EXLY_DASHBOARD__REQUEST_LEADS, filterDays: filterDays });
    return () => {
      dispatch({ type: RESET_RESPONSES_PAYLOAD });
      onSelect([]); //@Dev need to clear the selectedIds when moved to another route
    };
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [leadId, setLeadId] = useState(null);
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const [showMessagesModal, setShowMessagesModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [customerName, setCustomerName] = useState();
  const [customerTagsArray, setCustomerTagsArray] = useState([]);
  const [loading, setLoading] = useState(false);

  const isDesktop = useDesktopMediaQuery();

  const { setResponsesPayloadToRedux } = useCrmResponses();

  const isEmpty = loaded && is_empty(data) && is_empty(filterValues);
  const canEditWebLeads = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SUBSECTIONS.LEADS.WEBPAGE_LEADS
  );
  const [isNotesModalActive, showNotesModal, hideNotesModal] =
    useToggleState(false);

  const [isShowTagsModalActive, openShowTagsModal, closeShowTagsModal] =
    useToggleState(false);

  const [
    isQuickAddModalActive,
    showQuickAddModalActive,
    hideQuickAddModalActive,
  ] = useToggleState(false);
  const [isManageColumnsActive, showManageColumns, hideManageColumns] =
    useToggleState(false);

  const showActions = canBroadcastMessage || canEditWebLeads;

  const [openDetailOnLoadRecordId, setOpenDetailOnLoadRecordId] = useState();
  const [showResponsesModal, setShowResponsesModal] = useState(false);

  React.useEffect(() => {
    fetchAllOfferings();
  }, []);

  const { columnsToShow } = useColumnsToShow({
    getColumnsHelper,
  });

  const setShowTagsModal = useCallback((record) => {
    const { tags, full_name, email } = record;
    setCustomerTagsArray(tags);
    setCustomerName(full_name);
    setLeadEmailId(email);
    openShowTagsModal();
  }, []);

  const showStaffFilter =
    orgPermissions.isOwner() ||
    orgPermissions.isMainUser() ||
    orgPermissions.isManager();

  const selectDays = (
    <LayoutTopActions
      canSendMessage={canBroadcastMessage}
      handleClientUpload={handleClientUpload}
      canEditWebLeads={canEditWebLeads}
      showQuickAddModalActive={showQuickAddModalActive}
      handleManageColumns={() => showManageColumns()}
      showBulkUploadOption={!isBulkImportProcessing}
      handleShowImportAlreadyInProgressModal={
        handleShowImportAlreadyInProgressModal
      }
    />
  );

  const setShowNotesModal = useCallback((emailId) => {
    setLeadEmailId(emailId);
    showNotesModal();
  }, []);

  const setAddTagsModal = useCallback((emailId, uniqueLeadId) => {
    setLeadEmailId(emailId);
    setLeadId(uniqueLeadId);
    setShowAddTagsModal(true);
  }, []);

  const HAS_WRITE_ACCESS = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SUBSECTIONS.LEADS.WEBPAGE_LEADS
  );
  const { appendCustomTableConfig } = useCustomColumnsConfig({
    sectionName: CUSTOM_COLUMN_SECTIONS.LEADS.section_name,
    recordSlug: CUSTOM_COLUMN_SECTIONS.LEADS.record_slug,
  });

  const {
    customColumnsToRender = [],
    customFiltersToAppend,
    customChipsToFormat,
  } = appendCustomTableConfig({
    orgMembers,
    disabled: !HAS_WRITE_ACCESS,
  });

  const onResponsesClick = (record) => {
    setShowResponsesModal(true);
    setResponsesPayloadToRedux({
      uniqueId: record.lead_id,
      entityType: constants.entity_type.leads.value,
    });
  };
  const columnsObj = columnConfig({
    orgPermissions,
    orgMembers,
    setOpenDetailOnLoadRecordId,
    handleUserClick: (record) =>
      handleCustomerDetailNavigation({
        entityType: constants.entity_type.leads.value,
        customerId: record.lead_id,
        uniqueRowId: record.id,
      }),
    setShowAnswerModal,
    setShowMessagesModal,
    setModalData,
    setScrollPosition,
    setShowNotesModal,
    setAddTagsModal,
    setShowTagsModal,
    disabled: !HAS_WRITE_ACCESS,
    onResponsesClick: (record) => onResponsesClick(record),
    customColumnsToRender,
  });

  const bulkActions = getTableActions(
    RESOURCE_KEYS.SUBSECTIONS.LEADS.WEBPAGE_LEADS
  );
  let primaryFooterProps = {
    text: "Add Leads",
    onClick: showQuickAddModalActive,
    hide: !canEditWebLeads,
    icon: (
      <ExlyImage
        src={getCrmAsset("person_add.svg")}
        height={20}
        width={20}
        id="tag"
      />
    ),
  };

  const footerProps = generateFooterProps({ bulkActions, primaryFooterProps });

  const customColumn = [
    ...columnsToShow,
    ...customColumnsToRender?.map((item) => item.field),
  ];
  // Getting filtered columns array of length 5
  let columnsObjFilters =
    customColumn?.map((column) => {
      return columnsObj[`${column}`];
    }) || [];

  // need my customColumnsToRender before action.field
  columnsObjFilters.push(columnsObj[leads_table_config.action.field]);
  columnsObjFilters.unshift(columnsObj[leads_table_config.full_name.field]);

  const columnsToRender = columnsObjFilters?.filter((value) => {
    return !is_empty(value);
  });

  const secondaryComponent = isDesktop ? (
    selectDays
  ) : (
    <div className={classes.secondaryComponentWrapper}>{selectDays}</div>
  );

  if (loaded && !isEmpty && scrollPosition) window.scrollTo(0, scrollPosition);

  // @dev - scrolling to last viewed row when coming back from details view
  useEffect(() => {
    scrollToLastViewedRow();
  }, [columnsToRender]);

  const perPageOptions = getPerPageOptions({ customColumnsToRender });
  return (
    <div>
      {isDesktop && (
        <Header
          title="Webpage Leads"
          learnMoreHref={WEBPAGE_LEADS_LEARN_MORE_HREFS.WEBPAGE_LEADS}
          about="about managing your webpage leads."
          right={secondaryComponent}
          classNames={{ container: styles.headerContainer }}
          hideBackButton
        />
      )}

      {columnsToShow?.length > 0 ? (
        <ExlyTable
          entityName={lead_entity_name}
          allValuesSelected={selectAll}
          bulkActionComponent={
            <SelectionCard
              actions={bulkActions}
              selectedRecords={Object.keys(selectedRecords)}
              totalRecords={total}
              currentPageFullSelection={currentPageFullSelection}
              entityName={lead_entity_name}
              selectAll={selectAll}
              handleSelectAll={handleSelectAll}
            />
          }
          ra_props={{
            ...props,
            perPageOptions,
          }}
          noExportButton={!orgPermissions.canExport()}
          layoutProps={{
            paddingDesktop: "0",
            paddingBottom: showActions ? "120px" : "32px",
            layoutDesktopMargin: "20px 24px",
            noMobileBoxShadow: true,
            learnMoreHref: WEBPAGE_LEADS_LEARN_MORE_HREFS.WEBPAGE_LEADS,
          }}
          showTopBars={true}
          tableTitle={isDesktop ? undefined : "Webpage Leads"}
          columnConfig={columnsToRender}
          tableFilters={filterConfig(
            listingsFilter,
            statusFilter,
            orgMembers,
            showStaffFilter,
            customFiltersToAppend
          )}
          customHandleExport={({ filterValues, isEmpty }) => {
            exportLargeListingData({
              filterValues,
              reportType: REPORT_TYPES.ALL_LEADS,
              notify,
              isEmpty,
            });
          }}
          entityType={constants.entity_type.leads.value}
          tabFilterConfig={tabFilterConfig}
          tableChipsFormatter={tableChipsFormatter(
            listingsFilter,
            statusFilter,
            orgMembers,
            customChipsToFormat
          )}
          classes={{ tabsWrapper: classes.webpageTabsWrapper }}
          statsComponent={(props) => (
            <div>
              {isBulkImportProcessing && (
                <BukImportStrip
                  handleRefreshClick={debouncedHandleBulkImportStripRefresh}
                  lastSyncTime={bulkUploadApiResponse.current_server_time}
                />
              )}
              <WebpageLeadsStats {...props} filterDays={filterDays} />
            </div>
          )}
          hasMobileFooter={showActions}
          footerProps={footerProps}
          renderPrimaryColumnRightNode={(record) => {
            return (
              <>
                {is_empty(record.phone_number) ? null : (
                  <WhatsAppButton
                    record={record}
                    source="phone_number"
                    countryCodeKey="country_code"
                  />
                )}
              </>
            );
          }}
          renderDrawerHeaderNode={(record) => (
            <>
              {is_empty(record.phone_number) ? null : (
                <WhatsAppButton
                  record={record}
                  source="phone_number"
                  countryCodeKey="country_code"
                />
              )}
            </>
          )}
          CustomFilters={CustomFiltersLeads}
          borderedFields
          fieldsLeftPadded
          isWebPage={true}
          drawerFieldsBordered
          fieldsAlignment="space-between"
          drawerFieldsAlignment="space-between"
          recordFooterVariant="secondary"
          customPrimaryAction={{
            label: "See More",
            onClick: (
              row,
              rows,
              setDrawerConfig,
              setActionsDrawerConfig,
              rowIndex
            ) =>
              setDrawerConfig({
                drawerActionType: "view",
                open: true,
                row,
                rowIndex,
              }),
          }}
          showSelectAllcheckBox={true}
          renderDrawerFooter={(record) => (
            <DetailsDrawerFooter
              record={record}
              source="phone_number"
              countryCodeKey="country_code"
            />
          )}
          renderSecondaryAction={(record) => (
            <LeadActionsField
              record={record}
              onClick={(record, e) => {
                const { id } = e.target;
                if (id === TABLE_ACTIONS_IDS.NOTE)
                  setShowNotesModal(record?.email);
                else if (id === TABLE_ACTIONS_IDS.TAG)
                  setAddTagsModal(record?.email, record?.id);
                else
                  handleCustomerDetailNavigation({
                    entityType: constants.entity_type.leads.value,
                    customerId: record.lead_id,
                    uniqueRowId: record.id,
                  });
              }}
            />
          )}
          openDetailOnLoadRecordId={openDetailOnLoadRecordId}
          primaryKey="id"
          checkboxSelection
          onRowSelected={handleRowsSelected}
          selected={selectedIds}
          emptyStateProps={{
            isLoading: isFeatureFlagLoading,
            version: EXLY_TABLE_EMPTY_STATES_VERSIONS.V2,
            title: "No lead messages yet",
            description:
              "As soon as we have a new lead message, it will show up here. View the video to learn more.",
            videoSrc: WEBPAGE_LEADS_LEARN_MORE_HREFS.WEBPAGE_LEADS_VIDEO,
            playerProps: {
              playing: !isFeatureFlagLoading && !isFeatureVisited, // autoplay the knowledge base video on the first visit to this feature
            },
            primaryCtaProps:
              isDesktop &&
              (canEditWebLeads
                ? { title: "Add Leads", onClick: showQuickAddModalActive }
                : {
                    title: "Share Your Webpage",
                    onClick: () => setShowShareModal(true),
                  }),
            hideActionsOnMobile: false,
            className: "mt-3",
          }}
        />
      ) : (
        <></>
      )}

      {canBroadcastMessage && showBroadcastModal && (
        <BroadcastModal
          open={showBroadcastModal}
          onClose={() => setShowBroadcastModal(false)}
          modal_props={{ title: "Send Message" }}
          mobile_modal_props={{ header: "Select Broadcast medium" }}
          broadcastActions={broadcastActions}
        />
      )}
      <BookingQuestions
        data={modalData}
        isOpen={showAnswerModal}
        type="lead"
        hideModal={() => {
          setShowAnswerModal(false);
          // setModalData(null);
        }}
      />
      <MessagesModal
        data={modalData}
        isOpen={showMessagesModal}
        type={"lead"}
        hideModal={() => {
          setShowMessagesModal(false);
          // setModalData(null);
        }}
      />
      <ShareModal
        isOpen={showShareModal}
        hideModal={() => setShowShareModal(false)}
        setShowShare={(value) => setShowShareModal(value)}
      />
      {isNotesModalActive && (
        <CrmNotes
          open={isNotesModalActive}
          onClose={hideNotesModal}
          feature={RESOURCE_KEYS.SUBSECTIONS.LEADS.WEBPAGE_LEADS}
          emailId={leadEmailId}
        />
      )}
      <AssignTags
        feature={RESOURCE_KEYS.SUBSECTIONS.LEADS.WEBPAGE_LEADS}
        tableName={BULK_ACTION_TABLES.LEADS}
        entityName={ENTITY_TYPE.lead.key}
        selectedRecords={selectedRecords}
        onTagsAssign={onTagsAssign}
        onTagsAssignPerRecord={onTagsAssignPerRecord}
        isShowTagsModalActive={isShowTagsModalActive}
        closeShowTagsModal={closeShowTagsModal}
        customerTagsArray={customerTagsArray}
        customerName={customerName}
        setShowAddTagsModal={setShowAddTagsModal}
        uniqueRowId={leadId}
        leadEmailId={leadEmailId}
        setLeadEmailId={setLeadEmailId}
        showAddTagsModal={showAddTagsModal}
      />
      {isQuickAddModalActive && (
        <QuickAddModal
          key={"QuickAdd"}
          open={isQuickAddModalActive}
          onClose={hideQuickAddModalActive}
          onSubmit={(value) => {
            onQuickAddLeads(value, hideQuickAddModalActive, setLoading);
          }}
          loading={loading}
        />
      )}
      {isManageColumnsActive && (
        <ManageColumnsModal
          key={"ManageColumnsModal"}
          open={isManageColumnsActive}
          onClose={hideManageColumns}
          columnsToShow={columnsToShow}
          type={constants.entity_type.leads.value}
        />
      )}
      {showBulkUploadModal && (
        <BulkImportModal {...bulkImportSuccessModalProps} />
      )}
      {showImportAlreadyInProgress && (
        <BulkImportProgressModal {...bulkImportAlreadyInProgressModalProps} />
      )}
      {showBulkErrorModal && (
        <BulkImportProgressModal {...bulkImportErrorModalProps} />
      )}
      <ExlyResponsesModal
        showResponsesModal={showResponsesModal}
        setShowResponsesModal={setShowResponsesModal}
      />
    </div>
  );
};

export default withComponentLibraryTheme(WebpageLeadsOne);
