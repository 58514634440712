import FormatItalicRoundedIcon from '@material-ui/icons/FormatItalicRounded';
import { RTE_TOOLBAR_MARKS } from '../../constants/RTEToolbar.marks';
import { MarkToolButton } from '../MarkToolButton/MarkToolButton';

export const ItalicMarkTool = () => {
  return (
    <MarkToolButton markFormat={RTE_TOOLBAR_MARKS.italic}>
      <FormatItalicRoundedIcon />
    </MarkToolButton>
  );
};
