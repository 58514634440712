import React from "react";
import ExlySpinner from "ui/modules/ExlySpinner/ExlySpinner";
import { useCustomColumnUpdate } from "../../CustomColumn/utils/useCustomColumnUpdate";
import styles from "./TimeField.module.css";
import ExlyTimePicker from "common/Components/ExlyTimePicker";
import classnames from "classnames";
import { convertTo12HourFormat } from "features/Common/modules/DateTime/DateTime.utils";
import { is_empty } from "features/Common/utils/common.utils";

export const TimeField = ({
  targetObject,
  handleUpdate,
  defaultValue = "",
  disabled,
  columnWidth,
}) => {
  const { loading, selectedValue, handleUpdateColumnData, setSelectedValue } =
    useCustomColumnUpdate({
      handleUpdate,
      targetObject,
      defaultValue: convertTo12HourFormat(defaultValue),
    });

  if (loading) return <ExlySpinner loaderWidth="24px" wrapperPadding="0 0" />;

  const handleBlur = ({ target: { value } }) => {
    const payloadValue = is_empty(value) ? null : value;
    handleUpdateColumnData(payloadValue);
  };

  return (
    <ExlyTimePicker
      placeholder="Select time"
      value={selectedValue}
      disabled={disabled}
      dropdownWrapperClassName={styles.timepicker}
      customClasses={{
        input: classnames(styles.timeInput, disabled && styles.disabledTime),
      }}
      onChange={setSelectedValue}
      onBlur={handleBlur}
      native_width={columnWidth || "100%"}
      fullWidth
      size="thin"
      padded
    />
  );
};
