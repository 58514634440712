import { FC } from 'react';

export const ListItemElement: FC<any> = (props) => {
  const { element, children } = props;
  const attributes = props.attributes ?? {};

  return (
    <li
      {...element.attr}
      {...attributes}
      style={{
        listStylePosition: 'outside',
      }}
    >
      {children}
    </li>
  );
};
