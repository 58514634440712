import { is_empty } from "utils/validations";
import dataProvider from "./dataProvider";
import { INTERNAL_STATUS_CODES } from "./api.constants";
import { useDispatch } from "react-redux";
import { EXLY_ACCOUNT__SET_FEATURE_CREDITS } from "ui/pages/Account/redux/actions";
import { CREDITS_EXPIRY_STATUS } from "common/Components/UBP/ubp.constants";
import { logError } from "utils/error";

/**
 *
 * @deprecated
 */
export const useExlyDataProvider = () => {
  const dispatch = useDispatch();

  const custom_request = async (
    resource,
    method,
    params = {},
    options = {}
  ) => {
    try {
      const { gzip_encoding = false, checkCreditsExpiry } = options;
      const response = await dataProvider.custom_request(
        resource,
        method,
        params,
        gzip_encoding
      );
      if (checkCreditsExpiry) checkFeatureExpiry(response);
      return response;
    } catch (err) {
      const { checkCreditsExpiry } = options;
      if (checkCreditsExpiry) checkFeatureExpiry(err?.body);
      logError({
        error: err,
        when: "custom_request",
        occuredAt: "data/useExlyDataProvider.js",
      });
      throw err;
    }
  };

  const checkFeatureExpiry = (response) => {
    if (
      !is_empty(response?.data) &&
      response?.data.status ===
        INTERNAL_STATUS_CODES.EXPIRED_CREDIT_STATUS_CODE &&
      !is_empty(response?.data?.feature_key)
    ) {
      dispatch({
        type: EXLY_ACCOUNT__SET_FEATURE_CREDITS,
        payload: {
          data: { credits_expiry_status: CREDITS_EXPIRY_STATUS.expired },
          feature_key: response?.data?.feature_key,
        },
      });
    }
  };

  return {
    custom_request,
  };
};
