import React, { useEffect, useRef, useState } from 'react';
import Icon from '../../common/Icon';
import { Transforms } from 'slate';

import Button from '../../common/Button';
import { Popover } from '@material-ui/core';
import { TYPES } from '../../utils/SlateUtilityFunctions';
import { useStyles } from './TableSelecor.styles';
import { TableUtil } from '../../utils/table';

interface TableSelectorProps {
  editor: any;
  className?: string;
}

const TableSelector: React.FC<TableSelectorProps> = ({ editor, className }) => {
  const classes = useStyles();

  const tableOptionsRef = useRef<any>();
  const [selection, setSelection] = useState();
  const [showOptions, setShowOptions] = useState(false);
  const [tableData, setTableData] = useState({
    row: 0,
    column: 0,
  });
  const [tableInput, setTableInput] = useState(
    Array.from({ length: 6 }, () =>
      Array.from({ length: 6 }, (v, i) => ({
        bg: 'lightGray',
        column: i,
      }))
    )
  );

  useEffect(() => {
    const newTable = Array.from({ length: 6 }, (obj, row) =>
      Array.from({ length: 6 }, (v, col) => ({
        bg:
          row + 1 <= tableData.row && col + 1 <= tableData.column
            ? 'orange'
            : 'lightgray',
        column: col,
      }))
    );
    setTableInput(newTable);
  }, [tableData]);
  useEffect(() => {
    if (!showOptions) {
      setTableData({
        row: 0,
        column: 0,
      });
    }
  }, [showOptions]);
  const table = new TableUtil(editor);

  const handleButtonClick = () => {
    setSelection(editor.selection);
    setShowOptions((prev) => !prev);
  };
  const handleInsert = () => {
    selection && Transforms.select(editor, selection);
    setTableData({ row: -1, column: -1 });
    table.insertTable(tableData.row, tableData.column);
    setShowOptions(false);
  };
  return (
    <div ref={tableOptionsRef}>
      <Button title="table" onClick={handleButtonClick} className={className}>
        <Icon icon={TYPES.TABLE} />
      </Button>
      <Popover
        classes={{
          paper: classes.paper,
        }}
        anchorEl={tableOptionsRef.current}
        open={showOptions}
        onClose={() => setShowOptions(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div>
          {
            <span style={{ fontSize: '0.85em' }}>
              <i>{`Insert a ${
                tableData.row >= 1
                  ? `${tableData.row} x ${tableData.column}`
                  : ''
              } table`}</i>
            </span>
          }
          <div className={classes.tableInput}>
            {tableInput.map((grp, row) =>
              grp.map(({ column, bg }, col) => (
                <div
                  key={row + col}
                  onClick={() => handleInsert()}
                  onMouseOver={() =>
                    setTableData({ row: row + 1, column: column + 1 })
                  }
                  className={classes.tableUnit}
                  style={{ border: `1px solid ${bg}` }}
                ></div>
              ))
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default TableSelector;
