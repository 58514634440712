import { RTE_TOOLBAR_MARKS } from '../../constants/RTEToolbar.marks';
import { MarkToolButton } from '../MarkToolButton/MarkToolButton';
import { useStyles } from './InlineCodeTool.styles';

export const InlineCodeTool = () => {
  const classes = useStyles();
  return (
    <MarkToolButton
      className={classes.root}
      markFormat={RTE_TOOLBAR_MARKS.code}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
      >
        <mask
          id="mask0_1566_7863"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="21"
        >
          <rect y="0.5" width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1566_7863)">
          <path
            d="M7.35417 10.5L8.58333 9.27083C8.75 9.10417 8.83333 8.90972 8.83333 8.6875C8.83333 8.46528 8.75 8.27083 8.58333 8.10417C8.41667 7.9375 8.21875 7.85417 7.98958 7.85417C7.76042 7.85417 7.5625 7.9375 7.39583 8.10417L5.58333 9.91667C5.5 10 5.44097 10.0903 5.40625 10.1875C5.37153 10.2847 5.35417 10.3889 5.35417 10.5C5.35417 10.6111 5.37153 10.7153 5.40625 10.8125C5.44097 10.9097 5.5 11 5.58333 11.0833L7.39583 12.8958C7.5625 13.0625 7.76042 13.1458 7.98958 13.1458C8.21875 13.1458 8.41667 13.0625 8.58333 12.8958C8.75 12.7292 8.83333 12.5347 8.83333 12.3125C8.83333 12.0903 8.75 11.8958 8.58333 11.7292L7.35417 10.5ZM12.6458 10.5L11.4167 11.7292C11.25 11.8958 11.1667 12.0903 11.1667 12.3125C11.1667 12.5347 11.25 12.7292 11.4167 12.8958C11.5833 13.0625 11.7812 13.1458 12.0104 13.1458C12.2396 13.1458 12.4375 13.0625 12.6042 12.8958L14.4167 11.0833C14.5 11 14.559 10.9097 14.5938 10.8125C14.6285 10.7153 14.6458 10.6111 14.6458 10.5C14.6458 10.3889 14.6285 10.2847 14.5938 10.1875C14.559 10.0903 14.5 10 14.4167 9.91667L12.6042 8.10417C12.5208 8.02083 12.4271 7.95833 12.3229 7.91667C12.2188 7.875 12.1146 7.85417 12.0104 7.85417C11.9062 7.85417 11.8021 7.875 11.6979 7.91667C11.5938 7.95833 11.5 8.02083 11.4167 8.10417C11.25 8.27083 11.1667 8.46528 11.1667 8.6875C11.1667 8.90972 11.25 9.10417 11.4167 9.27083L12.6458 10.5ZM4.16667 18C3.70833 18 3.31597 17.8368 2.98958 17.5104C2.66319 17.184 2.5 16.7917 2.5 16.3333V4.66667C2.5 4.20833 2.66319 3.81597 2.98958 3.48958C3.31597 3.16319 3.70833 3 4.16667 3H15.8333C16.2917 3 16.684 3.16319 17.0104 3.48958C17.3368 3.81597 17.5 4.20833 17.5 4.66667V16.3333C17.5 16.7917 17.3368 17.184 17.0104 17.5104C16.684 17.8368 16.2917 18 15.8333 18H4.16667ZM4.16667 16.3333H15.8333V4.66667H4.16667V16.3333Z"
            fill="#1C1B1F"
          />
        </g>
      </svg>
    </MarkToolButton>
  );
};
