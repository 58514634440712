//@DEV(muskan) todo not moving this to feature folder as This config will be moved to BE in CRM R4
const constants = {
  /** unique identifier/primary key for each booking in my bookings view */
  id: "uuid",
  /** unique identifier/primary key for record for transacton in details page */
  transaction_uuid: "transaction_uuid",
  form_data: {
    name: {
      field: "customer_name",
      source: "guest_name",
      placeholder: "Name",
    },
    email: {
      field: "customer_email",
      placeholder: "Email",
      source: "guest__email",
    },
    phone: {
      field: "customer_phone",
      source: "guest__phone_number",
      placeholder: "Phone Number",
    },
    offering: {
      field: "session_name",
      source: "listing__title",
      placeholder: "Offering",
    },
    purchased_plan: {
      field: "purchased_plan_name",
      placeholder: "Plan Name",
    },
    offering_type: {
      field: "session_type",
      source: "listing__type",
      placeholder: "Offering Type",
    },
    upsell_type: {
      field: "upsell_type",
      placeholder: "Promotion Type",
      source: "upsell_type",
    },
    source_upsell_offering_name: {
      field: "source_upsell_offering_name",
      source: "source_upsell_offering_name",
      placeholder: "Promotion From Offering",
    },
    units: {
      field: "guest_count",
      source: "guest_count",
      placeholder: "No. of Units",
    },
    date: {
      field: "created_at",
      placeholder: "Booked On",
      source: "created_at__start_range",
      filter_placeholder: "Booked On or After",
    },
    staff: {
      field: "staff_name",
      placeholder: "Offering Owner",
      source: "org_uuid",
    },
    price: {
      field: "transaction_amount",
      placeholder: "Price Paid",
    },
    min_price: {
      placeholder: "Minimum Price",
      source: "min_price",
    },
    max_price: {
      placeholder: "Maximum Price",
      source: "max_price",
    },
    listing_status: "listing_status",
    utm_info: {
      field: "utm_info",
      placeholder: "UTM info",
    },
    utm_source: {
      source: "utm_info__source",
      placeholder: "UTM Source",
    },
    utm_medium: {
      field: "utm_info",
      placeholder: "UTM medium",
      source: "utm_info__medium",
    },
    utm_campaign: {
      field: "utm_info",
      placeholder: "UTM campaign",
      source: "utm_info__campaign",
    },
    payment_type: {
      field: "payment_type",
      source: "payment_type",
      placeholder: "Payment Type",
    },
    subscription_status: {
      field: "subscription_status",
      source: "subscription_status",
      placeholder: "Status",
    },
    payment_debit_mode: {
      field: "payment_debit_mode",
      source: "payment_debit_mode",
      placeholder: "Debit Type",
    },
    listing_address: {
      field: "venue_details",
      placeholder: "Meeting link/ Location",
    },
    batch: {
      placeholder: "Batch",
      field: "class_group_uuid",
    },
    customer_timezone: { placeholder: "Timezone" },
    responses: { placeholder: "Responses", field: "answer_count" },
    total_installments: { field: "total_installments" },
    paid_installments: { field: "paid_installments" },
    variants_purchased: "Variant Purchased",
    quantity: "Quantity",
    shipping_status: "Shipping Status",
    delivery_address: "Delivery Address",
    subscription_start_date: {
      field: "cycle_start_slot_time",
      placeholder: "Subscription Start Date",
    },
    subscription_end_date: {
      field: "cycle_end_slot_time",
      placeholder: "Next Due Date",
    },
    class_start_date: {
      field: "cycle_start_slot_time",
      placeholder: "Class Start Date",
    },
    class_end_date: {
      field: "cycle_end_slot_time",
      placeholder: "Class End Date",
    },
    slots_booked: {
      field: "slots_booked",
      placeholder: "Slot(s) Booked",
    },
    session_booked: {
      placeholder: "Session Booked",
      field: "slot_start_datetime",
    },
  },
  action: "Action",
  booking_questions_header: "Answers",
};
export default constants;

export const bookings_section_name = "bookings";
export const custom_column_types = {
  organisation_type: {
    value: "organisationstaff",
  },
};

export const MY_BOOKINGS_LEARN_MORE_HREFS = {
  MY_BOOKINGS:
    "https://support.exlyapp.com/support/solutions/articles/84000347720-my-bookings-on-exly",
};
