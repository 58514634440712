import React, { useState } from "react";
import { ExlyModal } from "features/Common/modules/ExlyModal/ExlyModal";
import { Input } from "@my-scoot/component-library-legacy";
import { useLongTextFieldValueModal } from "./utils/useLongTextFieldValueModal";
import styles from "./LongTextFieldValueModal.module.css";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { LONG_TEXT_MAX_CHAR_LIMIT } from "../../constants/LongTextField.constants";

export const LongTextFieldValueModal = withComponentLibraryTheme(
  ({ handleSubmit = () => {}, value, onChange, disabled }) => {
    const [currentValue, setCurrentValue] = useState(value);
    const { longTextValueModalOpen, closeLongTextValueModal } =
      useLongTextFieldValueModal();

    const closeModal = () => {
      onChange(value);
      closeLongTextValueModal();
    };

    const onSave = () => {
      onChange(currentValue);
      handleSubmit(currentValue);
      closeLongTextValueModal();
    };

    // Determines whether the Save button should be disabled
    const disableSaveBtn =
      disabled || // If the component is already disabled (e.g., due to some external condition)
      currentValue === value || // If the current value is unchanged (no updates made)
      (currentValue.length && !currentValue.trim()); // If the current value has non-whitespace characters but is effectively empty (e.g., only spaces)

    return (
      <ExlyModal
        title="Add text"
        primaryBtnText="Save"
        onPrimaryBtnClick={onSave}
        primaryBtnProps={{
          fullWidth: true,
          disabled: disableSaveBtn,
        }}
        open={longTextValueModalOpen}
        onClose={closeModal}
        padded={false}
        showSecondaryBtn={false}
        modal_props={{
          modalPaperClassName: styles.modalPaper,
        }}
      >
        <div className={styles.modalContent}>
          <Input
            value={currentValue}
            placeholder="Enter text here..."
            className={styles.inputClass}
            inputElementClassName={styles.inputElement}
            multiline
            onChange={({ target: { value } }) => setCurrentValue(value)}
            disabled={disabled}
            inputProps={{ maxLength: LONG_TEXT_MAX_CHAR_LIMIT }}
          />
        </div>
      </ExlyModal>
    );
  }
);
