import React, { useCallback } from "react";
import { Field } from "react-final-form";

import styles from "./ExlyTableFilterTimePicker.module.css";
import { ExlyTimePicker } from "common/form";

export const ExlyTableFilterTimePicker = ({
  placeholder,
  source,
  filterValues,
  setFilters,
}) => {
  const onChange = useCallback(
    (value) => {
      setFilters({
        ...filterValues,
        [source]: value,
      });
    },
    [filterValues, source]
  );

  return (
    <Field
      component={ExlyTimePicker}
      name={source}
      placeholder={placeholder}
      onChange={onChange}
      dropdownWrapperClassName={styles.timepicker}
      fullWidth
      padded
      classes={{
        notchedOutline: styles.noBorder,
        root: styles.timepicker,
        input: styles.mobileInput,
      }}
    />
  );
};
