import { RichTextEditorUtils } from "@my-scoot/component-library-legacy";
import { schedule_types_ids } from "constants/schedule";
import {
  DRIP_AUDIENCE_TYPE,
  DRIP_FREQUENCY,
  DRIP_TRIGGERS,
  DRIP_TRIGGERS_MESSAGES,
  DRIP_TRIGGER_LABELS,
  DRIP_USER_TYPE,
  EMAIL_DRIP_STATUS,
  EMAIL_SCHEDULE,
} from "./EmailDrip.constants";
import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";

const DripCarouselDesktop1 = getS3AssetCDNUrl(
  "assets/vectors/sales_and_marketing/broadcast/drip-carousel-desktop-1.svg"
);
const DripCarouselDesktop2 = getS3AssetCDNUrl(
  "assets/vectors/sales_and_marketing/broadcast/drip-carousel-desktop-2.svg"
);
const DripCarouselDesktop3 = getS3AssetCDNUrl(
  "assets/vectors/sales_and_marketing/broadcast/drip-carousel-desktop-3.svg"
);
const DripCarouselDesktop4 = getS3AssetCDNUrl(
  "assets/vectors/sales_and_marketing/broadcast/drip-carousel-desktop-4.svg"
);
const DripCarouselMobile1 = getS3AssetCDNUrl(
  "assets/vectors/sales_and_marketing/broadcast/drip-carousel-mobile-1.svg"
);
const DripCarouselMobile2 = getS3AssetCDNUrl(
  "assets/vectors/sales_and_marketing/broadcast/drip-carousel-mobile-2.svg"
);
const DripCarouselMobile3 = getS3AssetCDNUrl(
  "assets/vectors/sales_and_marketing/broadcast/drip-carousel-mobile-3.svg"
);
const DripCarouselMobile4 = getS3AssetCDNUrl(
  "assets/vectors/sales_and_marketing/broadcast/drip-carousel-mobile-4.svg"
);

export const getDripProgressSteps = ({ dripUuid, page }) => {
  const completedOptions = [
    {
      title: "Setup",
      path: `/email-drip/${dripUuid}/setup`,
      completed: true,
    },
    {
      title: "Drip flow",
      path: `/email-drip/${dripUuid}/drip-flow`,
      completed: true,
    },
    {
      title: "Review",
      path: `/email-drip/${dripUuid}/review`,
      completed: true,
    },
  ];

  const steps = completedOptions.map((option, index) => ({
    ...option,
    completed: index < page,
  }));

  return { steps, completedOptions };
};

export const getUserType = ({ user_type, listing_uuid, audience_type }) => {
  if (!user_type) {
    // using defaults
    return audience_type === DRIP_AUDIENCE_TYPE.CUSTOMERS
      ? DRIP_USER_TYPE.FRONT_END_KEY_CUSTOMER_WITH_SPECIFIC_OFFERING
      : DRIP_USER_TYPE.FRON_END_KEY_HIGH_INTENT_WITH_SPECIFIC_OFFERING;
  }
  if (audience_type === DRIP_AUDIENCE_TYPE.CUSTOMERS) {
    return listing_uuid
      ? DRIP_USER_TYPE.FRONT_END_KEY_CUSTOMER_WITH_SPECIFIC_OFFERING
      : DRIP_USER_TYPE.ALL_CUSTOMERS;
  }

  if (user_type === DRIP_USER_TYPE.LOW_INTENT_LEADS) return user_type;
  return listing_uuid
    ? DRIP_USER_TYPE.FRON_END_KEY_HIGH_INTENT_WITH_SPECIFIC_OFFERING
    : DRIP_USER_TYPE.HIGH_INTENT_LEADS;
};

export const getAudienceReviewText = ({
  user_type,
  listing_uuid,
  audience_type,
  listing_title = "",
}) => {
  if (audience_type === DRIP_AUDIENCE_TYPE.CUSTOMERS) {
    return listing_uuid
      ? `Customers who buy the offering: ${listing_title}`
      : "All customers";
  }

  if (user_type === DRIP_USER_TYPE.LOW_INTENT_LEADS)
    return "All low intent leads";
  return listing_uuid
    ? `High intent leads for offering: ${listing_title}`
    : "All high intent leads";
};

export const DEFAULT_EMAIL_TIME = "10:00 AM";

export const getIntitialAudienceFormValues = (details) => {
  const { audience_type } = details || {};
  const initialDetails = {
    ...details,
    schedule_time: details.schedule_time || DEFAULT_EMAIL_TIME,
    audience_type: audience_type || DRIP_AUDIENCE_TYPE.CUSTOMERS,
  };
  const isPublished =
    details?.status === EMAIL_DRIP_STATUS.ACTIVE ||
    details?.status === EMAIL_DRIP_STATUS.PAUSED;

  const front_end_user_type = getUserType(initialDetails);
  return {
    ...initialDetails,
    front_end_user_type,
    // for paused and active drip show warning modal
    isPublished,
  };
};

export const getTriggerValues = ({ offeringType, front_end_user_type }) => {
  const isOneOnOneClass = offeringType === schedule_types_ids.one_on_one_class;
  const isGroupClass = offeringType === schedule_types_ids.group_class;
  const isRollingClass = offeringType === schedule_types_ids.rolling_class;
  const isWorkshop = offeringType === schedule_types_ids.workshop;
  const isAppointment =
    offeringType === schedule_types_ids.one_on_one_appointment;

  // all customers
  if (front_end_user_type === DRIP_USER_TYPE.ALL_CUSTOMERS) {
    return {
      defaultValue: DRIP_TRIGGERS.MAKING_PURCHASE,
      options: [
        {
          label: DRIP_TRIGGER_LABELS.MAKING_PURCHASE,
          value: DRIP_TRIGGERS.MAKING_PURCHASE,
        },
      ],
    };
  }

  // customers of specific offering
  if (
    front_end_user_type ===
    DRIP_USER_TYPE.FRONT_END_KEY_CUSTOMER_WITH_SPECIFIC_OFFERING
  ) {
    if (
      isOneOnOneClass ||
      isGroupClass ||
      isRollingClass ||
      isWorkshop ||
      isAppointment
    ) {
      const offeringStartLabel =
        isOneOnOneClass || isAppointment || isRollingClass
          ? "When offering starts for customer"
          : DRIP_TRIGGER_LABELS.START_OF_OFFERING;

      return {
        defaultValue: DRIP_TRIGGERS.START_OF_OFFERING,
        options: [
          {
            label: DRIP_TRIGGER_LABELS.MAKING_PURCHASE,
            value: DRIP_TRIGGERS.MAKING_PURCHASE,
          },
          {
            label: offeringStartLabel,
            value: DRIP_TRIGGERS.START_OF_OFFERING,
          },
        ],
      };
    }

    return {
      defaultValue: DRIP_TRIGGERS.MAKING_PURCHASE,
      options: [
        {
          label: DRIP_TRIGGER_LABELS.MAKING_PURCHASE,
          value: DRIP_TRIGGERS.MAKING_PURCHASE,
        },
      ],
    };
  }

  // all high intent leads
  if (front_end_user_type === DRIP_USER_TYPE.HIGH_INTENT_LEADS) {
    return {
      defaultValue: DRIP_TRIGGERS.PAYMENT_ABANDONMENT,
      options: [
        {
          label: DRIP_TRIGGER_LABELS.PAYMENT_ABANDONMENT,
          value: DRIP_TRIGGERS.PAYMENT_ABANDONMENT,
        },
      ],
    };
  }

  // high intent leads of an offering

  if (
    front_end_user_type ===
    DRIP_USER_TYPE.FRON_END_KEY_HIGH_INTENT_WITH_SPECIFIC_OFFERING
  ) {
    if (isOneOnOneClass || isGroupClass || isWorkshop) {
      return {
        defaultValue: DRIP_TRIGGERS.START_OF_OFFERING,
        options: [
          {
            label: DRIP_TRIGGER_LABELS.PAYMENT_ABANDONMENT,
            value: DRIP_TRIGGERS.PAYMENT_ABANDONMENT,
          },
          {
            label: DRIP_TRIGGER_LABELS.START_OF_OFFERING,
            value: DRIP_TRIGGERS.START_OF_OFFERING,
          },
        ],
      };
    }

    return {
      defaultValue: DRIP_TRIGGERS.PAYMENT_ABANDONMENT,
      options: [
        {
          label: DRIP_TRIGGER_LABELS.PAYMENT_ABANDONMENT,
          value: DRIP_TRIGGERS.PAYMENT_ABANDONMENT,
        },
      ],
    };
  }

  // low intent leads
  if (front_end_user_type === DRIP_USER_TYPE.LOW_INTENT_LEADS) {
    return {
      defaultValue: DRIP_TRIGGERS.GET_IN_TOUCH_FORM,
      options: [
        {
          label: DRIP_TRIGGER_LABELS.GET_IN_TOUCH_FORM,
          value: DRIP_TRIGGERS.GET_IN_TOUCH_FORM,
        },
      ],
    };
  }

  return { options: [] };
};

export const validateEmails = (values) => {
  const errors = {};

  if (!values.email_list || values.email_list.length === 0) {
    errors.email_list = ["required"];
    return errors;
  }
  values.email_list.forEach((value, index) => {
    if (
      !value ||
      typeof value !== "object" ||
      !value.email_subject ||
      RichTextEditorUtils.isEmpty(value.email_body) ||
      (!value.magnitude && value.magnitude !== 0)
    ) {
      errors[`email_list[${index}]`] = ["Please fill all the fields"];
    }
  });

  return errors;
};

export const getDripEmailContentCarouselSlides = (isDesktop) => [
  {
    title: "Personalise your emails",
    description:
      "We have created a set of variables for you to add to your email. These variables will act like placeholders for the magic they bring.",
    icon: isDesktop ? DripCarouselDesktop1 : DripCarouselMobile1,
  },
  {
    title: "Add info with variables",
    description:
      "You can use them when you want to add some details to the email and personalise it as per the user. You can also add automation for yourself.",
    icon: isDesktop ? DripCarouselDesktop2 : DripCarouselMobile2,
  },
  {
    title: "Add variables on Subject & Email Body",
    description:
      "The variables will get added to either the Subject line or the Email Body depending upon where your cursor is! As simple as pie!",
    icon: isDesktop ? DripCarouselDesktop3 : DripCarouselMobile3,
  },
  {
    title: "Easily replace or remove",
    description:
      "Tap on a variable to choose a different variable and replace the current one. You can simply hit Backspace to remove a variable. Go on, try yourself!",
    icon: isDesktop ? DripCarouselDesktop4 : DripCarouselMobile4,
  },
];

// with personlize in desktop is in toolbar
export const getComposeEmailPersonaliseSlides = (isDesktop) => [
  {
    title: "Personalise your emails",
    description:
      "We have created a set of variables for you to add to your email. These variables will act like placeholders for the magic they bring.",
    icon: isDesktop ? DripCarouselDesktop1 : DripCarouselMobile1,
  },
  {
    title: "Add info with variables",
    description:
      "You can use them when you want to add some details to the email and personalise it as per the user. You can also add automation for yourself.",
    icon: isDesktop ? DripCarouselDesktop2 : DripCarouselMobile2,
  },
  {
    title: "Add variables on Subject & Email Body",
    description:
      "The variables will get added to either the Subject line or the Email Body depending upon where your cursor is! As simple as pie!",
    icon: isDesktop ? DripCarouselDesktop3 : DripCarouselMobile3,
  },
  {
    title: "Easily replace or remove",
    description:
      "Tap on a variable to choose a different variable and replace the current one. You can simply hit Backspace to remove a variable. Go on, try yourself!",
    icon: isDesktop ? DripCarouselDesktop4 : DripCarouselMobile4,
  },
];

export const getEmailScheduleText = ({
  email_frequency_type,
  magnitude,
  drip_trigger_type,
  drip_schedule_time,
  schedule,
}) => {
  const scheuleTime = drip_schedule_time?.toLowerCase();
  const isScheduleBeforeEvent = schedule === EMAIL_SCHEDULE.BEFORE_EVENT;

  const isFrequencyDayWise = email_frequency_type === DRIP_FREQUENCY.DAYS;

  if (isFrequencyDayWise) {
    return magnitude === 0
      ? DRIP_TRIGGERS_MESSAGES[drip_trigger_type]
      : `${magnitude} days from ${DRIP_TRIGGERS_MESSAGES[drip_trigger_type]}, at ${scheuleTime}`;
  }

  const hours = magnitude === 0 ? "Right" : `${magnitude} hours`;

  return `${hours} ${
    isScheduleBeforeEvent
      ? "before each session starts"
      : "after each session ends"
  }`;
};

export const getVariableUsedInEmail = ({
  emailBody = "",
  emailSubject,
  allowedVariables,
}) => {
  const variablesUsedInEmail = new Set();
  const regex = /{{([^}]*)}}/g;
  const emailBodyMatches = emailBody.matchAll(regex);
  const emailSubjectMatches = emailSubject.matchAll(regex);
  [emailSubjectMatches, emailBodyMatches].forEach((matches) => {
    for (const match of matches) {
      const [, currentVariable] = match;
      const isVariableAllowed = allowedVariables.some(
        ({ key }) => key === currentVariable
      );

      if (isVariableAllowed) variablesUsedInEmail.add(currentVariable);
    }
  });
  return [...variablesUsedInEmail];
};

export const getDripReviewFrequencyText = (isFrequencyDayWise, schedule_time) =>
  isFrequencyDayWise
    ? `Day wise: Each email will be sent after certain number of days from when the offering starts, at ${schedule_time?.toLowerCase()} sharp`
    : "Session wise: Each email will be sent before or after each session in the offering";
