import { Box, Popover } from '@material-ui/core';
import React, { useState } from 'react';
import {
  getValueFromOptions,
  toggleBlock,
  toggleMark,
} from '../../utils/SlateUtilityFunctions';
import { useStyles } from '../Toolbar.styles';
import classnames from 'classnames';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import { Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

interface CustomDropdownProps {
  options: any[];
  editor: any;
  placeholder?: any;
  className?: string;
  minWidth?: string;
}

export const CustomDropdown: React.FC<CustomDropdownProps> = ({
  options,
  editor,
  placeholder,
  minWidth,
  className = '',
  ...restProps
}) => {
  const classes = useStyles({ minWidth });

  const { selection } = editor;

  const [open, setOpen] = useState(false);
  const [anchroEl, setAnchroEl] = useState(null);
  const [editorSelection, setEditorSelection] = useState(null);

  const setFocus = (selection: any) => {
    if (selection)
      setTimeout(() => {
        if (Range.isCollapsed(selection)) {
          Transforms.select(editor, selection);
          ReactEditor.focus(editor);
        }
      }, 50);
  };

  const handleOpen = (e) => {
    e.preventDefault();
    setEditorSelection(selection);
    setAnchroEl(e.currentTarget);
    setOpen(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setAnchroEl(null);
    setOpen(false);
    setFocus(editorSelection);
  };

  const selected = getValueFromOptions(editor, options);

  return (
    <Box className={[classes.complexDropdown, className].join(' ')}>
      <Box className={classes.dropdownLabel} onMouseDown={handleOpen}>
        {selected?.label || placeholder}
        <ArrowDropDownRoundedIcon className={open && classes.dropdownOpen} />
      </Box>
      <Popover
        open={open}
        anchorEl={anchroEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {options.map(({ label, value }) => (
          <Box
            className={classnames(
              classes.customDropdownOption,
              selected?.value.format === value.format && classes.selectedOption
            )}
            onMouseDown={(e) => {
              e.preventDefault();

              if (!value) return;

              if (value.type === 'block') toggleBlock(editor, value.format);
              else toggleMark(editor, value.format);

              setOpen(false);
            }}
          >
            {label}
          </Box>
        ))}
      </Popover>
    </Box>
  );
};
