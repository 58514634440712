import { IconButton } from '@material-ui/core';
import { FC } from 'react';
import { IToolbarButton } from './ToolbarButton.interfaces';
import { useStyles } from './ToolbarButton.styles';

export const ToolbarButton: FC<IToolbarButton> = ({
  active,
  children,
  className,
  ...props
}) => {
  const classes = useStyles();
  return (
    <IconButton
      className={[classes.root, active ? classes.active : '', className].join(
        ' '
      )}
      {...props}
    >
      {children}
    </IconButton>
  );
};
