import React from "react";
import {
  assignCustomMember,
  getCustomColumns,
} from "ui/pages/schedule/BookedSession/BookedSession.helpers";
import { is_empty } from "features/Common/utils/common.utils";
import { getCustomColumnTableRenderConfig } from "./CustomColumn.utils";
import useColumnsToShow from "features/Crm/utils/hooks/useColumnsToShow";
import { useCrmStore } from "features/Crm/store/useCrmStore";
import { useDesktopMediaQuery } from "features/Common/hooks/useDesktopMediaQuery";

export const useCustomColumnsConfig = ({ sectionName, recordSlug }) => {
  const isDesktop = useDesktopMediaQuery();
  const setCustomColumnsMasterObj = useCrmStore(
    (state) => state.setCustomColumnsMasterObj
  );
  const { columnsToShow = {} } = useColumnsToShow({
    getColumnsHelper: () => getCustomColumns(sectionName),
    onSuccess: (value) =>
      setCustomColumnsMasterObj({
        value: value?.custom_columns,
        featureKey: sectionName,
      }),
  });
  const { custom_columns: customColumns = [] } = columnsToShow;

  const customColumnsHeaders = customColumns.map((obj) => obj.column_name);

  const appendCustomTableConfig = ({ orgMembers, disabled }) => {
    const customColumnsToRender = [];
    const customFiltersToAppend = [];
    const customChipsToFormat = [];

    customColumns.forEach((customColumnItem) => {
      const columnSlug = customColumnItem.column_slug;
      const columnName = customColumnItem.column_name;

      const {
        // filterProps,
        filterType,
        disableContains,

        // tableFieldProps
        Component,
        options,
        columnWidth,
        inputClass,
      } = getCustomColumnTableRenderConfig({
        type: customColumnItem.content_type ?? customColumnItem.data_type,
        orgMembers,
        columnOptions: customColumnItem.metadata?.options,
        isDesktop,
      });

      customColumnsToRender.push({
        field: columnSlug,
        headerName: columnName,
        ...(columnWidth && { width: columnWidth }),
        valueFormatter: (record) => {
          const targetObject = record[recordSlug];
          const columnData = record[columnSlug] || {}; // check
          const defaultValue = customColumnItem.content_type
            ? columnData.data?.[columnData.displayable_fields]
            : record[columnSlug];

          return (
            <Component
              options={options}
              targetObject={targetObject}
              defaultValue={defaultValue}
              handleUpdate={(e) =>
                assignCustomMember({
                  value: e,
                  targetObject,
                  customColumnUuid: customColumnItem.uuid,
                  columnSlug,
                })
              }
              disabled={disabled}
              columnWidth={columnWidth}
              inputClass={inputClass}
            />
          );
        },
      });

      customFiltersToAppend.push({
        source: columnSlug,
        placeholder: columnName,
        type: filterType,
        disableContains,
        hidden: !customColumnItem.is_filter_applicable,
        ...(!is_empty(options) && { options: options }),
      });

      customChipsToFormat.push({
        columnSlug: columnSlug,
        columnName: columnName,
        options,
      });
    });

    const customFieldToRenderInDetails = customColumnsToRender;

    return {
      /** columns to render in table */
      customColumnsToRender,
      /** columns to add in filter options in table */
      customFiltersToAppend,
      /** chips to form when selected in table filter */
      customChipsToFormat,
      /** custom column fields to render in details page */
      customFieldToRenderInDetails,
    };
  };

  const getCustomColumnsToExport = (data) => {
    const customColumnsExport = customColumns.reduce((result, item) => {
      const columnDataToExport = [];

      if (data[item.column_slug]?.exportable_fields)
        data[item.column_slug].exportable_fields.forEach((field) =>
          columnDataToExport.push(`${data[item.column_slug]?.data[field]}`)
        );
      result[item.column_name] = columnDataToExport.join(" ");

      return result;
    }, {});
    return customColumnsExport;
  };

  return {
    appendCustomTableConfig,
    getCustomColumnsToExport,
    customColumnsHeaders,
  };
};
