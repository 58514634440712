import { FC } from 'react';
import { convertStringToCSSObject } from '../../../../utils/RTEEditable.utils';

export const ParagraphElement: FC<any> = (props) => {
  const { element, children } = props;
  const attributes = props.attributes ?? {};

  return (
    <p
      {...element.attr}
      {...attributes}
      style={{
        margin: 0,
        minHeight: '18px',
        ...element?.styles,
        ...convertStringToCSSObject(element?.style?.value),
      }}
    >
      {children}
    </p>
  );
};
