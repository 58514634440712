import { RTE_NODE_TYPES } from '../../../../RTEEditable/constants/RTEEditable.constants';

export const HEADING_TOOL_OPTIONS = [
  {
    id: '1',
    label: 'Normal',
    value: { type: 'block', format: 'normal' },
  },
  { id: '2', label: 'H1', value: { type: 'block', format: RTE_NODE_TYPES.H1 } },
  { id: '3', label: 'H2', value: { type: 'block', format: RTE_NODE_TYPES.H2 } },
  { id: '4', label: 'H3', value: { type: 'block', format: RTE_NODE_TYPES.H3 } },
  { id: '5', label: 'H4', value: { type: 'block', format: RTE_NODE_TYPES.H4 } },
  { id: '6', label: 'H5', value: { type: 'block', format: RTE_NODE_TYPES.H5 } },
  { id: '7', label: 'H6', value: { type: 'block', format: RTE_NODE_TYPES.H6 } },
  {
    id: '8',
    label: 'Blockquote',
    value: { type: 'block', format: RTE_NODE_TYPES.BLOCKQUOTE },
  },
  { id: '9', label: 'Code', value: { type: 'mark', format: 'code' } },
];
