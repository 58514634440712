import React from "react";
import cssStyles from "./LongTextFieldValue.module.css";
import { EditOutlined } from "@material-ui/icons";
import { useLongTextFieldValueModal } from "../LongTextFieldValueModal/utils/useLongTextFieldValueModal";
import classnames from "classnames";

export const LongTextFieldValue = ({
  charLimit = 100,
  targetObject,
  value = "",
  wrapperClass = "",
  disabled,
}) => {
  const { openLongTextValueModal } = useLongTextFieldValueModal();
  if (!value) return "-";

  const shouldTruncate = value.length > charLimit;
  return (
    <div
      className={classnames(
        cssStyles.wrapper,
        disabled && cssStyles.disabledIcon,
        wrapperClass
      )}
    >
      <p
        className={classnames(
          cssStyles.text,
          disabled && cssStyles.inputDisabled
        )}
      >
        {!shouldTruncate ? (
          value
        ) : (
          <>
            {value.slice(0, charLimit)}...
            <span
              className={cssStyles.seeMore}
              onClick={() => openLongTextValueModal(targetObject)}
            >
              see more
            </span>
          </>
        )}
      </p>
      <div
        className={classnames(
          cssStyles.iconWrapper,
          disabled && cssStyles.disabledIcon
        )}
        onClick={() => !disabled && openLongTextValueModal(targetObject)}
      >
        <EditOutlined className={cssStyles.icon} />
      </div>
    </div>
  );
};
