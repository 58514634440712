import React from "react";
import { format } from "date-fns";
import ExlySpinner from "ui/modules/ExlySpinner/ExlySpinner";
import { DatePicker } from "@my-scoot/component-library-legacy";
import { useCustomColumnUpdate } from "../../CustomColumn/utils/useCustomColumnUpdate";
import { convertStringToDateObject } from "features/Common/modules/DateTime/utils/DateTime.utils";
import { YEAR_MONTH_DAY_FORMAT } from "features/Common/modules/DateTime/DateTime.constants";
import styles from "./DateField.module.css";
import classnames from "classnames";

export const DateField = ({
  targetObject,
  handleUpdate,
  defaultValue,
  disabled,
}) => {
  const { loading, selectedValue, handleUpdateColumnData } =
    useCustomColumnUpdate({
      handleUpdate,
      targetObject,
      defaultValue,
    });

  if (loading) return <ExlySpinner loaderWidth="24px" wrapperPadding="0 0" />;

  return (
    <DatePicker
      placeholder="Select date"
      value={
        selectedValue
          ? convertStringToDateObject({
              dateString: selectedValue,
            })
          : null
      }
      disabled={disabled}
      onChange={(date) => {
        handleUpdateColumnData(format(date, YEAR_MONTH_DAY_FORMAT));
      }}
      wrapperClassName={classnames(
        styles.datePicker,
        disabled && styles.datePickerDisabled
      )}
      popperClassName={styles.datePickerPopperClassName}
      popperPlacement={"bottom-end"}
      size="thin"
      fullWidth
    />
  );
};
