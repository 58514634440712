import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  {
    root: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
    },
  },
  {
    name: 'RTEToolbar',
  }
);
