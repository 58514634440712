import React, { PureComponent } from "react";
import "react-image-crop/dist/ReactCrop.css";
import Unsplash, { toJson } from "unsplash-js";
import Modal from "@material-ui/core/Modal";
import styles from "./style.module.css";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import ReactCrop from "react-image-crop";
import firebase from "firebase";
import { is_empty } from "../../../utils/validations";
import { generateRandomString } from "../../../utils/Utils";
import ImageIcon from "@material-ui/icons/Image";

import { StyledDemo } from "./photoZoom";
import {
  preProcessB64ImageForS3Upload,
  uploadImageToExlyBucket,
} from "features/Common/modules/ExlyImage/utils/ExlyImage.utils";
import { logError } from "utils/error";
import {
  file_format_keys,
  file_mime_types,
} from "features/Common/modules/File/File.constants";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(config);

const storage = firebase.storage();

const unsplash = new Unsplash({
  accessKey: process.env.REACT_APP_UNSPLASH_ACCESSS_KEY,
  secret: process.env.REACT_APP_UNSPLASH_SECRET,
});

class RightPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.timeout = 0;
  }

  state = {
    src: "",
    crop: {
      unit: "%",
      width: 100,
      aspect: 16 / 9,
    },
    zoom: 1,
    showModal: false,
    showOriginal: true,
    modalState: false,
    imageWidth: 100,
    imageHeight: 100,
    borderRadius: "50%",
    minWidth: 160,
    minHeight: 100,
    croppedFile: "",
    blobFile: undefined,
    query: "",
    pics: [],
    isFetchingImages: false,
    isUploading: false,
  };

  componentDidMount() {
    document.querySelector("#stock_search").onkeypress = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    };

    this.searchPhotos(this.props.query);

    this.setState({
      src: this.props.image,
      original: this.props.image,
      zoom: this.props.zoom,
      crop: this.props.crop,
      showModal: false,
      showOriginal: true,
      modalState: false,
      imageWidth: this.props.width,
      imageHeight: this.props.height,
      borderRadius: this.props.radius,
      minWidth: this.props.minWidth,
      minHeight: this.props.minHeight,
      query: "",
    });

    if (this.props.existingimage) {
      this.setState({
        src: this.props.imageexits,
        showModal: true,
        modalState: true,
      });
    }
  }

  onFileSelected = (e) => {
    if (this.props.checkImageSizeExceeds(e)) return;
    this.setState({
      showModal: true,
      modalState: true,
    });
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState({ file: e.target.files[0] });
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        this.props.image
      );
      this.setState({ croppedImageUrl });
    }
  }

  closeModal = (croppedImage, croppedurl) => {
    let imageData = this.state.croppedImageUrl;
    if (croppedImage) {
      imageData = croppedImage;
      this.setState({
        croppedImageUrl: croppedImage,
      });
    }
    if (imageData === undefined) {
      imageData = this.state.src;
    }
    if (croppedurl) {
      imageData = croppedurl;
    } else {
      imageData = this.state.croppedFile;
    }

    if (is_empty(imageData)) {
      return;
    }

    this.setState({ isUploading: true });
    if (this.props.uploadViaOptimization) {
      /**
       * Added default values for fileName and type when selecting from splash.
       * PNG has been set has default type as in cropping image a
       * new image is change to png format.
       */
      const fileName = this.state.file?.name || `${Date.now()}.png`;
      const fileType =
        this.state.file?.type || file_mime_types[file_format_keys.png][0];
      let processedFile = preProcessB64ImageForS3Upload(imageData);
      if (this.state.blobFile) {
        processedFile = this.state.blobFile;
      }
      uploadImageToExlyBucket({
        fileName: fileName,
        fileType: fileType,
        file: processedFile,
        sectionName: this.props.sectionName,
      }).then((publicImageUrl) => {
        this.setState({
          showModal: false,
          showOriginal: false,
          modalState: false,
          isUploading: false,
        });
        this.props.getUrl(publicImageUrl);
        this.setState({ croppedImageUrl: publicImageUrl });
      });
    } else {
      let randomText = generateRandomString();
      let filename = imageData.toString().substring(9, 18) + randomText;
      let storageRef = storage.ref("/exly");
      let fileRef = storageRef.child(filename).putString(imageData, "data_url");
      fileRef.on(
        "state_changed",
        function () {
          /**
           * TODO: figure out what is the purpose of this argument
           * OLD function definition that wasn't doing anything
           * ```
              function (snapshot) {
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              }
           * ```
           */
        },
        function (error) {
          logError({
            error: error?.body?.message,
            occuredAt:
              "upload images to firebase in src/ui/widgets/photoUploader/RightPanel.js",
            when: "Error while uploading image to firebase",
          });
        },
        () => {
          fileRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.setState({
              showModal: false,
              showOriginal: false,
              modalState: false,
              isUploading: false,
            });
            this.props.getUrl(downloadURL);
            this.setState({ croppedImageUrl: downloadURL });
          });
        }
      );
    }
  };

  closeModalWithoutSaving = () => {
    this.setState({
      showModal: false,
      src: this.props.original,
      showOriginal: true,
      crop: this.props.crop,
      modalState: false,
    });
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width * scaleX);
    canvas.height = Math.ceil(crop.height * scaleY);

    image.setAttribute("crossOrigin", "Anonymous");

    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    this.setState({
      croppedFile: canvas.toDataURL("image/png"),
    });

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error("Canvas is empty");
            return;
          }
          this.setState({ blobFile: blob });
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);

          resolve(this.fileUrl);
        },
        "image/png",
        1
      );
    });
  }

  searchPhotos = async (searchText) => {
    try {
      if (is_empty(searchText)) {
        searchText = is_empty(this.props.query) ? "Random" : this.props.query;
      }
      this.setState({ isFetchingImages: true });

      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        unsplash.search
          .photos(searchText, 1, 20, { orientation: "landscape" })
          .then(toJson)
          .then((json) => {
            this.setState({ pics: json.results });
            this.setState({ isFetchingImages: false });
          })
          .catch((err) => {
            console.error(
              "error occured while calling unsplash.search in RightPanel",
              err
            );
          });
      }, 200);
    } catch (err) {
      console.error("searchPhotos err", err);
    }
  };

  readChildData = (e) => {
    let croppedImageUrl = e.urls.regular;
    this.setState({ src: croppedImageUrl });
    this.setState({ showModal: true });
    this.setState({ modalState: true });
  };

  render() {
    const { crop, src } = this.state;

    if (this.props.uploadViaOptimization && !this.props.sectionName) {
      throw new Error(
        "sectionName is empty. sectionName is needed for the RightPanel to work when uploadViaOptimization is true"
      );
    }

    return (
      <div className={styles.drawerWidth}>
        <p className={styles.titleText}>Select / Upload Image</p>

        <div className={styles.upload_device_background}>
          <ImageIcon style={{ color: "#6c5dd3", fontSize: 54 }} />

          <span className={styles.button}>Upload from Device</span>

          <input
            type="file"
            accept="image/x-png,image/jpg,image/jpeg"
            onChange={this.onSelectFile}
            onChangeCapture={this.onFileSelected}
            alt="image crop"
            style={{
              height: "250px",
              cursor: "pointer !important",
              opacity: 0,
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
            }}
          />
        </div>

        <br />
        <div
          className={styles.horizontalContainer}
          style={{ alignItems: "center" }}
        >
          &bull;&nbsp;
          <div style={{ fontSize: "16px" }}> OR </div>
          &nbsp;&bull;
        </div>
        <br />

        {this.props.from === "congratulatepopup" && this.state.showModal ? (
          <Modal open={this.state.modalState} disableEnforceFocus>
            <StyledDemo
              image={this.props.image}
              src={src}
              isUploading={this.state.isUploading}
              closeModalWithoutSaving={this.closeModalWithoutSaving}
              closeModal={this.closeModal}
            />
          </Modal>
        ) : (
          this.state.showModal && (
            <Modal open={this.state.modalState} disableEnforceFocus>
              <div className="alert_modal modal_overflow">
                <div className={styles.cropperBackground}>
                  <div className={styles.cropperStyle}>
                    <ReactCrop
                      src={src}
                      onDragStart={() => {
                        let temp = crop;
                        if (
                          this.props.fluidAspectRatio &&
                          temp.aspect != undefined
                        ) {
                          temp.aspect = undefined;
                          this.setState({ crop: temp });
                        }
                      }}
                      crop={crop}
                      ruleOfThirds
                      minHeight={this.props.imageHeight}
                      minWidth={this.props.imageWidth}
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                      className={styles.cropper}
                      crossorigin={"Anonymous"}
                    ></ReactCrop>
                  </div>
                </div>
                <div className={styles.openCloseModal}>
                  {this.state.isUploading ? null : (
                    <div
                      onClick={this.closeModalWithoutSaving}
                      className={styles.cancelText}
                    >
                      Cancel
                    </div>
                  )}

                  {this.state.isUploading ? (
                    <div
                      className={`${styles.cropText} ${styles.horizontalContainer}`}
                    >
                      <span>Uploading</span>
                      <div className={styles.loader}> </div>
                    </div>
                  ) : (
                    <div onClick={this.closeModal} className={styles.cropText}>
                      <span>Crop Image</span>
                    </div>
                  )}
                </div>
              </div>
            </Modal>
          )
        )}

        <form autoComplete="off" className={styles.headerText}>
          <input
            type="text"
            id="stock_search"
            name="query"
            className={styles.searchInput}
            placeholder={`Search for stock images`}
            value={this.state.query}
            onChange={(e) => {
              this.searchPhotos(e.target.value);
              this.setState({ query: e.target.value });
            }}
          />
        </form>

        <span className={styles.credit}>
          <b>Results from</b> Unsplash
        </span>

        {this.state.isFetchingImages && (
          <div>
            {" "}
            <h3>Loading...</h3>
          </div>
        )}

        {!this.state.isFetchingImages &&
          (is_empty(this.state.pics) ? (
            <div>
              <br />
              <h5>Search for relevant keywords for suggestions</h5>
            </div>
          ) : (
            <GridList cols={2}>
              {this.state.pics.map((pic) => (
                <GridListTile
                  key={pic.id}
                  onClick={() => this.readChildData(pic)}
                >
                  <img
                    className={styles.cardImage}
                    alt={pic.alt_description}
                    src={pic.urls.regular}
                    width="200px"
                    height="100%"
                    crossOrigin="Anonymous"
                  />
                </GridListTile>
              ))}
            </GridList>
          ))}
      </div>
    );
  }
}

export default RightPanel;

RightPanel.defaultProps = {
  checkImageSizeExceeds: () => {},
};
