import { IconButton, makeStyles, IconButtonProps } from '@material-ui/core';
import React from 'react';
import classnames from 'classnames';

const useStyles = makeStyles((theme: any) => ({
  iconBtn: {
    borderRadius: '5px',
    padding: '4px',
    color: "#000",
    '&:hover': {
      background: theme?.palette?.secondary?.shade_200,
      border: 'none',
    },

    '& svg': {
      height: '16px',
      width: '16px',
    }
  },
  active: {
    background: theme?.palette?.primary?.shade_200,
    color: theme?.palette?.primary?.main,
  },
}));

interface ButtonProps extends IconButtonProps {
  className?: string;
  format?: string;
  active?: boolean;
  children?: React.ReactElement;
}

const Button = (props: ButtonProps) => {
  const { children, className, format, active, type, ...rest } = props;
  const classes = useStyles();

  return (
    <IconButton
      className={classnames(
        classes.iconBtn,
        active && classes.active,
        className
      )}
      title={format}
      {...rest}
    >
      {children}
    </IconButton>
  );
};

export default Button;
