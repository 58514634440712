import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';

import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import { CheckRounded as CheckIcon } from '@material-ui/icons';
import classnames from 'classnames';

import Button from '../../../../atoms/Button/Button';
import Avatar from '../../../../atoms/Avatar/Avatar';
import {
  IDrawerConfig,
  IColumn,
  IActions,
} from '../MobileTableDrawer/MobileTableDrawer';
import useStyles from './MobileTableRecord.styles';
import useLongPress from '../../utils/useLongPress';

export interface IPrimaryAction {
  label?: string;
  renderCustomAction?: (
    row: any,
    rows: any[],
    setDrawerConfig: any,
    setActionsDrawerConfig?: any,
    rowIndex?: number
  ) => React.ReactNode;
  onClick?: (
    row: any,
    rows: any[],
    setDrawerConfig: any,
    setActionsDrawerConfig?: any,
    rowIndex?: number
  ) => void;
  onClose?: (
    row: any,
    rows: any[],
    setDrawerConfig: any,
    setActionsDrawerConfig?: any,
    rowIndex?: number
  ) => void;
}

export interface IActionsDrawerConfig {
  row: any;
  open: false;
}

export interface IMobileTableRecord {
  row: any;
  rows: any[];
  rowIndex: number;
  columns: IColumn[];
  backgroundVariant: 'light' | 'dark';
  borderedFields?: boolean;
  fieldsLeftPadded?: boolean;
  fieldsAlignment?: 'start' | 'space-between';
  isEditable?: boolean;
  isDeletable?: boolean;
  selected?: boolean;
  actions?: IActions;
  rowClassName?: string;
  renderPrimaryColumnRightNode?: (
    row: any,
    rows: any[],
    openDrawer: any,
    rowIndex: number
  ) => React.ReactNode;
  primaryActions?: IPrimaryAction[];
  customPrimaryAction?: IPrimaryAction;
  renderSecondaryAction?: (
    row: any,
    rows: any[],
    setDrawerConfig: any,
    rowIndex?: number
  ) => React.ReactNode;
  recordFooterClassName?: string;
  recordFooterVariant: 'primary' | 'secondary';
  getRecordFooterClassName?: (row: any) => string;
  openDetailOnLoadRecordId?: any;
  onRowSelection?: Function;
  getRecordClassName?: (row: any, rows: any[]) => string;
  checkboxSelection?: boolean;
  setDrawerConfig: React.Dispatch<React.SetStateAction<IDrawerConfig>>;
  setActionsDrawerConfig: React.Dispatch<
    React.SetStateAction<IActionsDrawerConfig>
  >;
  onOpenEditDrawer: (row: any, rowIndex: number) => void;
}

const MobileTableRecord: React.FC<IMobileTableRecord> = ({
  row,
  rows,
  columns,
  backgroundVariant,
  borderedFields,
  fieldsLeftPadded,
  fieldsAlignment = 'start',
  isEditable,
  isDeletable,
  rowIndex,
  rowClassName,
  renderPrimaryColumnRightNode,
  primaryActions,
  customPrimaryAction,
  renderSecondaryAction,
  recordFooterClassName,
  recordFooterVariant = 'primary',
  openDetailOnLoadRecordId,
  getRecordFooterClassName,
  onRowSelection,
  getRecordClassName,
  checkboxSelection = false,
  setDrawerConfig,
  onOpenEditDrawer,
  setActionsDrawerConfig,
  selected,
}) => {
  const longPressHookData = useLongPress(
    {
      onLongPress: () => {
        onRowSelection?.(row);
      },
    },
    { shouldPreventDefault: false, delay: 1000 }
  );
  const longPress = checkboxSelection && longPressHookData;

  useEffect(() => {
    const uniqueColumn: IColumn | undefined = columns?.find(
      (column) => column.isUnique
    );
    if (
      uniqueColumn?.field &&
      openDetailOnLoadRecordId &&
      row[uniqueColumn?.field] == openDetailOnLoadRecordId
    ) {
      setDrawerConfig({ drawerActionType: 'view', open: true, row, rowIndex });
    }
  }, []);

  let primaryColumn: undefined | IColumn;
  let secondaryColumn: undefined | IColumn;
  const expandedColumns = [] as IColumn[];
  const collapsedColumns = [] as IColumn[];

  const classes = useStyles({
    backgroundVariant,
    borderedFields,
    additionalAction: Boolean(renderSecondaryAction),
    fieldsLeftPadded,
    selected,
  });

  columns?.forEach((column) => {
    if (column.isPrimary) primaryColumn = column;
    else if (column.isSecondary) secondaryColumn = column;
    else if (!column.isCollapsed) expandedColumns.push(column);
    else collapsedColumns.push(column);
  });

  const showFooter: boolean = Boolean(
    renderSecondaryAction || primaryActions?.length || customPrimaryAction
  );

  const onSelectRow = () => {
    onRowSelection?.(row);
  };

  // const moreActions = (isEditable || isDeletable) && (
  //   <IconButton
  //     className={classes.moreActionIconButton}
  //     // onClick={onOpenMoreActionsPopover}
  //   >
  //     <MoreHorizRoundedIcon />
  //   </IconButton>
  // );

  const renderAction = (currentAction: IPrimaryAction) =>
    currentAction.renderCustomAction ? (
      currentAction.renderCustomAction(
        row,
        rows,
        setDrawerConfig,
        setActionsDrawerConfig,
        rowIndex
      )
    ) : (
      <Button
        size="thin"
        color="link"
        variant="text"
        disableTouchRipple={true}
        className={classes.seeMoreBtn}
        classes={{ label: classes.seeMoreButtonLabel }}
        onClick={(e: Event) => {
          e.stopPropagation();
          currentAction.onClick?.(
            row,
            rows,
            setDrawerConfig,
            setActionsDrawerConfig,
            rowIndex
          );
        }}
        // Stop Event Propogation in case of checkoxSelection
        onMouseDown={(e: Event) => e.stopPropagation()}
        onTouchStart={(e: Event) => e.stopPropagation()}
        onMouseUp={(e: Event) => e.stopPropagation()}
        onMouseLeave={(e: Event) => e.stopPropagation()}
        onTouchEnd={(e: Event) => e.stopPropagation()}
      >
        <span>{currentAction.label}</span>
        <ChevronRightRoundedIcon />
      </Button>
    );

  return (
    <div
      {...longPress}
      className={classnames(
        classes.container,
        rowClassName,
        getRecordClassName?.(row, rows)
      )}
      // @dev - assuming only single table is rendered in a page with the same data
      // In future if the case arises for multiple tables with same data to be rendered on same page, we will need to add table specific prefix to the id to avoid collision.            
      id={row.id}
    >
      {primaryColumn && (
        <div
          className={classnames(
            classes.primarySection,
            // moreActions && classes.withMoreActions,
            primaryColumn.columnClassName
          )}
        >
          {primaryColumn.renderColumn ? (
            primaryColumn.renderColumn(row)
          ) : (
            <div className={classes.primaryKeyLeftSection}>
              <Avatar
                className={selected && classes.selectedAvatar}
                label={primaryColumn && !selected && row[primaryColumn.field]}
                icon={selected && <CheckIcon />}
                colorIndex={rowIndex}
                onClick={checkboxSelection && onSelectRow}
              />
              <div
                className={classnames(classes.primaryWrapper, 'primaryWrapper')}
              >
                <div className={classnames(classes.primaryKey, 'primaryKey')}>
                  {primaryColumn.valueFormatter
                    ? primaryColumn.valueFormatter(row)
                    : row[primaryColumn.field] ?? primaryColumn.emptyField}
                </div>
                {secondaryColumn && (
                  <div
                    className={classnames(
                      classes.secondaryKey,
                      secondaryColumn.columnClassName
                    )}
                  >
                    {secondaryColumn.renderColumn
                      ? secondaryColumn.renderColumn(row)
                      : secondaryColumn.valueFormatter
                      ? secondaryColumn.valueFormatter(row)
                      : row[secondaryColumn.field] ??
                        secondaryColumn.emptyField}
                  </div>
                )}
              </div>
            </div>
          )}
          {renderPrimaryColumnRightNode?.(row, rows, setDrawerConfig, rowIndex)}
          {/* {moreActions} */}
        </div>
      )}
      <div className={classnames(classes.importantFields)}>
        {expandedColumns.map((column, columnIndex) => {
          const {
            headerName,
            field,
            renderColumn,
            valueFormatter,
            isEditable,
            columnClassName,
            emptyField,
          } = column;

          if (column.hidden) {
            return null;
          }

          return (
            <div
              className={classnames(
                classes.importantField,
                fieldsAlignment === 'space-between' &&
                  classes.spaceBetweenAlignment,
                columnClassName
              )}
              key={`table__${field}__${headerName}`}
            >
              {renderColumn ? (
                renderColumn(row)
              ) : (
                <>
                  <span
                    className={classnames(classes.importantFieldLabel, 'label')}
                  >
                    {headerName}:
                  </span>

                  <span className="value">
                    {valueFormatter
                      ? valueFormatter(row)
                      : row[field] ?? emptyField}
                  </span>

                  {isEditable && (
                    <IconButton
                      className={classes.editIconBtn}
                      onClick={() => onOpenEditDrawer(row, rowIndex)}
                    >
                      <CreateRoundedIcon viewBox="3 3 18 18" />
                    </IconButton>
                  )}
                </>
              )}
              {/* {!primaryColumn && columnIndex === 0 && moreActions} */}
            </div>
          );
        })}
      </div>

      {showFooter && (
        <div
          className={classnames(
            classes.footer,
            recordFooterVariant === 'secondary' && classes.secondary__footer,
            recordFooterClassName,
            getRecordFooterClassName?.(row)
          )}
        >
          {renderSecondaryAction?.(row, rows, setDrawerConfig, rowIndex)}

          {primaryActions?.length === 1
            ? renderAction(primaryActions[0])
            : null}

          {customPrimaryAction ? renderAction(customPrimaryAction) : null}
        </div>
      )}
    </div>
  );
};

export default MobileTableRecord;
