import { useCrmStore } from "features/Crm/store/useCrmStore";

export const useLongTextFieldValueModal = () => {
  const longTextValueModalOpen = useCrmStore(
    (state) => state.longTextValueModalOpen
  );
  const setLongTextValueModalOpen = useCrmStore(
    (state) => state.setLongTextValueModalOpen
  );

  const openLongTextValueModal = (value) => {
    setLongTextValueModalOpen(value);
  };

  const closeLongTextValueModal = () => {
    setLongTextValueModalOpen("");
  };

  return {
    longTextValueModalOpen,
    closeLongTextValueModal,
    openLongTextValueModal,
  };
};
