import { FC } from 'react';

export const HeadingElement: FC<any> = (props) => {
  const { element, children } = props;
  const { type } = element;
  const HeadingTag = type;
  const attributes = props.attributes ?? {};

  return (
    <HeadingTag {...attributes} {...element.attr}>
      {children}
    </HeadingTag>
  );
};
