/**
 * @param key relative path for the images uploaded by creator
 * @returns public url for the asset
 */
export const getOptimisedS3AssetCDNUrl = (key: string) => {
  return `${process.env.REACT_APP_EXLY_CONTENT_IMAGES_CDN_URL}/${key}`;
};

/**
 * @param key relative path for the static asset of creator tool
 * @returns public url for the asset
 * Note : assets to be kept in public folder are uploaded here.
 */
export const getS3AssetCDNUrl = (key: string) => {
  return `${process.env.REACT_APP_EXLY_CONTENT_CDN_URL}/creator-tool/${key}`;
};

/**
 * @param key relative path for the uploaded file at S3 cdn bucket
 * @returns public url for the uploaded file
 *
 * Note: use this for assets that are uploaded to the bucket but dont belong to creator tool
 */
export const getS3CDNUrl = (key: string) => {
  return `${process.env.REACT_APP_EXLY_CONTENT_CDN_URL}/${key}`;
};
