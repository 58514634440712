import { FC } from 'react';

export const TableCellElement: FC<any> = (props) => {
  const { element, children } = props;
  const attributes = props.attributes ?? {};

  return (
    <td
      {...element.attr}
      {...attributes}
      style={{
        border: '1px solid #272522',
        height: '50px',
        padding: '0 5px',
        overflowWrap: 'anywhere',
      }}
    >
      {children}
    </td>
  );
};
