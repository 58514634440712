import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  {
    root: {
      width: 0.5,
      backgroundColor: 'white',
      alignSelf: 'stretch',
    },
  },
  {
    name: 'ToolsGroupDivider',
  }
);
