import { useState, useEffect } from "react";
import { logError } from "utils/error";

const useColumnsToShow = ({ getColumnsHelper, onSuccess = () => {} }) => {
  const [columnsToShow, setColumnsToShow] = useState([]);

  const getColumnsToShow = () => {
    getColumnsHelper()
      .then((response) => {
        const data = response?.data;
        // DEV - only set data incase of data is defined.
        if (data) {
          onSuccess(data);
          setColumnsToShow(data);
        }
      })
      .catch((error) => {
        logError({
          error,
          occuredAt: "src/hooks/useColumnsToShow.js",
          when: "calling getColumnsToShow",
        });
      });
  };

  /**
   * Component Mounting functions
   */
  useEffect(() => {
    getColumnsToShow();
  }, []);

  return {
    columnsToShow,
  };
};

export default useColumnsToShow;
