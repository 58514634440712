import { FC } from 'react';

export const DefaultElement: FC<any> = (props) => {
  const { element, children } = props;
  const attributes = props.attributes ?? {};

  return (
    <p
      {...element.attr}
      {...attributes}
      style={{
        margin: 0,
        minHeight: '18px',
      }}
    >
      {children}
    </p>
  );
};
