import FormatListNumberedRoundedIcon from '@material-ui/icons/FormatListNumberedRounded';
import { useSlate } from 'slate-react';
import {
  isBlockActive,
  toggleBlock,
} from '../../../../../RichTextEditorV2/utils/SlateUtilityFunctions';
import { RTE_TOOLBAR_BLOCKS } from '../../constants/RTEToolbar.blocks';
import { ToolbarButton } from '../ToolbarButton/ToolbarButton';
import { IToolbarButton } from '../ToolbarButton/ToolbarButton.interfaces';

export const OrderedListTool = () => {
  const editor = useSlate();

  const handleToggleUnorderedList: IToolbarButton['onMouseDown'] = (e) => {
    e.preventDefault();
    toggleBlock(editor, RTE_TOOLBAR_BLOCKS.ORDERED_LIST);
  };

  return (
    <ToolbarButton
      active={isBlockActive(editor, RTE_TOOLBAR_BLOCKS.ORDERED_LIST)}
      onMouseDown={handleToggleUnorderedList}
    >
      <FormatListNumberedRoundedIcon />
    </ToolbarButton>
  );
};
