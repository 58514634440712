import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(
  (theme: any) => ({
    separatorBox: {
      background: theme?.palette?.basic?.white,
      width: '1.5px',
      height: '20px',
    },
  }),
  { name: 'ComponentLibrary' }
);

const Divider: React.FC = () => {
  const classes = useStyles();

  return <Box className={classes.separatorBox} />;
};

export default Divider;
