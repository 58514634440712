import { country_codes } from "../constants/CountryCodes.constants";

export const removeCountryCodeFromNumber = (phone) => {
  if (!phone) return "";
  return phone.replace(/\D/g, "").slice(2); // remove country code from phone_number & `-`
};

/**
 * @param {string} userCountryCode
 * @returns Finds the country name with matching user country code.
 */
export const getCountryNameBasedOnCountryCode = (userCountryCode) => {
  const countryDetails = Object.values(country_codes).find(
    (item) => item.dial_code == userCountryCode
  );

  return countryDetails?.name || "";
};
