import { POST_TYPE_IDS_ENUM } from "@my-scoot/exly-react-component-lib/build/cjs/constants/BrandedCommunity/Post";
import { BRANDED_COMMUNITY_QUERY_PARAMS } from "features/BrandedCommunity/constants/BrandedCommunity.constants";

export { POST_TYPE_IDS_ENUM };

export const default_post_type_id = POST_TYPE_IDS_ENUM.TEXT;

export const post_content_type_ids_to_show_list = [
  POST_TYPE_IDS_ENUM.IMAGE,
  POST_TYPE_IDS_ENUM.VIDEO,
  POST_TYPE_IDS_ENUM.DOCUMENT,
];

export const post_other_type_ids_to_show_list = [POST_TYPE_IDS_ENUM.OFFERING];

export const DEFAULT_SECTIONS = {
  FEED: 1,
  MEMBERS: 2,
  BONUS_OFFERINGS: 3,
  DIRECT_MESSAGES: 4,
  POST_PREVIEW_PANEL: 5,
};

export const DEFAULT_SECTIONS_MAP = {
  [DEFAULT_SECTIONS.FEED]: { id: DEFAULT_SECTIONS.FEED },
  [DEFAULT_SECTIONS.MEMBERS]: { id: DEFAULT_SECTIONS.MEMBERS },
  [DEFAULT_SECTIONS.BONUS_OFFERINGS]: { id: DEFAULT_SECTIONS.BONUS_OFFERINGS },
  [DEFAULT_SECTIONS.DIRECT_MESSAGES]: { id: DEFAULT_SECTIONS.DIRECT_MESSAGES },
};

export const TOOLTIPS = {
  SIDENAV:
    "Navigation made easier. Now you can easily relocate to and manage anywhere within the community through this nav panel.",
  DEFAULT_SECTIONS:
    "With new additions like DMs and Mentions, you can engage in 1-1 chats with your community members as well as become part of conversations through mentions.",
  ADD_GROUP_CTA:
    "You can create various collections and add channels in them. Channels can help you segregate conversations around different topics and discussions.",
  GROUPS:
    "We have already prepared a collection with some channels, for you to start with. You can edit and/or add anything to it to suit your liking, or remove it if you need to.",
  MEMBERS_PANEL:
    "You can see all the community members who are currently online. To view a member’s profile, simply click on their name.",
};

export const COMMUNITY_URL_QUERY_PARAMS = BRANDED_COMMUNITY_QUERY_PARAMS;

export const default_sections_keys_in_listing_details = {
  [DEFAULT_SECTIONS.FEED]: "feed",
  [DEFAULT_SECTIONS.MEMBERS]: "members",
  [DEFAULT_SECTIONS.BONUS_OFFERINGS]: "bonus_offerings",
  [DEFAULT_SECTIONS.DIRECT_MESSAGES]: "direct_messages",
};

export const DEFAULT_SECTIONS_DEFAULT_LABELS = {
  [DEFAULT_SECTIONS.FEED]: "Feed",
  [DEFAULT_SECTIONS.MEMBERS]: "Members",
  [DEFAULT_SECTIONS.BONUS_OFFERINGS]: "Bonus Offerings",
  [DEFAULT_SECTIONS.DIRECT_MESSAGES]: "Direct Messages",
};
