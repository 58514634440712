import { useSlate } from 'slate-react';
import Icon from '../../../../../RichTextEditorV2/common/Icon';
import { CustomDropdown } from '../../../../../RichTextEditorV2/Toolbar/components/CustomDropdown';
import { RTE_NODE_TYPES } from '../../../RTEEditable/constants/RTEEditable.constants';
import { useStyles } from './AlignmentTool.styles';
import { ALIGNMENT_TOOL_OPTIONS } from './constants/AlignmentTool.constants';

export const AlignmentTool = () => {
  const classes = useStyles();
  const editor = useSlate();

  return (
    <CustomDropdown
      className={classes.root}
      editor={editor}
      placeholder={<Icon icon={RTE_NODE_TYPES.ALIGN_LEFT} />}
      options={ALIGNMENT_TOOL_OPTIONS}
    />
  );
};
